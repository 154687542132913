import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import {
  fetchBlocksByStudySessionId,
  fetchFlashcardsForStudySession,
  fetchStudySessionById,
  fetchTodayStudySession,
  fetchVignettesForBlock,
} from '../services/study';
import {
  // clearTodaySession,
  setTodaySession,
} from '../slices/todaySession/todaySessionSlice';
import {
  // clearBlocks,
  setBlocks,
} from '../slices/blocks/blocksSlice';
import { RootState } from '../store/store';
import {
  // clearFlashcards,
  setNewAndDue,
} from '../slices/flashcards/flashcardsSlice';
import { updateStudySession } from '../services/studySession';
import {
  // clearVignettes,
  setVignettes,
} from '../slices/vignettes/vignettesSlice';
import { StudyPhase, StudySession } from '../types/Study';
import { fetchTopicTree } from '../services/topicCustomization';
import { TopicTreeNode } from '../types/TopicCustomization';
import { setActiveFilters } from '../slices/activeTopics/activeTopicsSlice';
import { getBlockById } from '../services/blocks';

export const useRefreshStudySessionAndContent = () => {
  const [isLoadingStudySessionAndContent, setIsLoadingStudySessionAndContent] =
    useState(false);
  const [isLoadedStudySessionAndContent, setIsLoadedStudySessionAndContent] =
    useState(false);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const dispatch = useDispatch();

  const refreshStudySessionAndContent = async () => {
    console.log('Refreshing Study Session and Content...');
    setIsLoadedStudySessionAndContent(false);
    setIsLoadingStudySessionAndContent(true);
    const session = await fetchTodayStudySession();
    const studySession = session?.studySession;
    if (studySession) {
      studySession.isReview = false;
      dispatch(setTodaySession(studySession));
      await Promise.all([
        loadFlashcards(studySession),
        loadBlocksAndVignettes(studySession),
        loadActiveTopics(),
      ]);
      setIsLoadedStudySessionAndContent(true);
      console.log('Study Session and Content Loaded');
    } else {
      enqueueSnackbar(
        "Error fetching today's study session and content. Please refresh the page."
      );
    }
    setIsLoadingStudySessionAndContent(false);
  };

  const refreshTodaySessionAndContentForReview = async (blockId: string) => {
    console.log('@@ Refreshing Today Session and Content For Review...');
    setIsLoadedStudySessionAndContent(false);
    setIsLoadingStudySessionAndContent(true);
    const session = await fetchTodayStudySession();
    const studySession = session?.studySession;
    if (studySession) {
      studySession.isReview = true;
      studySession.phase = StudyPhase.qbankReview;
      studySession.activeBlockId = blockId;
      dispatch(setTodaySession(studySession));
      await loadBlocksAndVignettes(studySession, blockId, true);
      setIsLoadedStudySessionAndContent(true);
      console.log('@@ Today Session and Content Loaded For Review.');
    } else {
      enqueueSnackbar(
        "Error fetching today's study session and content for review. Please refresh the page."
      );
    }
    setIsLoadingStudySessionAndContent(false);
  };

  const refreshFlashcards = async () => {
    setIsLoadedStudySessionAndContent(false);
    setIsLoadingStudySessionAndContent(true);
    await loadFlashcards(todaySession);
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  const loadStudySessionAndContentBySessionId = async (
    sessionId: string,
    isReview: boolean = false,
    blockId?: string
  ) => {
    console.log('Loading Study Session and Content by Session ID...');
    setIsLoadedStudySessionAndContent(false);
    setIsLoadingStudySessionAndContent(true);
    const session = await fetchStudySessionById(sessionId);
    const studySession = session?.studySession;
    if (studySession) {
      studySession.isReview = isReview;
      if (blockId) {
        studySession.phase = StudyPhase.qbankReview;
        studySession.activeBlockId = blockId;
      }
      dispatch(setTodaySession(studySession));
      if (isReview) {
        await loadBlocksAndVignettes(studySession, blockId, true);
      } else {
        await Promise.all([
          loadFlashcards(studySession),
          loadBlocksAndVignettes(studySession),
        ]);
      }
      setIsLoadedStudySessionAndContent(true);
      console.log('Study Session and Content Loaded');
    } else {
      enqueueSnackbar(
        'Error fetching study session and content. Please refresh the page.'
      );
    }
    setIsLoadingStudySessionAndContent(false);
  };

  const loadFlashcards = async (studySession: StudySession) => {
    console.log('Loading Flashcards...');
    const res = await fetchFlashcardsForStudySession(studySession.id);
    if (res) dispatch(setNewAndDue({ new: res.new, due: res.due }));
    console.log('Flashcards Loaded');
  };

  const loadBlocksAndVignettes = async (
    studySession: StudySession = todaySession,
    reviewBlockId?: string,
    isReview?: boolean
  ) => {
    if (isReview && reviewBlockId) {
      const reviewBlock = await getBlockById(reviewBlockId);
      if (reviewBlock) {
        dispatch(setBlocks([reviewBlock]));
        const vignettes = await fetchVignettesForBlock(reviewBlockId);
        if (vignettes && vignettes.vignettes) {
          dispatch(setVignettes(vignettes.vignettes));
          console.log('Blocks and Vignettes Loaded for Review');
        }
      }
    } else {
      const blocks = await fetchBlocksByStudySessionId(studySession.id);
      if (blocks && blocks.blocks) {
        dispatch(setBlocks(blocks.blocks));
        let blockId = studySession.activeBlockId;
        if (!blockId) {
          console.log('No Active Block, Attempt Setting to First Block');
          blockId = blocks.blocks[0]?.id;
          if (blockId) {
            const updatedInfo = {
              ...studySession,
              activeBlockId: blockId,
            };
            const response = await updateStudySession(
              studySession.id,
              updatedInfo
            );
            dispatch(setTodaySession(response));
          } else {
            console.log('No Blocks Found');
          }
        }
        if (blockId) {
          const vignettes = await fetchVignettesForBlock(blockId);
          if (vignettes && vignettes.vignettes) {
            dispatch(setVignettes(vignettes.vignettes));
            console.log('Blocks and Vignettes Loaded');
          }
        }
      }
    }
  };

  const loadActiveTopics = async () => {
    try {
      const topicTree = await fetchTopicTree();

      const updatedTopicFilters = topicTree.map((topic: TopicTreeNode) => {
        return {
          ...topic,
          children: topic.children?.map((child) => {
            return { ...child };
          }),
        };
      });

      const allTopicsActive = updatedTopicFilters.every(
        (topic: TopicTreeNode) => topic.active
      );

      const allTopicsInactive = updatedTopicFilters.every(
        (topic: TopicTreeNode) => !topic.active
      );

      dispatch(
        setActiveFilters({
          allTopicsActive: allTopicsActive,
          allTopicsInactive: allTopicsInactive,
          topicTree: updatedTopicFilters,
        })
      );
    } catch (error) {
      console.error('Error fetching topic tree:', error);
    }
  };

  return {
    isLoadingStudySessionAndContent,
    isLoadedStudySessionAndContent,
    setIsLoadingStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
    refreshTodaySessionAndContentForReview,
    refreshFlashcards,
    loadStudySessionAndContentBySessionId,
  };
};
