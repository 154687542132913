export const BitVerticalController: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="850"
    height="850"
    viewBox="0 0 850 850"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M230 618.612C230 611.309 235.92 605.389 243.222 605.389L243.222 635.385C265.22 589.168 312.352 557.223 366.944 557.223C442.577 557.223 503.889 618.535 503.889 694.167C503.889 769.799 442.577 831.112 366.944 831.112C292.259 831.112 231.537 771.325 230.029 697L230 697L230 618.612Z"
      fill="#A7AEBA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M503.889 155.833C503.889 80.2006 442.577 18.8884 366.944 18.8885C292.259 18.8885 231.537 78.6752 230.029 153L230 153L230 155.809C230 155.817 230 155.825 230 155.833C230 155.841 230 155.849 230 155.857L230 231.388C230 238.691 235.92 244.611 243.222 244.611L243.222 214.615C265.22 260.832 312.352 292.777 366.944 292.777C442.577 292.777 503.889 231.465 503.889 155.833Z"
      fill="#A7AEBA"
    />
    <rect
      x="243.222"
      y="850"
      width="850"
      height="377.778"
      rx="188.889"
      transform="rotate(-90 243.222 850)"
      fill="#E5E9F2"
    />
    <circle
      cx="503.889"
      cy="465.611"
      r="27.3889"
      transform="rotate(-90 503.889 465.611)"
      fill="#A7AEBA"
    />
    <circle
      cx="503.889"
      cy="384.388"
      r="27.3889"
      transform="rotate(-90 503.889 384.388)"
      fill="#A7AEBA"
    />
    <circle
      cx="366"
      cy="186.056"
      r="33.0556"
      transform="rotate(-90 366 186.056)"
      fill="#A7AEBA"
    />
    <circle
      cx="498.222"
      cy="186.056"
      r="33.0556"
      transform="rotate(-90 498.222 186.056)"
      fill="#A7AEBA"
    />
    <circle
      cx="432.111"
      cy="119.944"
      r="33.0556"
      transform="rotate(-90 432.111 119.944)"
      fill="#A7AEBA"
    />
    <circle
      cx="432.111"
      cy="252.167"
      r="33.0556"
      transform="rotate(-90 432.111 252.167)"
      fill="#A7AEBA"
    />
    <path
      d="M439.315 133.306L437.262 126.565H426.939L424.886 133.306H418.417L428.411 104.873H435.751L445.784 133.306H439.315ZM435.829 121.53L433.776 114.944C433.647 114.505 433.472 113.944 433.253 113.259C433.046 112.562 432.833 111.858 432.614 111.148C432.407 110.425 432.239 109.799 432.11 109.269C431.981 109.799 431.8 110.457 431.568 111.245C431.348 112.02 431.135 112.756 430.929 113.453C430.722 114.15 430.573 114.647 430.483 114.944L428.449 121.53H435.829Z"
      fill="white"
    />
    <path
      d="M488.091 171.1H496.904C500.674 171.1 503.528 171.63 505.465 172.688C507.402 173.747 508.37 175.607 508.37 178.267C508.37 179.338 508.196 180.307 507.847 181.172C507.512 182.037 507.021 182.747 506.375 183.302C505.73 183.858 504.942 184.219 504.012 184.387V184.581C504.955 184.774 505.807 185.11 506.569 185.588C507.331 186.053 507.938 186.737 508.39 187.641C508.854 188.532 509.087 189.72 509.087 191.205C509.087 192.922 508.667 194.394 507.828 195.621C506.989 196.847 505.788 197.79 504.225 198.449C502.676 199.094 500.829 199.417 498.686 199.417H488.091V171.1ZM494.096 182.315H497.582C499.325 182.315 500.532 182.043 501.204 181.501C501.875 180.946 502.211 180.132 502.211 179.061C502.211 177.976 501.811 177.201 501.01 176.736C500.223 176.259 498.97 176.02 497.253 176.02H494.096V182.315ZM494.096 187.079V194.459H498.008C499.816 194.459 501.075 194.11 501.785 193.413C502.495 192.715 502.85 191.779 502.85 190.604C502.85 189.907 502.695 189.294 502.385 188.764C502.075 188.235 501.552 187.822 500.816 187.525C500.093 187.228 499.093 187.079 497.814 187.079H494.096Z"
      fill="white"
    />
    <path
      d="M431.738 248.871L437.645 237.212H444.114L434.72 254.508V265.528H428.755V254.701L419.361 237.212H425.869L431.738 248.871Z"
      fill="white"
    />
    <path
      d="M379.707 199.417H372.851L366.266 188.706L359.68 199.417H353.25L362.644 184.813L353.85 171.1H360.474L366.576 181.288L372.56 171.1H379.029L370.139 185.142L379.707 199.417Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M340.5 630.889C336.327 630.889 332.945 634.272 332.945 638.445L332.945 689.445C332.945 693.617 336.327 697 340.5 697L391.5 697C395.673 697 399.056 700.383 399.056 704.556L399.056 755.556C399.056 759.729 402.438 763.111 406.611 763.111L457.611 763.111C461.784 763.111 465.167 759.729 465.167 755.556L465.167 704.556C465.167 700.383 468.55 697 472.722 697L523.722 697C527.895 697 531.278 693.617 531.278 689.445L531.278 638.445C531.278 634.272 527.895 630.889 523.722 630.889L472.722 630.889C468.55 630.889 465.167 627.506 465.167 623.334L465.167 572.334C465.167 568.161 461.784 564.778 457.611 564.778L406.611 564.778C402.438 564.778 399.056 568.161 399.056 572.334L399.056 623.334C399.056 627.506 395.673 630.889 391.5 630.889L340.5 630.889Z"
      fill="#A7AEBA"
    />
    <path
      d="M361.278 675.277L344.278 663.944L361.278 652.611L361.278 675.277Z"
      fill="white"
    />
    <path
      d="M420.778 593.111L432.111 576.111L443.444 593.111H420.778Z"
      fill="white"
    />
    <path
      d="M420.778 734.777L432.111 751.777L443.444 734.777L420.778 734.777Z"
      fill="white"
    />
    <path
      d="M502.945 675.277L519.945 663.944L502.945 652.611L502.945 675.277Z"
      fill="white"
    />
  </svg>
);
