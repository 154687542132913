import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight, X } from 'react-feather';
import { useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../utils/haptics';
import { useHandleResetProgress } from '../../../utils/resetProgress';

interface SettingsMenuProps {
  mobileMenuIsOpen: boolean;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  mobileMenuIsOpen,
  setMobileMenuIsOpen,
}) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeSection, setActiveSection] = useState('');

  const handleResetProgress = useHandleResetProgress();

  useEffect(() => {
    if (activeSection === '') {
      const firstFocusableElement = document.querySelector(
        '.settings-menu_body_menu button'
      ) as HTMLButtonElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    } else {
      const firstFocusableElement = document.querySelector(
        '.settings-menu_body_sections section button'
      ) as HTMLButtonElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [activeSection]);

  return (
    <div
      className={`settings-menu ${mobileMenuIsOpen ? 'open' : ''} ${activeSection !== '' ? 'open-sub' : ''}`}
    >
      <div className="settings-menu_menu">
        <div className="settings-menu_menu_header">
          <h2>Settings</h2>
          <button
            onClick={() => {
              setMobileMenuIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <X />
          </button>
        </div>
        <button
          className={`settings-menu_menu_item ${activeSection === 'account' ? 'active' : ''}`}
          onClick={() => {
            navigate('/account');
            hapticsImpactLight();
          }}
        >
          <span>Account</span>
          <ArrowRight />
        </button>
        {currentUser?.role === 'admin' && !currentUser?.demoMode && (
          <Link
            to={'/admin'}
            className={`settings-menu_menu_item ${activeSection === 'admin' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
            onClick={hapticsImpactLight}
          >
            <span>Admin Dashboard</span>
          </Link>
        )}
        <button
          className={`settings-menu_menu_item ${activeSection === 'account' ? 'active' : ''}`}
          onClick={() => {
            navigate('/help');
            hapticsImpactLight();
          }}
        >
          Help
        </button>
        {currentUser?.role === 'admin' && !currentUser?.demoMode && (
          <button
            className="settings-menu_menu_item"
            onClick={() => {
              handleResetProgress();
            }}
          >
            <span>Reset Progress</span>
          </button>
        )}
      </div>
      {activeSection !== '' && (
        <button
          className="settings-menu_overlay"
          onClick={() => {
            setActiveSection('');
            hapticsImpactLight();
          }}
        ></button>
      )}
      {/* <NotificationFeed
        isVisible={notificationsIsVisible}
        setIsVisible={setNofiticationsIsVisible}
        notifButtonRef={notifButtonRef}
      /> */}
    </div>
  );
};

export default SettingsMenu;
