export const BitLV: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2012 17.8232C14.2013 -3.40614 14.2012 -0.324457 14.2012 -0.324457L20.3645 -0.324457C20.3645 -0.324457 20.3645 10.6326 20.3645 17.8232C20.3645 26.3834 28.2399 31.5195 28.2399 31.5195C28.2399 31.5195 14.2011 29.8075 14.2012 17.8232Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M3 20.0757L3 10.0757H5.11426L5.11426 18.3325H9.40137L9.40137 20.0757H3Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
  </svg>
);
