import { useEffect, useRef, useState } from 'react';
import CustomImage from '../../Study/Exam/CustomImage';
import { hapticsImpactLight } from '../../../utils/haptics';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import parse from 'html-react-parser';
import useWindowWidth from '../../../hooks/useWindowWidth';

interface ImageDisplayProps {
  images: {
    src: string;
    alt: string;
    caption?: string;
    credit?: string;
    ord: number;
  }[];
  displayMainImage?: boolean;
  activeContentId?: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({
  images,
  displayMainImage,
  activeContentId,
}) => {
  const [orderedImages, setOrderedImages] = useState<
    {
      src: string;
      alt: string;
      caption?: string;
      credit?: string;
      ord: number;
    }[]
  >([]);
  const [currentImage, setCurrentImage] = useState<number | null>(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [captionOpen, setCaptionOpen] = useState(true);

  const thumbnailsRef = useRef(null);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (images && images.length > 0) {
      const orderedImages = [...images].sort((a, b) => {
        if (a.ord == null || a.ord === undefined) return 1;
        if (b.ord == null || b.ord === undefined) return -1;
        return a.ord - b.ord;
      });
      setOrderedImages(orderedImages);
    }
  }, [images]);

  useEffect(() => {
    if (!openLightbox) {
      setCaptionOpen(true);
    }
  }, [openLightbox]);

  useEffect(() => {
    if (activeContentId && displayMainImage) {
      setCurrentImage(0);
    }
  }, [activeContentId, displayMainImage]);

  return (
    <>
      <div
        className={`image-display ${displayMainImage ? 'is-display-main' : ''}`}
      >
        {displayMainImage &&
          typeof currentImage === 'number' &&
          currentImage >= 0 &&
          orderedImages[currentImage] && (
            <div
              className="image-display_main"
              role="button"
              onClick={() => setOpenLightbox(true)}
            >
              <CustomImage
                src={orderedImages[currentImage].src}
                caption={orderedImages[currentImage].caption}
                credit={orderedImages[currentImage].credit}
              />
            </div>
          )}
        <div className="image-display_row">
          {orderedImages.map((image, index) => (
            <div
              className={`image-display_row_image ${
                currentImage === index && displayMainImage && windowWidth > 560
                  ? 'selected'
                  : ''
              }`}
              key={image.src}
              role="button"
              onClick={() => {
                hapticsImpactLight();
                setCurrentImage(index);
                if (!displayMainImage || windowWidth <= 560) {
                  setOpenLightbox(true);
                }
              }}
            >
              <CustomImage
                key={image.src}
                src={image.src}
                caption={image?.caption}
                credit={image?.credit}
              />
            </div>
          ))}
        </div>
      </div>
      {orderedImages.length > 0 && (
        <Lightbox
          open={openLightbox}
          close={() => setOpenLightbox(false)}
          slides={orderedImages}
          index={currentImage ?? undefined}
          plugins={[Thumbnails, Zoom]}
          thumbnails={{ ref: thumbnailsRef }}
          zoom={{ maxZoomPixelRatio: 2, zoomInMultiplier: 5 }}
          on={{
            view: ({ index }) => setCurrentImage(index),
            click: () => {
              if (thumbnailsRef.current) {
                // @ts-expect-error - TODO: fix this
                if (thumbnailsRef.current.visible) {
                  // @ts-expect-error - TODO: fix this
                  thumbnailsRef.current.hide?.();
                } else {
                  // @ts-expect-error - TODO: fix this
                  thumbnailsRef.current.show?.();
                }
              }
            },
          }}
          render={{
            slideFooter: ({ slide }) => {
              // @ts-expect-error - TODO: fix this
              if (slide.caption || slide.caption) {
                return (
                  <div
                    className={`caption ${captionOpen ? 'is-expanded' : ''}`}
                  >
                    <div className="caption_content">
                      <div className="caption-text">
                        {/* @ts-expect-error - TODO: fix this */}
                        {slide.caption && parse(slide.caption)}
                      </div>
                      <div className="credit">
                        {/* @ts-expect-error - TODO: fix this */}
                        {slide.credit && parse(slide.credit)}
                      </div>
                    </div>
                  </div>
                );
              }
            },
          }}
        />
      )}
    </>
  );
};

export default ImageDisplay;
