import Next from '../../../../assets/images/standard/arrow-right.svg';
import NextInactive from '../../../../assets/images/standard/arrow-right-inactive.svg';
import Previous from '../../../../assets/images/standard/arrow-left.svg';
import PreviousInactive from '../../../../assets/images/standard/arrow-left-inactive.svg';
import LabValues from '../../../../assets/images/standard/lab-values.svg';
import StopIcon from '../../../../assets/images/standard/stop.svg';
import Notes from '../../../../assets/images/standard/notes.svg';
import ReverseColor from '../../../../assets/images/standard/reverse.svg';
import Flag from '../../../../assets/images/standard/flag.svg';
import Calculator from '../../../../assets/images/standard/calculator.svg';
import { toggleBlockFlag } from '../../../../services/blocks';
import { useDispatch, useSelector } from 'react-redux';
import { updateBlockFE } from '../../../../slices/blocks/blocksSlice';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { BlockType } from '../../../../types/Study';
import { convertSecondsToDisplayTime } from '../../../../utils/convertSecondsToDisplayTime';
import { Pause } from 'react-feather';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import { RootState } from '../../../../store/store';
import { useSystemTheme } from '../../../../hooks/useSystemTheme';

interface ExamHeaderProps {
  currentBlockType: BlockType;
  currentBlockNumber: number;
  currentBlockId: string;
  currentQuestion: number;
  currentVignetteId: string;
  totalQuestions: number;
  timeRemaining: number;
  isSubmitted: boolean;
  prevQuestion: () => void;
  nextQuestion: () => void;
  toggleTheme: () => void;
  toggleLabValues: () => void;
  toggleCalculator: () => void;
  toggleNotes: () => void;
  blockCount: number;
  setQuestionsMenuIsOpen: (isOpen: boolean) => void;
  isFlagged: boolean;
  labValuesAreOpen?: boolean;
  notesAreOpen?: boolean;
  calculatorIsOpen?: boolean;
  pauseBlock: () => void;
  setConfirmEndIsOpen: (isOpen: boolean) => void;
  isDemo?: boolean;
}

const ExamHeader: React.FC<ExamHeaderProps> = ({
  currentBlockType,
  currentBlockNumber,
  currentBlockId,
  currentQuestion,
  currentVignetteId,
  totalQuestions,
  timeRemaining,
  isSubmitted,
  prevQuestion,
  nextQuestion,
  toggleTheme,
  toggleLabValues,
  toggleCalculator,
  toggleNotes,
  blockCount,
  setQuestionsMenuIsOpen,
  isFlagged,
  labValuesAreOpen,
  notesAreOpen,
  calculatorIsOpen,
  pauseBlock,
  setConfirmEndIsOpen,
  isDemo,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const dispatch = useDispatch();
  const systemTheme = useSystemTheme();

  const updateBlockFlags = async () => {
    const res = await toggleBlockFlag(currentBlockId, currentVignetteId);
    if (res) {
      dispatch(updateBlockFE(res));
    }
  };

  const updateTextSize = async (newSize: 'default' | 'larger' | 'largest') => {
    const updateUser = await updateCurrentUserInfo({
      vignetteTextSize: newSize,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        setTimeout(() => {
          document.body.classList.remove('theme-dark');
        }, 0);
      }
    }
  };

  const renderCounterButton = () => {
    return (
      <button
        className="button button--counter"
        onClick={() => {
          setQuestionsMenuIsOpen(true);
          hapticsImpactLight();
        }}
      >
        <div className="top">
          <div className="top-mobile">
            {currentQuestion + 1} <span>of {totalQuestions}</span>
          </div>
          <div className="top-desktop">
            Item: {currentQuestion + 1} of {totalQuestions}
          </div>
        </div>
        <div className="bottom">
          Block: {isDemo ? 1 : currentBlockNumber} of {blockCount}
        </div>
      </button>
    );
  };

  const renderTimeDisplay = () => {
    return (
      <div className="time-display">
        {!isSubmitted && (
          <div className="time">
            {convertSecondsToDisplayTime(timeRemaining)}
          </div>
        )}
        <div className="block-type">
          <span style={{ textTransform: 'capitalize' }}>
            {currentBlockType}
          </span>{' '}
          Mode
        </div>
      </div>
    );
  };

  const renderMarkButton = () => {
    return (
      <button
        onClick={() => {
          updateBlockFlags();
          hapticsImpactLight();
        }}
        className="button--mark"
      >
        <input type="checkbox" checked={isFlagged ? true : false} />
        <img src={Flag} alt="Flag" />
        <div>Mark</div>
      </button>
    );
  };

  const renderPreviousQuestionButton = () => {
    return (
      <button
        className="button button--change-question"
        onClick={() => {
          prevQuestion();
          hapticsImpactLight();
        }}
      >
        {currentQuestion === 0 ? (
          <img src={PreviousInactive} alt="Previous Question" />
        ) : (
          <img src={Previous} alt="Previous Question" />
        )}
        <div>Previous</div>
      </button>
    );
  };

  const renderNextQuestionButton = () => {
    return (
      <button
        className="button button--change-question"
        onClick={() => {
          nextQuestion();
          hapticsImpactLight();
        }}
      >
        {currentQuestion === totalQuestions - 1 ? (
          <img src={NextInactive} alt="Next Question" />
        ) : (
          <img src={Next} alt="Next Question" />
        )}
        <div>Next</div>
      </button>
    );
  };

  const renderLabValuesButton = () => {
    return (
      <button
        className={`button button--lab-values ${labValuesAreOpen ? ' button--active' : ''}`}
        onClick={() => {
          toggleLabValues();
          hapticsImpactLight();
        }}
      >
        <img src={LabValues} alt="Lab Values" />
        <div>Lab Values</div>
      </button>
    );
  };

  const renderNotesButton = () => {
    return (
      <button
        className={`button button--notes ${notesAreOpen ? ' button--active' : ''}`}
        onClick={() => {
          toggleNotes();
          hapticsImpactLight();
        }}
      >
        <img src={Notes} alt="Notes" />
        <div>Notes</div>
      </button>
    );
  };

  const renderCalculatorButton = () => {
    return (
      <button
        className={`button button--calculator ${calculatorIsOpen ? ' button--active' : ''}`}
        onClick={() => {
          toggleCalculator();
          hapticsImpactLight();
        }}
      >
        <img src={Calculator} alt="Calculator" />
        <div>Calculator</div>
      </button>
    );
  };

  const renderReverseColorButton = () => {
    return (
      <button
        className="button button--reverse-color"
        onClick={() => {
          toggleTheme();
          hapticsImpactLight();
        }}
      >
        <img src={ReverseColor} alt="Reverse Color" />
        <div>Reverse Color</div>
      </button>
    );
  };

  const renderTextSizeButton = () => {
    return (
      <div className="button button--text-size">
        <div className="button--text-size_wrapper">
          <button
            className={`button--text-size_wrapper_option ${!currentUser || currentUser?.vignetteTextSize === 'default' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateTextSize('default');
            }}
          >
            A
          </button>
          <button
            className={`button--text-size_wrapper_option button--text-size_wrapper_option--middle ${currentUser?.vignetteTextSize === 'larger' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateTextSize('larger');
            }}
          >
            A
          </button>
          <button
            className={`button--text-size_wrapper_option ${currentUser?.vignetteTextSize === 'largest' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateTextSize('largest');
            }}
          >
            A
          </button>
        </div>
        <div className="label">Text Zoom</div>
      </div>
    );
  };

  const renderPauseButton = () => {
    return (
      <button
        className="button button--glass button--pause"
        onClick={() => {
          pauseBlock();
          hapticsImpactLight();
        }}
      >
        <Pause />
      </button>
    );
  };

  const renderEndButton = () => {
    return (
      <button
        className="button button--glass button--pause"
        onClick={() => {
          hapticsImpactLight();
          setConfirmEndIsOpen(true);
        }}
      >
        <img src={StopIcon} alt="End Session" />
      </button>
    );
  };

  return (
    <div className="exam-header">
      <div className="exam-header_left">
        {renderCounterButton()}
        {renderTimeDisplay()}
        {renderMarkButton()}
      </div>
      <div className="buttons buttons--change-questions">
        {renderPreviousQuestionButton()}
        {renderNextQuestionButton()}
      </div>
      <div className="buttons buttons--functions">
        {renderMarkButton()}
        {renderLabValuesButton()}
        {renderNotesButton()}
        {renderCalculatorButton()}
        {currentBlockType === BlockType.timed && renderReverseColorButton()}
        {renderTextSizeButton()}
        {!isSubmitted && renderPauseButton()}
        {!isSubmitted && renderEndButton()}
      </div>
    </div>
  );
};

export default ExamHeader;
