import React, { useState, useEffect } from 'react';
import type { RootState } from '../../store/store';
import {
  getAllUsers,
  deleteUser,
  updateUser,
  updateUserReferralId,
  getMobileAppInfo,
} from '../../services/users';
import { User, UserRole } from '../../types/User';
import Header from '../../components/Global/Header';
import { Crosshair, Trash, User as UserIcon, Edit3, Gift } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { HeaderType } from '../../types/Header';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField, Tooltip } from '@mui/material';
import { isSuperAdmin } from '../../utils/isSuperAdmin';
import { getUserInfo, impersonateUser } from '../../services/auth';
import {
  clearUserInfo,
  setUserInfo,
  updateAuthToken,
  updateRefreshToken,
} from '../../slices/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getReferralInfo } from '../../services/referralService';
import { useSnackbar } from 'notistack';

type ReferralData = {
  referralCounts: Array<{
    id: string;
    email: string;
    referralCounts: number;
    referralId: string | null;
  }>;
  eligibleUsers: Array<{ email: string; id: string }>;
};

const Admin: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [users, setUsers] = useState<User[]>([]);
  const [hideSynaptiq, setHideSynaptiq] = useState(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReferralInfoModal, setShowReferralInfoModal] = useState(false);
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [showModifyReferralIdModal, setShowModifyReferralIdModal] =
    useState(false);

  const [pendingModifyUserId, setPendingModifyUserId] = useState<
    string | null
  >();

  const [pendingUpdateRoleSelection, setPendingUpdateRoleSelection] =
    useState<UserRole | null>(null);
  const [pendingModifyReferralId, setPendingModifyReferralId] = useState<
    string | null
  >();
  const [mobileAppInfo, setMobileAppInfo] = useState<{
    iosUsers: number;
    androidUsers: number;
  }>();
  const [referralData, setReferralData] = useState<ReferralData | null>(null);

  const userRoles = Object.values(UserRole);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    fetchUsers();
    fetchReferralInfo();
    fetchMobileAppInfo();
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchReferralInfo();
  }, [hideSynaptiq]);

  const fetchReferralInfo = async () => {
    const referralInfo = await getReferralInfo();
    setReferralData(referralInfo);
  };

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getAllUsers();
      const filteredUsers = filterUsers(fetchedUsers);
      filteredUsers.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchMobileAppInfo = async () => {
    const mobileAppInfo = await getMobileAppInfo();
    setMobileAppInfo(mobileAppInfo);
  };

  const initiateDeleteRequest = (userId: string) => {
    setPendingModifyUserId(userId);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    if (!pendingModifyUserId) return;
    await deleteUser(pendingModifyUserId);
    const fetchedUsers = await getAllUsers();
    const filteredUsers = filterUsers(fetchedUsers);
    filteredUsers.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setUsers(filteredUsers);
    setPendingModifyUserId(null);
    hideDeleteModal();
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
    setPendingModifyUserId(null);
  };

  const initiateUpdateRole = (userId: string) => {
    setPendingModifyUserId(userId);
    setShowUpdateRoleModal(true);
  };

  const impersonate = async (userId: string) => {
    try {
      const response = await impersonateUser(userId);
      if (response && response.token && response.refreshToken) {
        handleImpersonateSuccess(response.token, response.refreshToken);
      }
    } catch (error) {
      console.error('Error impersonating user:', error);
    }
  };

  const handleImpersonateSuccess = async (
    authToken: string,
    refreshToken: string | null = null
  ) => {
    dispatch(updateAuthToken(authToken));
    if (refreshToken) dispatch(updateRefreshToken(refreshToken));
    dispatch(clearUserInfo());
    const userInfo = await getUserInfo();
    if (userInfo) {
      dispatch(setUserInfo(userInfo));
    }
    navigate('/');
  };

  const filterUsers = (users: User[]) => {
    if (!hideSynaptiq) return users;
    const nonAnonUsers = users.filter(
      (user) => !user.email!.includes('@anon.com')
    );
    return nonAnonUsers.filter(
      (user) =>
        user.role !== 'admin' &&
        !user.email!.includes('rptest') &&
        !user.email!.includes('jctest') &&
        !user.email!.includes('kbtest') &&
        !user.email!.includes('caccamo') &&
        !user.email!.includes('oraai.com') &&
        !user.email!.includes('synaptiq.co') &&
        !user.email!.includes('kaycbas') &&
        !user.email!.includes('ryanrlphelps@gmail.com') &&
        !user.email!.includes('cphelps@losaltoslandscaping.com') &&
        !user.email!.includes('ryanllewellynphelps@gmail.com') &&
        !user.email!.includes('will.richardson.1994@gmail.com') &&
        user.id !== currentUser?.id
    );
  };

  const hideUpdateRoleModal = () => {
    setShowUpdateRoleModal(false);
    setPendingUpdateRoleSelection(null);
    setPendingModifyUserId(null);
  };

  const toggleApprovedReferrer = async (userId: string) => {
    if (!userId) return;
    const currentApprovedReferrerStatus = users.find(
      (user) => user.id === userId
    )?.isApprovedReferrer;
    await updateUser(userId, {
      isApprovedReferrer: !currentApprovedReferrerStatus,
    });
    enqueueSnackbar(
      `Status set to ${!currentApprovedReferrerStatus ? 'approved' : 'not approved'}`,
      { autoHideDuration: 3000 }
    );
    fetchUsers();
    fetchReferralInfo();
  };

  const handleUpdateRole = async () => {
    if (!pendingModifyUserId || !pendingUpdateRoleSelection) return;
    await updateUser(pendingModifyUserId, {
      role: pendingUpdateRoleSelection,
    });
    hideUpdateRoleModal();
    fetchUsers();
  };

  const modifyReferralId = async () => {
    if (!pendingModifyUserId || !pendingModifyReferralId) return;
    try {
      const response = await updateUserReferralId(
        pendingModifyUserId,
        pendingModifyReferralId
      );
      if (response) {
        setShowModifyReferralIdModal(false);
        setPendingModifyUserId(null);
        fetchUsers();
        fetchReferralInfo();
        enqueueSnackbar('Referral ID updated successfully', {
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar('ReferralId already in use', {
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="admin">
      <div className="admin_body">
        <Header type={HeaderType.default} />
        <div className="admin_body_content">
          <div>
            <div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h1 className="m-t-0 m-b-1">Admin Dashboard</h1>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={hideSynaptiq}
                          onChange={() => setHideSynaptiq(!hideSynaptiq)}
                        />
                      }
                      label="Hide Synaptiq Users"
                    />
                  </FormGroup>
                </div>
                <button
                  style={{ height: 'fit-content' }}
                  className="button"
                  onClick={() => setShowReferralInfoModal(true)}
                >
                  View Referral Info
                </button>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '1.5rem',
                  alignItems: 'baseline',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '.25rem',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <strong style={{ fontSize: '1.25rem' }}>
                    {users.length}
                  </strong>
                  <div className="helper">Total Users</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '.25rem',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <strong style={{ fontSize: '1.25rem' }}>
                    {users.filter((user) => user.oauth).length}
                  </strong>
                  <div className="helper">OAuth Users</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '.25rem',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <strong style={{ fontSize: '1.25rem' }}>
                    {mobileAppInfo?.iosUsers}
                  </strong>
                  <div className="helper">iOS Users</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '.25rem',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <strong style={{ fontSize: '1.25rem' }}>
                    {mobileAppInfo?.androidUsers}
                  </strong>
                  <div className="helper">Android Users</div>
                </div>
              </div>
              <table className="users-list">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>ID</th>
                    <th>OAuth</th>
                    <th>Role</th>
                    <th style={{ width: '175px' }}>Approved Referrer</th>
                    <th>Created At</th>
                    {currentUser?.role === 'admin' && (
                      <th style={{ width: '110px' }}>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user: User) => (
                    <tr className="user-row" key={user.id}>
                      <td>
                        {user.name ? user.name : 'N/A'}
                        {currentUser?.id === user.id ? ' (You)' : ''}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.id}</td>
                      <td>{user.oauth ? user.oauthStrategy : 'Not OAuth'}</td>
                      <td>{user.role}</td>
                      <td>{user.isApprovedReferrer ? 'Yes' : 'No'}</td>
                      <td>{moment(user.createdAt).format('MM/DD/YYYY')}</td>
                      {currentUser?.role === 'admin' && (
                        <td>
                          <Tooltip
                            title={'Impersonate User'}
                            enterTouchDelay={0}
                          >
                            <button
                              className="button button--icon-only"
                              style={{ display: 'inline-flex' }}
                              onClick={() => impersonate(user.id)}
                            >
                              <Crosshair />
                            </button>
                          </Tooltip>
                          <Tooltip
                            title={'Update User Role'}
                            enterTouchDelay={0}
                          >
                            <button
                              className="button button--icon-only"
                              style={{ display: 'inline-flex' }}
                              onClick={() => initiateUpdateRole(user.id)}
                            >
                              <UserIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title={'Delete User'} enterTouchDelay={0}>
                            <button
                              className="button button--icon-only"
                              style={{ display: 'inline-flex' }}
                              onClick={() => initiateDeleteRequest(user.id)}
                            >
                              <Trash />
                            </button>
                          </Tooltip>
                          {isSuperAdmin(currentUser) && (
                            <Tooltip
                              title={'Toggle Approved Referrer'}
                              enterTouchDelay={0}
                            >
                              <button
                                className="button button--icon-only"
                                style={{ display: 'inline-flex' }}
                                onClick={() => toggleApprovedReferrer(user.id)}
                              >
                                <Gift />
                              </button>
                            </Tooltip>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showDeleteModal} onClose={hideDeleteModal}>
        <div className="modal modal--delete-user">
          <div className="modal_header">
            <h2>Delete User</h2>
          </div>
          <div className="modal_content">
            <p>Are you sure you want to delete this user?</p>
          </div>
          <div className="modal_actions">
            <button className="button button--error" onClick={handleDeleteUser}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={showUpdateRoleModal} onClose={hideUpdateRoleModal}>
        <div className="modal modal--update-role">
          <div className="modal_header">
            <h2>Update User Role</h2>
          </div>
          <div className="modal_content">
            <select
              value={pendingUpdateRoleSelection ?? ''}
              onChange={(e) =>
                setPendingUpdateRoleSelection(e.target.value as UserRole)
              }
            >
              <option value="">Select a role</option>
              {userRoles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
          <div className="modal_actions">
            <button className="button" onClick={handleUpdateRole}>
              Save
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showReferralInfoModal}
        onClose={() => setShowReferralInfoModal(false)}
      >
        <div className="modal modal--referral-info">
          <div className="modal_header">
            <h2>Referral Info</h2>
          </div>
          <div className="modal_content">
            <div className="ref-info">
              <div className="ref-info_col eligible">
                <h3>
                  Eligible Referrers&nbsp;
                  <span>
                    (
                    {
                      referralData?.eligibleUsers.filter(
                        (u: { email: string; id: string }) =>
                          !u.email!.includes('rptest') &&
                          !u.email!.includes('jctest') &&
                          !u.email!.includes('kbtest') &&
                          !u.email!.includes('caccamo') &&
                          !u.email!.includes('oraai.com') &&
                          !u.email!.includes('synaptiq.co') &&
                          !u.email!.includes('kaycbas') &&
                          !u.email!.includes('ryanrlphelps') &&
                          !u.email!.includes('idn2106@columbia.edu') &&
                          !u.email!.includes('ryanllewellynphelps')
                      ).length
                    }
                    )
                  </span>
                </h3>
                {referralData?.eligibleUsers
                  .filter(
                    (u: { email: string; id: string }) =>
                      !u.email!.includes('rptest') &&
                      !u.email!.includes('jctest') &&
                      !u.email!.includes('kbtest') &&
                      !u.email!.includes('caccamo') &&
                      !u.email!.includes('oraai.com') &&
                      !u.email!.includes('synaptiq.co') &&
                      !u.email!.includes('kaycbas') &&
                      !u.email!.includes('ryanrlphelps') &&
                      !u.email!.includes('idn2106@columbia.edu') &&
                      !u.email!.includes('ryanllewellynphelps')
                  )
                  .map((u: { email: string; id: string }, index: number) => (
                    <div key={index} style={{ display: 'flex' }}>
                      <span>{u.email}</span>
                      <button
                        className="button button--icon-only"
                        style={{ display: 'inline-flex' }}
                        onClick={() => toggleApprovedReferrer(u.id)}
                      >
                        <Gift />
                      </button>
                    </div>
                  ))}
              </div>
              <div className="ref-info_col counts">
                <h3>Referral Counts</h3>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Referral Counts</th>
                      <th>Referral ID</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralData?.referralCounts
                      .sort(
                        (
                          a: { referralCounts: number },
                          b: { referralCounts: number }
                        ) => b.referralCounts - a.referralCounts
                      )
                      .map(
                        (o: {
                          id: string;
                          email: string;
                          referralCounts: number;
                          referralId: string | null;
                        }) => (
                          <tr key={o.id}>
                            <td>{o.email}</td>
                            <td>{o.referralCounts}</td>
                            <td>{o.referralId}</td>
                            <td>
                              <button
                                className="button button--icon-only"
                                onClick={() => {
                                  setShowModifyReferralIdModal(true);
                                  setPendingModifyReferralId(
                                    o.referralId || ''
                                  );
                                  setPendingModifyUserId(o.id);
                                }}
                              >
                                <Edit3 />
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={showModifyReferralIdModal}
        onClose={() => setShowModifyReferralIdModal(false)}
      >
        <div className="modal modal--modify-referral-id">
          <div className="modal_header">
            <h2>Modify Referral ID</h2>
          </div>
          <div className="modal_content">
            <TextField
              label="Referral ID"
              value={pendingModifyReferralId}
              onChange={(e) => setPendingModifyReferralId(e.target.value)}
              style={{ width: '100%' }}
            />
            <button className="button" onClick={modifyReferralId}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Admin;
