export const XboxLB: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.459623 14.5949L0.0917973 22.9836C0.0417629 24.1247 0.953374 25.0772 2.09557 25.0772H27.4001C28.3361 25.0772 29.1476 24.4298 29.3557 23.5173L31.709 13.1959C31.8858 12.4204 31.5876 11.6136 30.9489 11.1395L25.9823 7.45325C25.6677 7.21971 25.2892 7.08206 24.8977 7.06535C13.6411 6.58491 4.91301 10.6001 1.24121 13.131C0.759476 13.463 0.485252 14.0103 0.459623 14.5949Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M17.3653 21.949H11.5488V12.1821H13.7487V20.1645H17.3653V21.949Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
    <path
      d="M18.6866 21.949V12.1821H22.2419C23.3317 12.1821 24.1694 12.3819 24.7552 12.7815C25.3409 13.1811 25.6338 13.7441 25.6338 14.4706C25.6338 14.9973 25.4544 15.4582 25.0957 15.8532C24.7415 16.2482 24.2875 16.5229 23.7335 16.6773V16.7046C24.4282 16.7908 24.9822 17.0474 25.3954 17.4742C25.8131 17.901 26.022 18.4209 26.022 19.0339C26.022 19.9284 25.7019 20.639 25.0616 21.1657C24.4214 21.6879 23.5474 21.949 22.4395 21.949H18.6866ZM20.8866 13.8031V16.1188H21.8537C22.3078 16.1188 22.6642 16.0099 22.923 15.7919C23.1864 15.5694 23.3181 15.2652 23.3181 14.8792C23.3181 14.1618 22.7823 13.8031 21.7107 13.8031H20.8866ZM20.8866 17.7534V20.328H22.0785C22.587 20.328 22.9843 20.2099 23.2704 19.9738C23.561 19.7377 23.7063 19.4153 23.7063 19.0066C23.7063 18.6162 23.5633 18.3097 23.2772 18.0872C22.9957 17.8647 22.6006 17.7534 22.0921 17.7534H20.8866Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
