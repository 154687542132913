export const XboxRS: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      y="9.41162"
      width="32"
      height="15.0588"
      rx="7.52941"
      fill="var(--gamepad-button-background)"
    />
    <rect
      x="8.4707"
      y="24.4707"
      width="15.0588"
      height="3.76471"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M21.0322 22.7646H18.1338L16.3916 19.8818C16.2614 19.6631 16.1364 19.4678 16.0166 19.2959C15.8968 19.124 15.7744 18.9782 15.6494 18.8584C15.5296 18.7334 15.402 18.6396 15.2666 18.5771C15.1364 18.5094 14.9932 18.4756 14.8369 18.4756H14.1572V22.7646H11.6338V11.5615H15.6338C18.3525 11.5615 19.7119 12.5771 19.7119 14.6084C19.7119 14.999 19.652 15.361 19.5322 15.6943C19.4124 16.0225 19.2432 16.3193 19.0244 16.585C18.8057 16.8506 18.54 17.0798 18.2275 17.2725C17.9202 17.4652 17.5765 17.6162 17.1963 17.7256V17.7568C17.363 17.8089 17.5244 17.8949 17.6807 18.0146C17.8369 18.1292 17.988 18.2646 18.1338 18.4209C18.2796 18.5771 18.4176 18.7464 18.5479 18.9287C18.6833 19.1058 18.8057 19.2803 18.915 19.4521L21.0322 22.7646ZM14.1572 13.4521V16.5693H15.251C15.7926 16.5693 16.2275 16.4131 16.5557 16.1006C16.889 15.7829 17.0557 15.3896 17.0557 14.9209C17.0557 13.9417 16.4697 13.4521 15.2979 13.4521H14.1572Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M16.0001 9.41162L19.2604 5.17633H12.7397L16.0001 9.41162Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
