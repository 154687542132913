export const BitRV: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.9424 13.2159C14.9425 34.4452 14.9424 31.3635 14.9424 31.3635L21.1057 31.3635C21.1057 31.3635 21.1057 20.4064 21.1057 13.2159C21.1057 4.65566 28.9811 -0.480469 28.9811 -0.480469C28.9811 -0.480469 14.9423 1.23157 14.9424 13.2159Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M3 20.5767V10.5767H6.94531C7.70052 10.5767 8.34505 10.7118 8.87891 10.9819C9.41602 11.2489 9.82454 11.6281 10.1045 12.1196C10.3877 12.6079 10.5293 13.1825 10.5293 13.8433C10.5293 14.5073 10.3861 15.0786 10.0996 15.5571C9.81315 16.0324 9.39811 16.397 8.85449 16.6509C8.31413 16.9048 7.65983 17.0317 6.8916 17.0317H4.25V15.3325H6.54981C6.95345 15.3325 7.28874 15.2772 7.55566 15.1665C7.82259 15.0558 8.02116 14.8898 8.15137 14.6685C8.28483 14.4471 8.35156 14.172 8.35156 13.8433C8.35156 13.5112 8.28483 13.2313 8.15137 13.0034C8.02116 12.7756 7.82096 12.603 7.55078 12.4858C7.28385 12.3654 6.94694 12.3052 6.54004 12.3052H5.11426V20.5767H3ZM8.40039 16.0259L10.8857 20.5767H8.55176L6.12012 16.0259H8.40039Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
  </svg>
);
