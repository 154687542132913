export const BitRH: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3037 15C-2.92567 15.0001 0.156012 15 0.156012 15V21.1634C0.156012 21.1634 11.1131 21.1634 18.3037 21.1634C26.8639 21.1634 32 29.0387 32 29.0387C32 29.0387 30.288 14.9999 18.3037 15Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M12 12V2H15.9453C16.7005 2 17.3451 2.13509 17.8789 2.40527C18.416 2.6722 18.8245 3.05143 19.1045 3.54297C19.3877 4.03125 19.5293 4.60579 19.5293 5.2666C19.5293 5.93066 19.3861 6.50195 19.0996 6.98047C18.8132 7.45573 18.3981 7.82031 17.8545 8.07422C17.3141 8.32813 16.6598 8.45508 15.8916 8.45508H13.25V6.75586H15.5498C15.9535 6.75586 16.2887 6.70052 16.5557 6.58984C16.8226 6.47917 17.0212 6.31315 17.1514 6.0918C17.2848 5.87044 17.3516 5.59538 17.3516 5.2666C17.3516 4.93457 17.2848 4.65462 17.1514 4.42676C17.0212 4.19889 16.821 4.02637 16.5508 3.90918C16.2839 3.78874 15.9469 3.72852 15.54 3.72852H14.1143V12H12ZM17.4004 7.44922L19.8857 12H17.5518L15.1201 7.44922H17.4004Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
  </svg>
);
