import * as React from 'react';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { LinearProgress, Tooltip } from '@mui/material';
import { calculateFlashcardProgress } from '../../../../utils/calculateStudyTime';

interface StudyProgressProps {}

const StudyProgress: React.FC<StudyProgressProps> = () => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const flashcards = useSelector((state: RootState) => state.flashcards);
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    setCurrentProgress(calculateFlashcardProgress(todaySession, flashcards));
  }, [flashcards]);

  return (
    <div className="study-progress">
      <div className="study-progress_phase">
        <div className="counters">
          <div
            className={`counter new ${flashcards.new.length > 999 ? 'thousand' : ''}`}
          >
            {flashcards.new.length || 0}
          </div>
          <div
            className={`counter due ${flashcards.due.length > 999 ? 'thousand' : ''}`}
          >
            {flashcards.due.length || 0}
          </div>
        </div>
        {todaySession && (
          <Tooltip
            title={Math.round(currentProgress).toString() + '% Complete'}
            enterTouchDelay={0}
          >
            <div className="study-progress_bar">
              <LinearProgress
                sx={{ width: '100%' }}
                variant="determinate"
                color="success"
                value={currentProgress}
              />
              <span style={{ marginLeft: '.5rem' }}>
                {currentProgress + '%'}
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default StudyProgress;
