export enum StudyPhase {
  flashcard = 'flashcard',
  qbank = 'qbank',
  qbankReview = 'qbankReview',
  sessionReview = 'sessionReview',
}

export enum BlockType {
  timed = 'timed',
  tutor = 'tutor',
}

export interface SchedulingRequestBody {
  due: Date | string;
  stability: number;
  difficulty: number;
  elapsedDays: number;
  scheduledDays: number;
  reps: number;
  lapses: number;
  state: number;
  lastReview: Date | string;
  sessionId?: string;
}

export interface StudySession {
  id: string;
  date: string;
  flashcardCount: number;
  completedFlashcardCount: number;
  vignetteCount: number;
  blockCount: number;
  isComplete: boolean;
  isDiagnostic: boolean;
  isStarted: boolean;
  phase: StudyPhase;
  activeBlockId: string;
  vignetteCorrectCount: number;
  vignetteIncorrectCount: number;
  vignetteOmittedCount: number;
  isReview: boolean;
  isSnoozed: boolean;
  hasSeenPriorities: boolean;
}

export interface Block {
  id: string;
  type: BlockType;
  answers: {
    [key: string]: {
      answer: string;
      correctAnswer: string;
      submitted: boolean;
      vignetteContentId: string;
    };
  };
  flags: {
    [key: string]: boolean;
  };
  notes: {
    [key: string]: string;
  };
  answerCrossouts: {
    [key: string]: boolean[];
  };
  date: Date;
  duration: number;
  isStarted: boolean;
  isSubmitted: boolean;
  correctCount: number;
  incorrectCount: number;
  omittedCount: number;
  vignetteCount: number;
  ord?: number;
  questionHighlights: {
    [key: string]: string;
  };
}

export enum StartType {
  Home = 'Home',
  Diagnostic = 'Diagnostic',
}

export interface StudySessionWithBlocks {
  id: string;
  date: string;
  flashcardCount: number;
  completedFlashcardCount: number;
  vignetteCount: number;
  blockCount: number;
  isComplete: boolean;
  isDiagnostic: boolean;
  isStarted: boolean;
  phase: StudyPhase;
  activeBlockId: string;
  vignetteCorrectCount: number;
  vignetteIncorrectCount: number;
  vignetteOmittedCount: number;
  isReview: boolean;
  isSnoozed: boolean;
  hasSeenPriorities: boolean;
  blocks: Block[];
}

export enum PanelType {
  explanation = 'explanation',
  library = 'library',
  report = 'report',
  flashcards = 'flashcards',
}
