import React from 'react';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

interface CustomModalProps {
  open: boolean;
  onClose: (isOpen: boolean) => void;
  name: string;
  glass?: boolean;
  blurs?: boolean;
  children: React.ReactNode;
  disableBackdropClick?: boolean;
  unsavedChanges?: boolean;
  handleUnsavedChanges?: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  children,
  name,
  glass,
  blurs,
  disableBackdropClick,
  unsavedChanges,
  handleUnsavedChanges,
  ...props
}) => {
  const handleBackdropClick = () => {
    if (unsavedChanges && handleUnsavedChanges) {
      handleUnsavedChanges();
    } else {
      if (!disableBackdropClick) {
        onClose(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      BackdropProps={{
        timeout: 250,
        className: disableBackdropClick ? 'backdrop--disable' : '',
        onClick: handleBackdropClick,
      }}
      {...props}
      disableAutoFocus
    >
      <Fade in={open} timeout={250}>
        <div
          className={`modal ${glass ? 'modal--glass' : ''} modal--${name} ${
            blurs ? 'modal--blurs' : ''
          }`}
        >
          {children}
          {blurs && (
            <>
              <div className="blur-1"></div>
              <div className="blur-2"></div>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
