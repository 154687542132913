import { ArrowLeft } from 'react-feather';
import { hapticsImpactLight } from '../../../utils/haptics';
import OraLogo from '../../Global/OraLogo';

interface FrameProps {
  classes?: string;
  currentFrame: number;
  index: number;
  showNext: boolean;
  showBack: boolean;
  advanceAllowed: boolean;
  transitionClass: string;
  nextFrame: () => void;
  prevFrame: () => void;
  jumpToFrame: (frame: number) => void;
}

const Frame: React.FC<FrameProps & { children: React.ReactNode }> = ({
  classes,
  children,
  currentFrame,
  index,
  showNext,
  showBack,
  advanceAllowed,
  transitionClass,
  nextFrame,
  prevFrame,
}) => {
  return (
    <div
      className={`onboarding_frame onboarding_frame--${index} ${currentFrame === 3 ? 'final' : ''} ${classes ? classes : ''}`}
      style={currentFrame === index ? { display: 'flex' } : { display: 'none' }}
    >
      <div className="onboarding_frame_header">
        <OraLogo />
        {/* Don't show on loader */}
        {currentFrame !== 2 && (
          <div className="step-indicator">
            <div className={`step ${currentFrame === 0 ? 'active' : ''}`}></div>
            <div className={`step ${currentFrame === 1 ? 'active' : ''}`}></div>
          </div>
        )}
      </div>
      <div className={`onboarding_frame_content ${transitionClass}`}>
        <div className="onboarding_frame_content_body">{children}</div>
        <div className="onboarding_frame_content_actions">
          {showBack && (
            <button
              className="button button--prev"
              onClick={() => {
                prevFrame();
                hapticsImpactLight();
              }}
            >
              <ArrowLeft />
            </button>
          )}
          {showNext && (
            <button
              disabled={!advanceAllowed}
              onClick={() => {
                nextFrame();
                hapticsImpactLight();
              }}
              style={currentFrame === 3 ? { display: 'none' } : {}}
              className={`button button--glass ${currentFrame === 0 ? 'button--start' : 'button--next'}`}
            >
              {currentFrame === 1 ? 'Finish' : 'Finish'}
            </button>
          )}
          {/* Complete Onboarding After Video */}
          {/* {index === 2 && (
            <button
              disabled={!advanceAllowed}
              onClick={() => {
                jumpToFrame(3);
                hapticsImpactLight();
                const video = document.getElementById(
                  'welcome-video'
                ) as HTMLVideoElement;
                if (video) {
                  video.pause();
                }
              }}
              className="button button--glass button--next"
            >
              Finish
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Frame;
