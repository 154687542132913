import React from 'react';
import OraLogo from '../../Global/OraLogo';
import QRCode from '../../../assets/ora-qr.svg';
import AppPromo from '../../../assets/images/app-promo.png';
import { Link } from 'react-router-dom';
import { getPlatform } from '../../../utils/getPlatform';

const MSFooter: React.FC = () => {
  const renderMobileAppCTA = () => {
    const platform = getPlatform();
    if (platform === 'ios') {
      return (
        <a
          className="button button--glass button--mobile-app"
          href="https://apps.apple.com/us/app/ora-ai-guided-step-prep/id6484319369"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1018 5.4544C14.009 5.5264 12.3706 6.4496 12.3706 8.5024C12.3706 10.8768 14.4554 11.7168 14.5178 11.7376C14.5082 11.7888 14.1866 12.888 13.4186 14.008C12.7338 14.9936 12.0186 15.9776 10.9306 15.9776C9.84259 15.9776 9.56259 15.3456 8.30659 15.3456C7.08259 15.3456 6.64739 15.9984 5.65219 15.9984C4.65699 15.9984 3.96259 15.0864 3.16419 13.9664C2.23939 12.6512 1.49219 10.608 1.49219 8.6688C1.49219 5.5584 3.51459 3.9088 5.50499 3.9088C6.56259 3.9088 7.44419 4.6032 8.10819 4.6032C8.74019 4.6032 9.72579 3.8672 10.929 3.8672C11.385 3.8672 13.0234 3.9088 14.1018 5.4544ZM10.3578 2.5504C10.8554 1.96 11.2074 1.1408 11.2074 0.3216C11.2074 0.208 11.1978 0.0928 11.177 0C10.3674 0.0304 9.40419 0.5392 8.82339 1.2128C8.36739 1.7312 7.94179 2.5504 7.94179 3.3808C7.94179 3.5056 7.96259 3.6304 7.97219 3.6704C8.02339 3.68 8.10659 3.6912 8.18979 3.6912C8.91619 3.6912 9.82979 3.2048 10.3578 2.5504Z"
              fill="#FEFEFE"
            />
          </svg>
          Download on iOS
        </a>
      );
    } else if (platform === 'android') {
      return (
        <a
          className="button button--glass button--mobile-app"
          href="https://play.google.com/store/apps/details?id=com.oraai.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
            <path d="M1 1h22v22H1z" fill="none" />
          </svg>
          Download on Android
        </a>
      );
    } else {
      return (
        <div className="app-badges">
          <img
            src={QRCode}
            alt="Ora QR Code"
            style={{
              height: '4rem',
              width: '4rem',
              display: 'block',
            }}
          />
          <img
            src={AppPromo}
            style={{
              height: '4rem',
              width: 'auto',
              display: 'block',
            }}
            alt="App Store Promo"
          />
          <p>Download the Ora mobile app</p>
        </div>
      );
    }
  };

  return (
    <footer className="ms-footer">
      <div className="footer_main">
        <div className="footer_main_left">
          <OraLogo type="link" url={'https://www.oraai.com/'} />
          <div>
            <p>The new gold standard QBank for Step prep</p>
          </div>
          {renderMobileAppCTA()}
        </div>
        <div className="footer_main_right">
          <div className="link-set">
            <div className="section-label">Site</div>
            <div className="links">
              <div>
                <Link to={'https://www.oraai.com/content'}>Content</Link>
              </div>
              <div>
                <Link to={'/library'}>Library</Link>
              </div>
              <div>
                <Link to={'https://www.oraai.com/team'}>Team</Link>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.Intercom('showNewMessage');
                  }}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
          <div className="link-set">
            <div className="section-label">About</div>
            <div className="links">
              <div>
                <a href="https://www.oraai.com/terms">Terms of Use</a>
              </div>
              <div>
                <a href="https://www.oraai.com/privacy">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_sub">
        Synaptiq Learning Inc. | &copy; 2025 | All Rights Reserved
      </div>
    </footer>
  );
};

export default MSFooter;
