export const XboxRB: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.0404 14.5949L32.4082 22.9836C32.4582 24.1247 31.5466 25.0772 30.4044 25.0772H5.09986C4.16393 25.0772 3.35241 24.4298 3.14435 23.5173L0.790997 13.1959C0.614162 12.4204 0.912374 11.6136 1.55112 11.1395L6.51766 7.45325C6.83232 7.21971 7.21083 7.08206 7.60233 7.06535C18.8589 6.58491 27.587 10.6001 31.2588 13.131C31.7405 13.463 32.0147 14.0103 32.0404 14.5949Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M15.2511 21.949H12.7243L11.2055 19.4357C11.0919 19.245 10.983 19.0748 10.8785 18.9249C10.7741 18.7751 10.6674 18.6479 10.5584 18.5435C10.454 18.4345 10.3427 18.3528 10.2247 18.2983C10.1112 18.2393 9.9863 18.2098 9.85009 18.2098H9.25754V21.949H7.05762V12.1821H10.5448C12.915 12.1821 14.1001 13.0675 14.1001 14.8384C14.1001 15.1789 14.0479 15.4945 13.9434 15.7851C13.839 16.0712 13.6914 16.33 13.5007 16.5615C13.31 16.7931 13.0785 16.9929 12.806 17.1609C12.5381 17.3289 12.2384 17.4606 11.907 17.5559V17.5832C12.0523 17.6286 12.193 17.7035 12.3293 17.8079C12.4655 17.9078 12.5971 18.0259 12.7243 18.1621C12.8514 18.2983 12.9717 18.4459 13.0853 18.6048C13.2033 18.7592 13.31 18.9113 13.4054 19.0611L15.2511 21.949ZM9.25754 13.8304V16.5479H10.2111C10.6833 16.5479 11.0624 16.4117 11.3485 16.1393C11.6391 15.8623 11.7844 15.5195 11.7844 15.1108C11.7844 14.2572 11.2736 13.8304 10.2519 13.8304H9.25754Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
    <path
      d="M16.1706 21.949V12.1821H19.7259C20.8156 12.1821 21.6534 12.3819 22.2391 12.7815C22.8248 13.1811 23.1177 13.7441 23.1177 14.4706C23.1177 14.9973 22.9384 15.4582 22.5797 15.8532C22.2255 16.2482 21.7714 16.5229 21.2175 16.6773V16.7046C21.9122 16.7908 22.4661 17.0474 22.8793 17.4742C23.2971 17.901 23.5059 18.4209 23.5059 19.0339C23.5059 19.9284 23.1858 20.639 22.5456 21.1657C21.9054 21.6879 21.0313 21.949 19.9234 21.949H16.1706ZM18.3705 13.8031V16.1188H19.3377C19.7917 16.1188 20.1482 16.0099 20.407 15.7919C20.6703 15.5694 20.802 15.2652 20.802 14.8792C20.802 14.1618 20.2662 13.8031 19.1946 13.8031H18.3705ZM18.3705 17.7534V20.328H19.5624C20.071 20.328 20.4683 20.2099 20.7543 19.9738C21.0449 19.7377 21.1902 19.4153 21.1902 19.0066C21.1902 18.6162 21.0472 18.3097 20.7611 18.0872C20.4796 17.8647 20.0846 17.7534 19.576 17.7534H18.3705Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
