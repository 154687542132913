export const BitLH: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6963 15C34.9257 15.0001 31.844 15 31.844 15V21.1634C31.844 21.1634 20.8869 21.1634 13.6963 21.1634C5.13613 21.1634 0 29.0387 0 29.0387C0 29.0387 1.71204 14.9999 13.6963 15Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M13.2432 12V2H15.3574V10.2568H19.6445V12H13.2432Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
  </svg>
);
