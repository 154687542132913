import { hapticsImpactLight } from '../../../utils/haptics';
import VideoSource from '../../../assets/videos/welcomev2.mp4';
import VideoThumbnail from '../../../assets/images/welcome-thumbnail.jpg';
import { Link } from 'react-router-dom';
import { HelpArticleTreeNode } from '../../../types/Help';
// import AvailableSrc from '../../../assets/images/available.png';

interface HelpMainProps {
  helpArticles: HelpArticleTreeNode[];
}

const HelpMain: React.FC<HelpMainProps> = ({ helpArticles }) => {
  const renderHelpArticleGrid = (helpArticles: HelpArticleTreeNode[]) => {
    return helpArticles.map((a) => {
      return (
        <>
          <h2>{a.title}</h2>
          <div className="help_main_content_grid">
            {a.children?.map((c) => {
              return (
                <Link
                  className="help_main_content_grid_item"
                  to={`/help/${c.id}`}
                  onClick={() => {
                    hapticsImpactLight();
                  }}
                >
                  <h3>{c.title}</h3>
                  <p>{c.description}</p>
                </Link>
              );
            })}
          </div>
        </>
      );
    });
  };

  return (
    <>
      <div className="help_main_header">
        <div className="help_main_header_text">
          <h1 id="help">Help Center</h1>
          <p className="subtitle">Your hub for Ora support.</p>
          <p>Need help now? We'd love to chat with you.</p>
          <button
            className="button button--primary"
            onClick={() => {
              hapticsImpactLight();
              window.Intercom('showNewMessage');
            }}
            style={{ width: '12rem' }}
          >
            Start a Chat
          </button>
          {/* <div className="profiles">
            <img
              src={AvailableSrc}
              alt="Profile pictures of available Ora team members"
            />
            <p>We usually respond quickly.</p>
          </div> */}
        </div>
        <div className="help_main_header_video">
          <video
            id="welcome-video"
            width="100%"
            height="auto"
            style={{ maxWidth: '100%', borderRadius: '.5rem' }}
            controls
            poster={VideoThumbnail}
          >
            <source src={VideoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="blur-1"></div>
        <div className="blur-2"></div>
      </div>
      <div className="help_main_content">
        {renderHelpArticleGrid(helpArticles)}
      </div>
    </>
  );
};

export default HelpMain;
