export const PsL2: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32 3C32 1.34315 30.6569 0 29 0H8C6.34315 0 5 1.33969 5 2.99655C5 5.1766 5 8.60954 5 13.5C5 23 0 25.5 0 29V29C0 30.6569 1.34315 32 3 32H20C26.6274 32 32 26.6274 32 20V3Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M8.8623 22.0004V8.90945H11.6301V19.7184H17.2423V22.0004H8.8623Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M18.9985 22.0004V20.006L23.6583 15.6914C24.0546 15.3079 24.387 14.9627 24.6555 14.6559C24.9282 14.3491 25.1349 14.0487 25.2755 13.7546C25.4161 13.4563 25.4864 13.1346 25.4864 12.7894C25.4864 12.4059 25.3991 12.0756 25.2243 11.7987C25.0496 11.5174 24.811 11.3022 24.5084 11.1531C24.2059 10.9996 23.8628 10.9229 23.4793 10.9229C23.0787 10.9229 22.7293 11.0039 22.431 11.1658C22.1327 11.3278 21.9026 11.56 21.7407 11.8626C21.5787 12.1651 21.4978 12.5252 21.4978 12.9428H18.8707C18.8707 12.0863 19.0645 11.3427 19.4523 10.712C19.8401 10.0813 20.3834 9.5934 21.0823 9.24822C21.7812 8.90305 22.5866 8.73047 23.4985 8.73047C24.436 8.73047 25.252 8.89666 25.9466 9.22905C26.6455 9.55717 27.1888 10.0131 27.5766 10.5969C27.9644 11.1808 28.1583 11.8498 28.1583 12.604C28.1583 13.0984 28.0603 13.5863 27.8643 14.0678C27.6725 14.5494 27.3295 15.0842 26.8351 15.6722C26.3408 16.256 25.6441 16.957 24.7449 17.7752L22.8337 19.6481V19.7376H28.3309V22.0004H18.9985Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
