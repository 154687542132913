import React, { useEffect, useRef, useState } from 'react';
import ThreeOrb from '../../../components/Home/ThreeOrb';
import useWindowWidth from '../../../hooks/useWindowWidth';

interface ScreensaverProps {
  isIdle: boolean;
  screenSaverDisplay: string;
  learningObjectives: string[];
}

const Screensaver: React.FC<ScreensaverProps> = ({
  isIdle,
  screenSaverDisplay,
  learningObjectives,
}) => {
  const [loIndex, setLoIndex] = useState(0);
  const [barAnimationKey, setBarAnimationKey] = useState(0);
  const [barAnimationActive, setBarAnimationActive] = useState(false);
  const [showLearningObjectives, setShowLearningObjectives] = useState(false);

  const windowWidth = useWindowWidth();

  const loNumberRef = useRef<HTMLDivElement>(null);
  const loTextRef = useRef<HTMLDivElement>(null);

  const barRef = useRef<HTMLDivElement>(null);

  const streamingIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
    null
  );
  const barTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!isIdle) {
      // If user returns from idle, clean up everything
      cleanupStreams();
      setTimeout(() => {
        if (loNumberRef.current) loNumberRef.current.textContent = '';
        if (loTextRef.current) loTextRef.current.textContent = '';
        setShowLearningObjectives(false);
      }, 1000);
    } else {
      // If user is idle, wait a bit then show the learning objectives
      setTimeout(() => {
        setShowLearningObjectives(true);
      }, 5000);
    }
  }, [isIdle, learningObjectives, windowWidth]);

  const cleanupStreams = () => {
    // Stop streaming text
    if (streamingIntervalRef.current) {
      clearInterval(streamingIntervalRef.current);
      streamingIntervalRef.current = null;
    }
    // Stop the bar timer
    if (barTimerRef.current) {
      clearTimeout(barTimerRef.current);
      barTimerRef.current = null;
    }
    // Reset bar animation
    setBarAnimationActive(false);

    if (loNumberRef.current) loNumberRef.current.textContent = '';
    if (loTextRef.current) loTextRef.current.textContent = '';
  };

  const animateTextStreaming = (
    text: string,
    textTargetRef: React.RefObject<HTMLDivElement>,
    speed = 20,
    onFinish?: () => void
  ) => {
    if (!textTargetRef.current) return null;

    // Clear any existing content before we start
    textTargetRef.current.textContent = '';

    let idx = 0;
    const intervalId = setInterval(() => {
      if (!textTargetRef.current) return;
      textTargetRef.current.textContent += text.charAt(idx);
      idx++;
      if (idx >= text.length) {
        clearInterval(intervalId);
        onFinish?.();
      }
    }, speed);

    return intervalId;
  };

  // Handle text streaming
  useEffect(() => {
    if (!isIdle || !showLearningObjectives || !learningObjectives[loIndex])
      return;

    const numberIntervalId = animateTextStreaming(
      `Learning Objective #${loIndex + 1}`,
      loNumberRef,
      20,
      () => {
        const textIntervalId = animateTextStreaming(
          learningObjectives[loIndex],
          loTextRef,
          20,
          () => {
            // 1) Force a fresh mount of the bar
            setBarAnimationKey((prev) => prev + 1);

            // 2) Ensure it starts at width=0 by clearing .active
            setBarAnimationActive(false);

            // 3) Wait a tick so the bar is fully mounted at 0%
            requestAnimationFrame(() => {
              setBarAnimationActive(true);
            });

            // 4) Then run your 15s timer
            const timerId = setTimeout(() => {
              setBarAnimationActive(false);
              if (loNumberRef.current) loNumberRef.current.textContent = '';
              if (loTextRef.current) loTextRef.current.textContent = '';
              // Pick another random LO
              setLoIndex(Math.floor(Math.random() * learningObjectives.length));
            }, 15000);

            barTimerRef.current = timerId;
          }
        );
        streamingIntervalRef.current = textIntervalId;
      }
    );
    streamingIntervalRef.current = numberIntervalId;

    return () => {
      if (numberIntervalId !== null) clearInterval(numberIntervalId);
      if (streamingIntervalRef.current)
        clearInterval(streamingIntervalRef.current);
    };
  }, [learningObjectives, loIndex, isIdle, showLearningObjectives]);

  // Randomize LO when the array changes
  useEffect(() => {
    if (learningObjectives.length > 0) {
      setLoIndex(Math.floor(Math.random() * learningObjectives.length));
    }
  }, [learningObjectives]);

  return (
    <div
      className={`screensaver${isIdle ? ' in' : ' out'}${
        showLearningObjectives ? ' show-learning-objectives' : ''
      }`}
      style={{ display: screenSaverDisplay }}
    >
      <div className="screensaver_inner">
        <ThreeOrb isScreensaver={true} />
        <div className="screensaver-content">
          <div className="learning-objective-number" ref={loNumberRef} />
          <div className="learning-objective-text" ref={loTextRef} />
          <div
            className={`bar-timer-container ${barAnimationActive ? 'show' : ''}`}
          >
            <div
              key={barAnimationKey}
              ref={barRef}
              className={`bar-timer ${barAnimationActive ? 'active' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screensaver;
