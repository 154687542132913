import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TriangleRight from '../../assets/icons/triangle-right.svg';
import TriangleDown from '../../assets/icons/triangle-down.svg';
import Frame from '../../components/Onboarding/Frame';
import { Check } from 'react-feather';
import CircularProgress from '@mui/material/CircularProgress';
import moment, { Moment } from 'moment';
import { setUserInfo } from '../../slices/auth/authSlice';
import { fetchExams } from '../../services/exams';
import { addOrUpdateUserExam } from '../../services/users';
import { RootState } from '../../store/store';
import { Exam } from '../../types/User';
import { initUserForStudying } from '../../services/study';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { hapticsImpactLight } from '../../utils/haptics';
import { supportedTimezones } from '../../utils/supportedTimezones';
import { updateCurrentUserInfo } from '../../services/auth';
import {
  defaultFlashcards,
  defaultVignettes,
} from '../../utils/contentQuantityUtils';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import { calcDefaultExamDate } from '../../helpers/userHelpers';
import { createAssents, VERSIONS } from '../../services/assent';
import { AssentType } from '../../types/Assent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface OnboardingProps {}

const Onboarding: React.FC<OnboardingProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const parentOnboarding = useRef<HTMLDivElement>(null);
  // const inputDiv1 = useRef<HTMLDivElement>(null);
  // const inputDiv2 = useRef<HTMLDivElement>(null);
  // const hrRef = useRef<HTMLHRElement>(null);
  // const autoSection = useRef<HTMLDivElement>(null);
  // const auto1 = useRef<HTMLDivElement>(null);
  // const auto2 = useRef<HTMLDivElement>(null);
  // const auto3 = useRef<HTMLDivElement>(null);
  const [onboardingFrame, setOnboardingFrame] = useState(0);
  // const [isWatched, setIsWatched] = useState<boolean>(false);
  // const [hasWatchedHalf, setHasWatchedHalf] = useState<boolean>(false);
  const [availableExams, setAvailableExams] = useState<Exam[] | []>([]);
  const [primaryExam, setPrimaryExam] = useState<Exam | undefined>(undefined);
  const [examSelection, setExamSelection] = useState('');
  const [examDate, setExamDate] = useState<Moment | null>(null);
  const [noDateChosen, setNoDateChosen] = useState(false);
  const { refreshStudySessionAndContent } = useRefreshStudySessionAndContent();

  // ONBOARDING SCREENS
  // 0 - Welcome, Choose Exam
  // 1 - Choose Exam
  // 2 - Loader (Non-Diagnostic)

  const [selectedTimezone, setSelectedTimezone] = useState('UTC');
  const [transitionClasses, setTransitionClasses] = useState([
    'reveal-frame',
    '',
    'reveal-frame',
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    createInitialAssents();
    getAvailableExams();
    getUserTimezone();
    if (currentUser && currentUser.isOnboarded) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const exam = availableExams.find((exam) => exam.id === examSelection);
    if (exam) {
      setPrimaryExam(exam);
      setNoDateChosen(false);
    }
  }, [examSelection]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.isOnboarded) {
        navigate('/');
      }
    }
  }, [currentUser]);

  // useEffect(() => {
  //   if (isWatched) {
  //     nextFrame();
  //     handleWatchedVideo();
  //   }
  // }, [isWatched]);

  // useEffect(() => {
  //   if (hasWatchedHalf) {
  //     handleWatchedVideo();
  //   }
  // }, [hasWatchedHalf]);

  useEffect(() => {
    // Load Home Page
    if (onboardingFrame === 2) {
      (async () => {
        // Wait for both loading UI and user preparation to finish
        // await Promise.all([triggerLoadingUI(), prepareUserForStudying()]);
        await Promise.all([prepareUserForStudying()]);
        // Finish final loading UI
        // await wait(1000);
        // auto3.current?.classList.add('done');
        finishOnboarding();
      })();
    }
  }, [onboardingFrame]);

  const getAvailableExams = async () => {
    const response = await fetchExams();
    if (response) {
      setAvailableExams(response);
    }
  };

  const createInitialAssents = async () => {
    if (!currentUser) return;
    const assents = [
      {
        type: AssentType.tos,
        version: VERSIONS.tos,
        dateAccepted: moment().toISOString(),
        location: 'signup',
      },
      {
        type: AssentType.privacyPolicy,
        version: VERSIONS.privacyPolicy,
        dateAccepted: moment().toISOString(),
        location: 'signup',
      },
    ];
    await createAssents(currentUser?.id, assents);
  };

  // const triggerLoadingUI = async () => {
  //   await wait(500);
  //   inputDiv1.current?.classList.add('reveal');

  //   // await wait(1000);
  //   // inputDiv2.current?.classList.add('reveal');

  //   await wait(1000);
  //   hrRef.current?.classList.add('reveal');

  //   await wait(1000);
  //   autoSection.current?.classList.add('reveal');

  //   // Finish loading curriculum UI
  //   await wait(2000);
  //   auto1.current?.classList.add('done');

  //   // Finish generating study session
  //   await wait(2000);
  //   auto2.current?.classList.add('done');

  //   await wait(1000);
  // };

  // const wait = (ms: number | undefined) =>
  //   new Promise((resolve) => setTimeout(resolve, ms));

  const prepareUserForStudying = async () => {
    if (!currentUser) return;

    let examDateString = null;
    if (examDate && !noDateChosen) {
      examDateString = examDate.toISOString();
    } else {
      examDateString = moment(
        calcDefaultExamDate(primaryExam?.name)
      ).toISOString();
    }

    try {
      await initUserForStudying();
      const response = await addOrUpdateUserExam(
        currentUser.id,
        examSelection,
        examDateString,
        defaultFlashcards(
          availableExams.find((exam) => exam.id === examSelection)?.name
        ),
        defaultVignettes(
          availableExams.find((exam) => exam.id === examSelection)?.name
        ),
        true,
        true,
        false,
        null,
        false
      );

      const updatedUser = { ...currentUser };
      updatedUser.exams = response;
      updatedUser.timezone = selectedTimezone;
      dispatch(setUserInfo(updatedUser));

      // Refresh study session and content
      await refreshStudySessionAndContent();
    } catch (error) {
      console.error('Error while preparing user study experience:', error);
    }
  };

  const finishOnboarding = async () => {
    parentOnboarding.current?.classList.add('done');
    const updateUser = await updateCurrentUserInfo({
      isOnboarded: true,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
    navigate('/');
  };

  // const handleWatchedVideo = async () => {
  //   const updateUser = await updateCurrentUserInfo({
  //     hasWatchedWelcomeVideo: true,
  //   });
  //   if (updateUser) {
  //     dispatch(setUserInfo(updateUser));
  //   }
  // };

  const nextFrame = () => {
    const targetFrame = onboardingFrame + 1;
    setTransitionClasses((prev) => {
      const newClasses = [...prev];
      newClasses[onboardingFrame] = 'slide-out';
      return newClasses;
    });
    setOnboardingFrame(targetFrame);
    setTimeout(() => {
      setTransitionClasses((prev) => {
        const newClasses = [...prev];
        newClasses[targetFrame] = 'reveal-frame';
        return newClasses;
      });
    });
  };

  const jumpToFrame = (frame: number) => {
    setTransitionClasses((prev) => {
      const newClasses = [...prev];
      newClasses[onboardingFrame] = 'slide-out';
      return newClasses;
    });
    setOnboardingFrame(frame);
    setTimeout(() => {
      setTransitionClasses((prev) => {
        const newClasses = [...prev];
        newClasses[frame] = 'reveal-frame';
        return newClasses;
      });
    });
  };

  const prevFrame = () => {
    const targetFrame = onboardingFrame - 1;
    setTransitionClasses((prev) => {
      const newClasses = [...prev];
      newClasses[onboardingFrame] = '';
      return newClasses;
    });
    setOnboardingFrame(targetFrame);
    setTimeout(() => {
      setTransitionClasses((prev) => {
        const newClasses = [...prev];
        newClasses[targetFrame] = 'reveal-frame';
        return newClasses;
      });
    });
  };

  const prioritizeExams = () => {
    const priorityExams = availableExams.filter(
      (exam) => exam.name === 'USMLE Step 1' || exam.name === 'USMLE Step 2'
    );
    const otherExams = availableExams.filter(
      (exam) => exam.name !== 'USMLE Step 1' && exam.name !== 'USMLE Step 2'
    );
    priorityExams.sort((a, b) => a.name.localeCompare(b.name));
    otherExams.sort((a, b) => a.name.localeCompare(b.name));
    return { priorityExams, otherExams };
  };

  const sortedExams = prioritizeExams();

  const getUserTimezone = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(
      supportedTimezones[userTimezone]
        ? supportedTimezones[userTimezone]
        : supportedTimezones['UTC']
    );
  };

  const dateIsInPast = (date: Moment | null) => {
    if (!date) return false;
    return date.isBefore(moment().startOf('day'));
  };

  return (
    <>
      <div
        className={`onboarding ${examSelection ? 'exam-selected' : ''}`}
        ref={parentOnboarding}
      >
        {/* 0 - Welcome Screen */}
        <Frame
          currentFrame={onboardingFrame}
          index={0}
          showNext={false}
          showBack={false}
          advanceAllowed={examSelection !== ''}
          transitionClass={transitionClasses[0]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
        >
          <h1>What exam are you studying for?</h1>
          <p>You can add additional exams later.</p>
          <form className="exam-selections">
            {sortedExams.priorityExams.map((exam: Exam) => {
              return (
                <label htmlFor={exam.id} key={exam.id}>
                  <input
                    id={exam.id}
                    type="radio"
                    name="exam"
                    value={exam.id}
                    onChange={() => setExamSelection(exam.id)}
                    onClick={() => {
                      nextFrame();
                      hapticsImpactLight();
                    }}
                  />
                  <div className="option">
                    <span>{exam.name}</span>
                    <Check />
                  </div>
                </label>
              );
            })}
            <Accordion>
              <AccordionSummary
                aria-controls="exam-content"
                id="exam-header"
                sx={{ padding: '0', minHeight: '0 !important' }}
                onClick={() => {
                  hapticsImpactLight();
                }}
              >
                <label>
                  <div
                    className="option"
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <img
                      className="expand-icon expand-icon--closed"
                      src={TriangleRight}
                      alt=""
                    />
                    <img
                      className="expand-icon expand-icon--open"
                      src={TriangleDown}
                      alt=""
                    />
                    <span>Shelf Exams</span>
                  </div>
                </label>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0' }}>
                {sortedExams.otherExams.map((exam: Exam) => {
                  return (
                    <label htmlFor={exam.id} key={exam.id}>
                      <input
                        id={exam.id}
                        type="radio"
                        name="exam"
                        value={exam.id}
                        onChange={() => setExamSelection(exam.id)}
                        onClick={() => {
                          nextFrame();
                          hapticsImpactLight();
                        }}
                      />
                      <div className="option">
                        <span>{exam.name}</span>
                        <Check />
                      </div>
                    </label>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </form>
        </Frame>
        {/* 1 - Choose Exam */}
        <Frame
          currentFrame={onboardingFrame}
          index={1}
          showNext={true}
          showBack={true}
          advanceAllowed={
            !dateIsInPast(examDate) && (noDateChosen || examDate !== null)
          }
          transitionClass={transitionClasses[1]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
        >
          <h1>Choose your exam date</h1>
          <p>
            Ora will schedule content based on your exam date and workload. You
            can change this later.
          </p>
          <DatePicker
            value={examDate}
            onChange={(newValue) => {
              setExamDate(newValue);
              setNoDateChosen(false);
            }}
            minDate={moment.utc().startOf('day')}
            maxDate={moment.utc().add(7, 'years')}
            openTo="month"
            views={['year', 'month', 'day']}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setExamDate(null);
                },
              },
            }}
          />
          <button
            className={`button button--glass ${noDateChosen ? 'active' : ''}`}
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => {
              hapticsImpactLight();
              setNoDateChosen(true);
            }}
          >
            Skip for Now
            {noDateChosen && <Check />}
          </button>
        </Frame>
        {/* Welcome Video */}
        {/* <Frame
          currentFrame={onboardingFrame}
          index={2}
          showNext={false}
          showBack={true}
          advanceAllowed={true}
          transitionClass={transitionClasses[2]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
        >
          <h1 className="m-b-1" style={{ textAlign: 'center' }}>
            Learn How Ora Works
          </h1>
          <WelcomeVideo
            isReminder={false}
            setIsWatched={setIsWatched}
            setHasWatchedHalf={setHasWatchedHalf}
          />
        </Frame> */}
        {/* Choose Exam Date */}
        {/* <Frame
          currentFrame={onboardingFrame}
          index={2}
          showNext={true}
          showBack={true}
          advanceAllowed={
            !dateIsInPast(examDate) && (noDateChosen || examDate !== null)
          }
          transitionClass={transitionClasses[2]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
        >
          <h1>Choose exam date</h1>
          <p>
            Ora will schedule content based on your exam date and workload. You
            can change this later.
          </p>
          <DatePicker
            value={examDate}
            onChange={(newValue) => {
              setExamDate(newValue);
              setNoDateChosen(false);
            }}
            minDate={moment().startOf('day')}
            maxDate={moment().add(7, 'years')}
            openTo="month"
            views={['year', 'month', 'day']}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setExamDate(null);
                },
              },
            }}
          />
          <button
            className={`button button--glass ${noDateChosen ? 'active' : ''}`}
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => {
              hapticsImpactLight();
              setNoDateChosen(true);
              setExamDate(moment(calcDefaultExamDate(primaryExam?.name)));
            }}
          >
            Skip for Now
            {noDateChosen && <Check />}
          </button>
        </Frame> */}
        {/* 3 - Loader (Non-Diagnostic) */}
        <Frame
          currentFrame={onboardingFrame}
          index={2}
          showNext={false}
          showBack={false}
          advanceAllowed={false}
          transitionClass={transitionClasses[2]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress
              sx={{
                color: 'var(--type-body-inverse)',
                height: '1rem !important',
                width: '1rem !important',
              }}
            />
          </div>
          {/* <h2>Setting things up...</h2> */}
          {/* <div className="inputs">
            <div ref={inputDiv1}>
              <ArrowRight />
              {availableExams.find((exam) => exam.id === examSelection)?.name}
            </div> */}
          {/* <div ref={inputDiv2}>
              <ArrowRight />
              {examDate && moment.isMoment(examDate)
                ? examDate.format('MM/DD/YYYY') + ' exam date'
                : 'No exam date'}
            </div> */}
          {/* </div> */}
          {/* <hr ref={hrRef} /> */}
          {/* <div className="automatons" ref={autoSection}>
            <div className="automaton" ref={auto1}>
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
              <Check className="check" />
              Preparing curriculum
            </div>
            <div className="automaton" ref={auto2}>
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
              <Check className="check" />
              Generating study session
            </div>
            <div className="automaton" ref={auto3}>
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
              <Check className="check" />
              Optimizing schedule
            </div>
          </div> */}
        </Frame>
      </div>
    </>
  );
};

export default Onboarding;
