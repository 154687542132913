import { BitHorizontalController } from '../../../assets/svgs/controllers/bitHorizontalController';
import { BitVerticalController } from '../../../assets/svgs/controllers/bitVerticalController';
import { PsController } from '../../../assets/svgs/controllers/psController';
import { XboxController } from '../../../assets/svgs/controllers/xboxController';
import { GamepadType } from '../../../types/Gamepad';
import { hapticsImpactLight } from '../../../utils/haptics';
import CustomModal from '../../Global/CustomModal';

interface SelectGamepadModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  gamepadType: GamepadType | null;
  setGamepadType: (gamepadType: GamepadType) => void;
}

const SelectGamepadModal: React.FC<SelectGamepadModalProps> = ({
  isOpen,
  setIsOpen,
  gamepadType,
  setGamepadType,
}) => {
  return (
    <CustomModal
      name="gamepad-select"
      open={isOpen}
      onClose={setIsOpen}
      glass={true}
    >
      <div className="modal_header">
        <h2>Select Controller</h2>
      </div>
      <div className="modal_content">
        <div className="controller-grid">
          <button
            className={`controller-grid_option ${gamepadType === 'xbox' ? 'selected' : ''}`}
            onClick={() => {
              setGamepadType('xbox');
              setIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <XboxController />
            Xbox
          </button>
          <button
            className={`controller-grid_option ${gamepadType === 'ps' ? 'selected' : ''}`}
            onClick={() => {
              setGamepadType('ps');
              setIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <PsController />
            Playstation
          </button>
          <button
            className={`controller-grid_option ${gamepadType === '8bitHorizontal' ? 'selected' : ''}`}
            onClick={() => {
              setGamepadType('8bitHorizontal');
              setIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <BitHorizontalController />
            8BitDo (Anki Remote) - Horizonal
          </button>
          <button
            className={`controller-grid_option controller-grid_option--vertical ${gamepadType === '8bitVertical' ? 'selected' : ''}`}
            onClick={() => {
              setGamepadType('8bitVertical');
              setIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <BitVerticalController />
            8BitDo (Anki Remote) - Vertical
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectGamepadModal;
