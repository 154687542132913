import {
  BarChart2,
  Book,
  Gift,
  HelpCircle,
  Home,
  Tool,
  User,
} from 'react-feather';
import OraLogo from '../OraLogo';
import { TabTypes } from '../../../types/Global';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../utils/haptics';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import ReferralModal from '../ReferralModal';

interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [activeTab, setActiveTab] = useState<TabTypes>(TabTypes.Home);
  const [referFriendModalIsOpen, setReferFriendModalIsOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      setActiveTab(TabTypes.Home);
    } else if (window.location.pathname === '/performance') {
      setActiveTab(TabTypes.Stats);
    } else if (window.location.pathname === '/library') {
      setActiveTab(TabTypes.Library);
    } else if (window.location.pathname === '/roadmap') {
      setActiveTab(TabTypes.Roadmap);
    } else if (window.location.pathname === '/account') {
      setActiveTab(TabTypes.Account);
    } else if (window.location.pathname === '/help') {
      setActiveTab(TabTypes.Help);
    } else {
      setActiveTab(TabTypes.null);
    }
  }, [navigate]);

  const renderTopOptions = () => {
    return (
      <div className="navbar_options">
        <button
          className={`navbar_options_option ${activeTab === TabTypes.Home ? 'active' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            navigate('/');
          }}
        >
          <Home />
          <span>Home</span>
        </button>
        <button
          className={`navbar_options_option ${activeTab === TabTypes.Stats ? 'active' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            navigate('/performance');
          }}
        >
          <BarChart2 />
          <span>Performance</span>
        </button>
        <button
          className={`navbar_options_option ${activeTab === TabTypes.Library ? 'active' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            navigate('/library');
          }}
        >
          <Book />
          <span>Library</span>
        </button>
      </div>
    );
  };

  const renderBottomOptions = () => {
    return (
      <div className="navbar_options">
        {currentUser?.role === 'admin' && !currentUser?.demoMode && (
          <button
            className={`navbar_options_option`}
            onClick={() => {
              hapticsImpactLight();
              navigate('/admin');
            }}
          >
            <Tool />
            <span>Admin Dashboard</span>
          </button>
        )}
        {currentUser?.isApprovedReferrer && currentUser?.referralId && (
          <button
            className="navbar_options_option"
            onClick={() => {
              hapticsImpactLight();
              setReferFriendModalIsOpen(true);
            }}
          >
            <Gift />
            <span>Refer a Friend</span>
          </button>
        )}
        <button
          className={`navbar_options_option ${activeTab === TabTypes.Help ? 'active' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            navigate('/help');
          }}
        >
          <HelpCircle />
          <span>Help</span>
        </button>
        <button
          className={`navbar_options_option ${activeTab === TabTypes.Account ? 'active' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            navigate('/account');
          }}
        >
          <User />
          <span>Account</span>
        </button>
      </div>
    );
  };

  return (
    <nav className="navbar">
      <div className="navbar_icons">
        <div className="navbar_icons_top">
          <div className="app-icon">
            <OraLogo type={'link'} url={'/'} />
          </div>
          {renderTopOptions()}
        </div>
        <div className="navbar_icons_bottom">{renderBottomOptions()}</div>
      </div>
      <div className="navbar_full">
        <div className="navbar_full_top">
          <div className="app-icon">
            <OraLogo type={'link'} url={'/'} />
          </div>
          {renderTopOptions()}
        </div>
        <div className="navbar_full_bottom">{renderBottomOptions()}</div>
      </div>
      {currentUser &&
        currentUser?.isApprovedReferrer &&
        currentUser?.referralId && (
          <ReferralModal
            currentUser={currentUser}
            referFriendModalIsOpen={referFriendModalIsOpen}
            setReferFriendModalIsOpen={setReferFriendModalIsOpen}
          />
        )}
    </nav>
  );
};

export default NavBar;
