export const UntoggleAll = () => {
  return (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0004 0H36.0004C42.6278 0 48.0004 5.37258 48.0004 12C48.0004 15.7364 46.2927 19.074 43.6153 21.2748C43.867 20.2243 44.0004 19.1277 44.0004 18C44.0004 10.268 37.7324 4 30.0004 4H14.0004C12.8727 4 11.7761 4.13333 10.7256 4.3851C12.9264 1.70766 16.264 0 20.0004 0Z"
        fill="#E9E9EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.001 4C24.3295 2.74418 22.2516 2 20 2C17.7483 2 15.6705 2.74418 13.999 4C13.9993 4 13.9997 4 14 4H26.001Z"
        fill="#FEFEFE"
      />
      <path
        d="M30 6H14C7.37258 6 2 11.3726 2 18C2 24.6274 7.37258 30 14 30H30C36.6274 30 42 24.6274 42 18C42 11.3726 36.6274 6 30 6Z"
        fill="#E9E9EA"
      />
      <path
        d="M24 18C24 12.4772 19.5228 8 14 8C8.47715 8 4 12.4772 4 18C4 23.5228 8.47715 28 14 28C19.5228 28 24 23.5228 24 18Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};
