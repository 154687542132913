import React from 'react';

const NonAuthPage: React.FC<{
  classes?: string;
  children: React.ReactNode;
}> = ({ classes, children }) => {
  return <div className={`non-auth-page ${classes}`}>{children}</div>;
};

export default NonAuthPage;
