export const PsR2: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H24C25.6569 0 27 1.33969 27 2.99655C27 5.1766 27 8.60954 27 13.5C27 23 32 25.5 32 29V29C32 30.6569 30.6569 32 29 32H12C5.37258 32 0 26.6274 0 20V3Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M3.0625 22.0004V8.90945H8.22727C9.21591 8.90945 10.0597 9.08629 10.7585 9.43999C11.4616 9.78942 11.9964 10.2859 12.3629 10.9293C12.7337 11.5685 12.919 12.3207 12.919 13.1857C12.919 14.055 12.7315 14.8029 12.3565 15.4293C11.9815 16.0515 11.4382 16.5288 10.7266 16.8612C10.0192 17.1935 9.16264 17.3597 8.15696 17.3597H4.69886V15.1353H7.70952C8.23793 15.1353 8.67685 15.0629 9.02628 14.918C9.37571 14.7731 9.63565 14.5558 9.80611 14.266C9.98082 13.9762 10.0682 13.6161 10.0682 13.1857C10.0682 12.7511 9.98082 12.3846 9.80611 12.0863C9.63565 11.788 9.37358 11.5621 9.01989 11.4087C8.67045 11.2511 8.2294 11.1722 7.69673 11.1722H5.83026V22.0004H3.0625ZM10.1321 16.043L13.3857 22.0004H10.3303L7.14702 16.043H10.1321Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M14.7983 22.0004V20.006L19.4581 15.6914C19.8544 15.3079 20.1868 14.9627 20.4553 14.6559C20.728 14.3491 20.9347 14.0487 21.0753 13.7546C21.2159 13.4563 21.2862 13.1346 21.2862 12.7894C21.2862 12.4059 21.1989 12.0756 21.0241 11.7987C20.8494 11.5174 20.6108 11.3022 20.3082 11.1531C20.0057 10.9996 19.6626 10.9229 19.2791 10.9229C18.8786 10.9229 18.5291 11.0039 18.2308 11.1658C17.9325 11.3278 17.7024 11.56 17.5405 11.8626C17.3786 12.1651 17.2976 12.5252 17.2976 12.9428H14.6705C14.6705 12.0863 14.8643 11.3427 15.2521 10.712C15.6399 10.0813 16.1832 9.5934 16.8821 9.24822C17.581 8.90305 18.3864 8.73047 19.2983 8.73047C20.2358 8.73047 21.0518 8.89666 21.7464 9.22905C22.4453 9.55717 22.9886 10.0131 23.3764 10.5969C23.7642 11.1808 23.9581 11.8498 23.9581 12.604C23.9581 13.0984 23.8601 13.5863 23.6641 14.0678C23.4723 14.5494 23.1293 15.0842 22.6349 15.6722C22.1406 16.256 21.4439 16.957 20.5447 17.7752L18.6335 19.6481V19.7376H24.1307V22.0004H14.7983Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
