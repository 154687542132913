import { UniversalButtonMap } from '../types/Gamepad';

export const universalButtonMap: UniversalButtonMap = {
  0: {
    xbox: 'A',
    ps: 'A',
    '8bitVertical': 'B',
    '8bitHorizontal': 'A',
  },
  1: {
    xbox: 'B',
    ps: 'B',
    '8bitVertical': 'Y',
    '8bitHorizontal': 'B',
  },
  2: {
    xbox: 'X',
    ps: 'X',
    '8bitVertical': 'A',
    '8bitHorizontal': 'X',
  },
  3: {
    xbox: 'Y',
    ps: 'Y',
    '8bitVertical': 'X',
    '8bitHorizontal': 'Y',
  },
  4: {
    xbox: 'LB',
    ps: 'LB',
    '8bitVertical': 'LB',
    '8bitHorizontal': 'LB',
  },
  5: {
    xbox: 'RB',
    ps: 'RB',
    '8bitVertical': 'RB',
    '8bitHorizontal': 'RB',
  },
  6: {
    xbox: 'LT',
    ps: 'LT',
  },
  7: {
    xbox: 'RT',
    ps: 'RT',
  },
  10: {
    xbox: 'LS',
    ps: 'LS',
  },
  11: {
    xbox: 'RS',
    ps: 'RS',
  },
};
