import React from 'react';
import { universalToIconMap } from './universalToIconMap';

interface GamepadButtonIconProps {
  controllerType: string;
  universalButton: string;
}

export const GamepadButtonIcon: React.FC<GamepadButtonIconProps> = ({
  controllerType,
  universalButton,
}) => {
  const IconComponent = (
    universalToIconMap[
      universalButton as keyof typeof universalToIconMap
    ] as Record<string, React.FC<React.SVGProps<SVGSVGElement>>>
  )[controllerType];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent className="gamepad-icon" />;
};
