import { useDispatch } from 'react-redux';
import { updateCurrentUserInfo } from '../services/auth';
import { setUserInfo } from '../slices/auth/authSlice';
import { ThemeMode } from '../types/User';

export const useUpdateTheme = () => {
  const dispatch = useDispatch();

  const updateTheme = async (theme: ThemeMode) => {
    const updateUser = await updateCurrentUserInfo({ theme: theme });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return { updateTheme };
};
