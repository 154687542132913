export const PsR3: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.82129 13.3956V1.75919H11.4122C12.291 1.75919 13.041 1.91639 13.6622 2.23078C14.2872 2.54139 14.7626 2.98267 15.0883 3.55464C15.4179 4.12282 15.5827 4.79139 15.5827 5.56033C15.5827 6.33305 15.416 6.99783 15.0827 7.55464C14.7493 8.10767 14.2664 8.53192 13.6338 8.82737C13.005 9.12283 12.2436 9.27055 11.3497 9.27055H8.27583V7.29328H10.952C11.4217 7.29328 11.8118 7.22889 12.1224 7.1001C12.433 6.97131 12.6641 6.77813 12.8156 6.52055C12.9709 6.26298 13.0486 5.9429 13.0486 5.56033C13.0486 5.17396 12.9709 4.8482 12.8156 4.58305C12.6641 4.3179 12.4311 4.11714 12.1167 3.98078C11.8061 3.84063 11.4141 3.77055 10.9406 3.77055H9.28152V13.3956H6.82129ZM13.1054 8.1001L15.9974 13.3956H13.2815L10.452 8.1001H13.1054Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M21.5542 13.5546C20.7058 13.5546 19.9501 13.4088 19.2872 13.1171C18.6281 12.8217 18.1073 12.4164 17.7247 11.9012C17.3459 11.3823 17.1508 10.7838 17.1395 10.1058H19.6167C19.6319 10.3899 19.7247 10.6399 19.8952 10.8558C20.0694 11.0679 20.3005 11.2327 20.5883 11.3501C20.8762 11.4675 21.2001 11.5262 21.5599 11.5262C21.9349 11.5262 22.2664 11.4599 22.5542 11.3274C22.8421 11.1948 23.0675 11.0111 23.2304 10.7762C23.3933 10.5414 23.4747 10.2706 23.4747 9.96373C23.4747 9.65313 23.3876 9.37851 23.2133 9.13987C23.0429 8.89745 22.7967 8.70805 22.4747 8.57169C22.1565 8.43533 21.7777 8.36714 21.3383 8.36714H20.2531V6.56033H21.3383C21.7095 6.56033 22.0372 6.49593 22.3213 6.36714C22.6092 6.23836 22.8327 6.06033 22.9917 5.83305C23.1508 5.60199 23.2304 5.33305 23.2304 5.02623C23.2304 4.73457 23.1603 4.47889 23.0202 4.25919C22.8838 4.0357 22.6906 3.86146 22.4406 3.73646C22.1944 3.61146 21.9065 3.54896 21.577 3.54896C21.2436 3.54896 20.9387 3.60957 20.6622 3.73078C20.3857 3.8482 20.1641 4.01676 19.9974 4.23646C19.8308 4.45616 19.7417 4.71373 19.7304 5.00919H17.3724C17.3838 4.33873 17.5751 3.74782 17.9463 3.23646C18.3175 2.7251 18.8175 2.32548 19.4463 2.0376C20.0789 1.74593 20.7929 1.6001 21.5883 1.6001C22.3914 1.6001 23.094 1.74593 23.6963 2.0376C24.2986 2.32926 24.7664 2.7232 25.0997 3.21942C25.4368 3.71184 25.6035 4.26487 25.5997 4.87851C25.6035 5.53002 25.4008 6.07358 24.9917 6.50919C24.5864 6.94479 24.058 7.22131 23.4065 7.33873V7.42964C24.2626 7.53949 24.9141 7.83684 25.3611 8.32169C25.8118 8.80275 26.0353 9.40502 26.0315 10.1285C26.0353 10.7914 25.844 11.3804 25.4577 11.8956C25.0751 12.4107 24.5467 12.816 23.8724 13.1115C23.1982 13.4069 22.4255 13.5546 21.5542 13.5546Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 21.5067C0 19.543 1.59188 17.9512 3.55556 17.9512H28.4444C30.4081 17.9512 32 19.543 32 21.5067C32 23.4704 30.4081 25.0623 28.4444 25.0623H23.1111V30.3956H8.88889V25.0623H3.55556C1.59188 25.0623 0 23.4704 0 21.5067ZM16 25.9512L8.88889 20.6178H23.1111L16 25.9512Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
