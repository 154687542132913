export const BitHorizontalController: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    width="850"
    height="850"
    viewBox="0 0 850 850"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M155.833 502.889C80.2011 502.889 18.8889 441.577 18.8889 365.944C18.8889 291.259 78.6757 230.537 153 229.029V229H155.833H231.389C238.691 229 244.611 234.92 244.611 242.222H214.616C260.833 264.22 292.778 311.352 292.778 365.944C292.778 441.577 231.466 502.889 155.833 502.889Z"
      fill="#A7AEBA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M694.167 502.889C769.799 502.889 831.111 441.577 831.111 365.944C831.111 291.259 771.324 230.537 697 229.029V229H694.167H618.611C611.309 229 605.389 234.92 605.389 242.222H635.384C589.167 264.22 557.222 311.352 557.222 365.944C557.222 441.577 618.534 502.889 694.167 502.889Z"
      fill="#A7AEBA"
    />
    <rect
      y="242.223"
      width="850"
      height="377.778"
      rx="188.889"
      fill="#E5E9F2"
    />
    <circle cx="384.389" cy="502.889" r="27.3889" fill="#A7AEBA" />
    <circle cx="465.611" cy="502.889" r="27.3889" fill="#A7AEBA" />
    <circle cx="663.944" cy="365" r="33.0556" fill="#A7AEBA" />
    <circle cx="663.944" cy="497.223" r="33.0556" fill="#A7AEBA" />
    <circle cx="730.056" cy="431.111" r="33.0556" fill="#A7AEBA" />
    <circle cx="597.833" cy="431.111" r="33.0556" fill="#A7AEBA" />
    <path
      d="M737.732 444.944L735.679 438.204H725.356L723.303 444.944H716.833L726.828 416.511H734.168L744.201 444.944H737.732ZM734.246 433.168L732.193 426.583C732.064 426.144 731.889 425.582 731.67 424.898C731.463 424.201 731.25 423.497 731.031 422.787C730.824 422.064 730.656 421.437 730.527 420.908C730.398 421.437 730.217 422.096 729.985 422.884C729.765 423.658 729.552 424.394 729.346 425.092C729.139 425.789 728.99 426.286 728.9 426.583L726.866 433.168H734.246Z"
      fill="white"
    />
    <path
      d="M654.286 482.739H663.099C666.869 482.739 669.723 483.268 671.66 484.327C673.596 485.386 674.565 487.245 674.565 489.905C674.565 490.977 674.39 491.945 674.042 492.811C673.706 493.676 673.215 494.386 672.57 494.941C671.924 495.496 671.137 495.858 670.207 496.026V496.219C671.149 496.413 672.002 496.749 672.764 497.227C673.525 497.691 674.132 498.376 674.584 499.28C675.049 500.171 675.281 501.359 675.281 502.843C675.281 504.561 674.862 506.033 674.022 507.259C673.183 508.486 671.982 509.429 670.42 510.087C668.87 510.733 667.024 511.056 664.881 511.056H654.286V482.739ZM660.29 493.953H663.777C665.52 493.953 666.727 493.682 667.398 493.14C668.07 492.585 668.406 491.771 668.406 490.699C668.406 489.615 668.005 488.84 667.205 488.375C666.417 487.897 665.165 487.659 663.447 487.659H660.29V493.953ZM660.29 498.718V506.097H664.203C666.01 506.097 667.269 505.749 667.979 505.051C668.69 504.354 669.045 503.418 669.045 502.243C669.045 501.546 668.89 500.932 668.58 500.403C668.27 499.874 667.747 499.46 667.011 499.163C666.288 498.866 665.287 498.718 664.009 498.718H660.29Z"
      fill="white"
    />
    <path
      d="M597.932 428.287L603.84 416.628H610.309L600.915 433.924V444.944H594.949V434.117L585.556 416.628H592.063L597.932 428.287Z"
      fill="white"
    />
    <path
      d="M677.18 379.777H670.323L663.738 369.067L657.153 379.777H650.722L660.116 365.174L651.323 351.461H657.947L664.048 361.648L670.033 351.461H676.502L667.611 365.503L677.18 379.777Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M219.111 339.5C219.111 335.327 215.728 331.944 211.556 331.944H160.556C156.383 331.944 153 335.327 153 339.5V390.5C153 394.673 149.617 398.055 145.444 398.055H94.4445C90.2717 398.055 86.8889 401.438 86.8889 405.611L86.8889 456.611C86.8889 460.784 90.2717 464.167 94.4445 464.167H145.444C149.617 464.167 153 467.549 153 471.722V522.722C153 526.895 156.383 530.278 160.556 530.278H211.556C215.728 530.278 219.111 526.895 219.111 522.722V471.722C219.111 467.549 222.494 464.167 226.667 464.167H277.667C281.84 464.167 285.222 460.784 285.222 456.611V405.611C285.222 401.438 281.84 398.055 277.667 398.055H226.667C222.494 398.055 219.111 394.673 219.111 390.5V339.5Z"
      fill="#A7AEBA"
    />
    <path
      d="M174.722 360.277L186.056 343.277L197.389 360.277H174.722Z"
      fill="white"
    />
    <path
      d="M256.889 419.777L273.889 431.111L256.889 442.444L256.889 419.777Z"
      fill="white"
    />
    <path
      d="M115.222 419.777L98.2222 431.111L115.222 442.444L115.222 419.777Z"
      fill="white"
    />
    <path
      d="M174.722 501.944L186.056 518.944L197.389 501.944H174.722Z"
      fill="white"
    />
  </svg>
);
