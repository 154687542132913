import { useCallback, useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../../utils/haptics';
import React from 'react';
enum ValueType {
  serum = 'serum',
  cerebrospinal = 'cerebrospinal',
  blood = 'blood',
  urinebmi = 'urinebmi',
}

interface HighlightTextProps {
  text: string;
  searchTerm: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, searchTerm }) => {
  if (!searchTerm) return <>{text}</>;

  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) => (
        <span key={index} className={regex.test(part) ? 'match' : ''}>
          {part}
        </span>
      ))}
    </>
  );
};

interface RecursiveTextProps {
  children: React.ReactNode;
  searchTerm: string;
}

const RecursiveText: React.FC<RecursiveTextProps> = ({
  children,
  searchTerm,
}) => {
  return (
    <>
      {React.Children.map(children, (child) => {
        if (typeof child === 'string') {
          return <HighlightText text={child} searchTerm={searchTerm} />;
        } else if (React.isValidElement(child)) {
          return React.cloneElement(
            child,
            child.props,
            <RecursiveText searchTerm={searchTerm}>
              {child.props.children}
            </RecursiveText>
          );
        } else {
          return child;
        }
      })}
    </>
  );
};

interface LabValuesProps {
  labValuesRef: React.RefObject<HTMLDivElement>;
}

const LabValues: React.FC<LabValuesProps> = ({ labValuesRef }) => {
  const [activeTab, setActiveTab] = useState<ValueType>(ValueType.serum);
  const [activeSI, setActiveSI] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTabMatches, setSearchTabMatches] = useState<ValueType[]>([]);

  const changeTab = (newTab: ValueType) => {
    setActiveTab(newTab);
    hapticsImpactLight();
  };

  const findMatches = useCallback(() => {
    if (!searchTerm) {
      setSearchTabMatches([]);
      return;
    }

    const matches: ValueType[] = [];
    Object.values(ValueType).forEach((value) => {
      const chart = document.getElementById(value);
      if (chart && chart.textContent?.includes(searchTerm)) {
        matches.push(value);
      }
    });
    setSearchTabMatches(matches);
  }, [searchTerm]);

  useEffect(() => {
    findMatches();
  }, [findMatches]);

  return (
    <div className="lab-values" ref={labValuesRef}>
      <div className="top">
        <div className="head">
          <input
            className="lab-values-search"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={hapticsImpactLight}
            style={{ fontSize: '16px' }}
          />
          <label className="si-toggle" htmlFor="si">
            <input
              type="checkbox"
              id="si"
              checked={activeSI}
              onChange={() => setActiveSI(!activeSI)}
              onClick={hapticsImpactLight}
            />
            SI Reference Intervals
          </label>
        </div>
        <div className="tabs">
          <button
            className={`tab ${activeTab === ValueType.serum ? 'active' : ''} ${searchTabMatches.includes(ValueType.serum) ? 'match-found' : ''}`}
            onClick={() => changeTab(ValueType.serum)}
          >
            Serum
          </button>
          <button
            className={`tab ${activeTab === ValueType.cerebrospinal ? 'active' : ''} ${searchTabMatches.includes(ValueType.cerebrospinal) ? 'match-found' : ''}`}
            onClick={() => changeTab(ValueType.cerebrospinal)}
          >
            Cerebrospinal
          </button>
          <button
            className={`tab ${activeTab === ValueType.blood ? 'active' : ''} ${searchTabMatches.includes(ValueType.blood) ? 'match-found' : ''}`}
            onClick={() => changeTab(ValueType.blood)}
          >
            Blood
          </button>
          <button
            className={`tab ${activeTab === ValueType.urinebmi ? 'active' : ''} ${searchTabMatches.includes(ValueType.urinebmi) ? 'match-found' : ''}`}
            onClick={() => changeTab(ValueType.urinebmi)}
          >
            Urine and BMI
          </button>
        </div>
      </div>
      <div
        id="serum"
        className={`chart serum ${activeTab === ValueType.serum ? 'show' : ''}`}
      >
        <RecursiveText searchTerm={searchTerm}>
          <table>
            <thead>
              <tr>
                <th className="labName">Serum</th>
                <th className="refRange">Reference Range</th>
                <th className={`SI ${activeSI ? 'show' : ''}`}>SI Reference</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alanine aminotransferase (ALT)</td>
                <td className="refRange">10-40 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>10-40 U/L</td>
              </tr>
              <tr>
                <td>Aspartate aminotransferase (AST)</td>
                <td className="refRange">12-38 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>12-38 U/L</td>
              </tr>
              <tr>
                <td>Alkaline phosphatase</td>
                <td className="refRange">25-100 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>25-100 U/L</td>
              </tr>
              <tr>
                <td>Amylase</td>
                <td className="refRange">25-125 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>25-125 U/L</td>
              </tr>
              <tr>
                <td>Bilirubin, total // direct</td>
                <td className="refRange">0.1-1.0 mg/dL // 0.0-0.3 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  2-17 µmol/L // 0-5 µmol/L
                </td>
              </tr>
              <tr>
                <td>Calcium</td>
                <td className="refRange">8.4-10.2 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  2.1-2.6 mmol/L
                </td>
              </tr>
              <tr style={{ borderBottom: '0' }}>
                <th colSpan={3}>Cholesterol</th>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>Total</td>
                <td className="refRange">
                  Normal: &lt;200 mg/dL // High: &gt;240 mg/dL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  &lt;5.2 mmol/L // &gt;6.2 mmol/L
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>HDL</td>
                <td className="refRange">40-60 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  1.0-1.6 mmol/L
                </td>
              </tr>
              <tr className="group">
                <td>LDL</td>
                <td className="refRange">&lt;160 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  &lt;4.2 mmol/L
                </td>
              </tr>
              <tr>
                <td>Triglycerides</td>
                <td className="refRange">
                  Normal: &lt;150 mg/dL // Borderline: 151-199 mg/dL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  &lt;1.70 mmol/L // 1.71-2.25 mmol/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Cortisol</td>
                <td className="refRange">
                  0800 h: 5-23 µg/dL // 1600 h: 3-15 µg/dL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  138-635 nmol/L // 82-413 nmol/L
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">2000 h: &lt;50% of 0800 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  Fraction of 0800 h: &lt;0.50
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Creatine kinase</td>
                <td className="refRange">Male: 25-90 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>25-90 U/L</td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 10-70 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>10-70 U/L</td>
              </tr>
              <tr>
                <td>Creatinine</td>
                <td className="refRange">0.6-1.2 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  53-106 µmol/L
                </td>
              </tr>
              <tr>
                <td>Urea nitrogen</td>
                <td className="refRange">7-18 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  2.5-6.4 mmol/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Creatinine clearance</td>
                <td className="refRange">Male: 97-137 mL/min</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  97-137 mL/min
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 88-128 mL/min</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  88-128 mL/min
                </td>
              </tr>
              <tr style={{ borderBottom: '0' }}>
                <th colSpan={3}>Electrolytes, serum</th>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>
                  Sodium (Na<sup>+</sup>)
                </td>
                <td className="refRange">136-146 mEq/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  136-146 mmol/L
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>
                  Potassium (K<sup>+</sup>)
                </td>
                <td className="refRange">3.5-5.0 mEq/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  3.5-5.0 mmol/L
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>
                  Chloride (Cl<sup>-</sup>)
                </td>
                <td className="refRange">95-105 mEq/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  95-105 mmol/L
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>
                  Bicarbonate (HCO<sub>3</sub>
                  <sup>-</sup>)
                </td>
                <td className="refRange">22-28 mEq/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>22-28 mmol/L</td>
              </tr>
              <tr className="group">
                <td>
                  Magnesium (Mg<sup>2+</sup>)
                </td>
                <td className="refRange">1.5-2.0 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.75-1.0 mmol/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Ferritin</td>
                <td className="refRange">Male: 20-250 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>20-250 µg/L</td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 10-120 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>10-120 µg/L</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={4}>Follicle-stimulating hormone</td>
                <td className="refRange">Male: 4-25 mIU/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>4-25 U/L</td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td className="refRange">Female: premenopause 4-30 mIU/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>4-30 U/L</td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td className="refRange" style={{ paddingLeft: '1rem' }}>
                  midcycle peak 10-90 mIU/mL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>10-90 U/L</td>
              </tr>
              <tr className="group">
                <td className="refRange" style={{ paddingLeft: '1rem' }}>
                  postmenopause 40-250 mIU/mL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>40-250 U/L</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Glucose</td>
                <td className="refRange">Fasting: 70-100 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  3.8-5.6 mmol/L
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">
                  Random, non-fasting: &lt;140 mg/dL{' '}
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  &lt;7.77 mmol/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Growth hormone - arginine stimulation</td>
                <td className="refRange">Fasting: &lt;5 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>&lt;5 µg/L</td>
              </tr>
              <tr className="group">
                <td className="refRange">Provocative stimuli: &gt;7 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>&gt;7 µg/L</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Iron</td>
                <td className="refRange">Male: 65-175 µg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  11.6-31.3 µmol/L
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 50-170 µg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  9.0-30.4 µmol/L
                </td>
              </tr>
              <tr>
                <td>Total iron-binding capacity</td>
                <td className="refRange">250-400 µg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  44.8-71.6 µmol/L
                </td>
              </tr>
              <tr>
                <td>Transferrin</td>
                <td className="refRange">200-360 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>2.0-3.6 g/L</td>
              </tr>
              <tr>
                <td>Lactate dehydrogenase</td>
                <td className="refRange">45-200 U/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>45-200 U/L</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={4}>Luteinizing hormone</td>
                <td className="refRange">Male: 6-23 mIU/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>6-23 U/L</td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td className="refRange">
                  Female: follicular phase 5-30 mIU/mL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>5-30 IU/L</td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td className="refRange" style={{ paddingLeft: '1rem' }}>
                  midcycle 75-150 mIU/mL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>75-150 IU/L</td>
              </tr>
              <tr className="group">
                <td className="refRange" style={{ paddingLeft: '1rem' }}>
                  postmenopause 30-200 mIU/mL
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>30-200 IU/L</td>
              </tr>
              <tr>
                <td>Osmolality</td>
                <td className="refRange">
                  275-295 mOsmol/kg H<sub>2</sub>O
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  275-295 mOsmol/kg H<sub>2</sub>O
                </td>
              </tr>
              <tr>
                <td>Intact parathyroid hormone (PTH)</td>
                <td className="refRange">10-60 pg/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>10-60 ng/L</td>
              </tr>
              <tr>
                <td>Phosphorus (inorganic)</td>
                <td className="refRange">3.0-4.5 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  1.0-1.5 mmol/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Prolactin (hPRL)</td>
                <td className="refRange">Male: &lt;17 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>&lt;17 µg/L</td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: &lt;25 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>&lt;25 µg/L</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <th colSpan={3}>Proteins</th>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>Total</td>
                <td className="refRange">6.0-7.8 g/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>60-78 g/L</td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>Albumin</td>
                <td className="refRange">3.5-5.5 g/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>35-55 g/L</td>
              </tr>
              <tr className="group">
                <td>Globulin</td>
                <td className="refRange">2.3-3.5 g/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>23-35 g/L</td>
              </tr>
              <tr>
                <td>Troponin I</td>
                <td className="refRange">≤0.04 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>≤0.04 µg/L</td>
              </tr>
              <tr>
                <td>TSH</td>
                <td className="refRange">0.4-4.0 μU/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.4-4.0 mIU/L
                </td>
              </tr>
              <tr>
                <td>
                  Thyroidal iodine (<sup>123</sup>I) uptake
                </td>
                <td className="refRange">8%-30% of administered dose/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.08-0.30/24 h
                </td>
              </tr>
              <tr>
                <td>
                  Thyroxine (T<sub>4</sub>)
                </td>
                <td className="refRange">5-12 µg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  64-155 nmol/L
                </td>
              </tr>
              <tr>
                <td>
                  Free T<sub>4</sub>
                </td>
                <td className="refRange">0.9-1.7 ng/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  12.0-21.9 pmol/L
                </td>
              </tr>
              <tr>
                <td>
                  Triiodothyronine (T<sub>3</sub>) (RIA)
                </td>
                <td className="refRange">100-200 ng/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  1.5-3.1 nmol/L
                </td>
              </tr>
              <tr>
                <td>
                  Triiodothyronine (T<sub>3</sub>) resin uptake
                </td>
                <td className="refRange">25%-35%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.25-0.35</td>
              </tr>
              <tr>
                <td>Uric acid</td>
                <td className="refRange">3.0-8.2 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.18-0.48 mmol/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <th colSpan={3}>Immunoglobulins</th>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>IgA</td>
                <td className="refRange">76-390 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.76-3.90 g/L
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>IgE</td>
                <td className="refRange">0-380 IU/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0-380 kIU/L</td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>IgG</td>
                <td className="refRange">650-1500 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>6.5-15.0 g/L</td>
              </tr>
              <tr className="group">
                <td>IgM</td>
                <td className="refRange">50-300 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.5-3.0 g/L</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <th colSpan={3}>Gases, arterial blood (room air)</th>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>pH</td>
                <td className="refRange">7.35-7.45</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  [H<sup>+</sup>] 36-44 nmol/L
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td>
                  Pco<sub>2</sub>
                </td>
                <td className="refRange">33-45 mm Hg</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>4.4-5.9 kPa</td>
              </tr>
              <tr className="group">
                <td>
                  Po<sub>2</sub>
                </td>
                <td className="refRange">75-105 mm Hg</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  10.0-14.0 kPa
                </td>
              </tr>
            </tbody>
          </table>
        </RecursiveText>
      </div>
      <div
        id="cerebrospinal"
        className={`chart cerebrospinal ${activeTab === ValueType.cerebrospinal ? 'show' : ''}`}
      >
        <RecursiveText searchTerm={searchTerm}>
          <table>
            <thead>
              <tr>
                <th className="labName">Cerebrospinal Fluid</th>
                <th className="refRange">Reference Range</th>
                <th className={`SI ${activeSI ? 'show' : ''}`}>SI Reference</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cell count</td>
                <td className="refRange">
                  0-5/mm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0-5 x 10<sup>6</sup>/L
                </td>
              </tr>
              <tr>
                <td>Chloride</td>
                <td className="refRange">118-132 mEq/L</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  118-132 mmol/L
                </td>
              </tr>
              <tr>
                <td>Gamma globulin</td>
                <td className="refRange">3%-12% total proteins</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.03-0.12</td>
              </tr>
              <tr>
                <td>Glucose</td>
                <td className="refRange">40-70 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  2.2-3.9 mmol/L
                </td>
              </tr>
              <tr>
                <td>Pressure</td>
                <td className="refRange">
                  70-180 mm H<sub>2</sub>O
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  70-180 mm H<sub>2</sub>O
                </td>
              </tr>
              <tr style={{ borderBottom: '0' }}>
                <td>Proteins, total</td>
                <td className="refRange">&lt;40 mg/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>&lt;0.40 g/L</td>
              </tr>
            </tbody>
          </table>
        </RecursiveText>
      </div>
      <div
        id="blood"
        className={`chart blood ${activeTab === ValueType.blood ? 'show' : ''}`}
      >
        <RecursiveText searchTerm={searchTerm}>
          <table>
            <thead>
              <tr>
                <th className="labName">Hematologic</th>
                <th className="refRange">Reference Range</th>
                <th className={`SI ${activeSI ? 'show' : ''}`}>SI Reference</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Erythrocyte count (RBC)</td>
                <td className="refRange">
                  Male: 4.3-5.9 million/mm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  4.3-5.9 x 10<sup>12</sup>/L
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">
                  Female: 3.5-5.5 million/mm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  3.5-5.5 x 10<sup>12</sup>/L
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Erythrocyte sedimentation rate (Westergren)</td>
                <td className="refRange">Male: 0-15 mm/h</td>
                <td className="SI group">0-15 mm/h</td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 0-20 mm/h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0-20 mm/h</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Hematocrit</td>
                <td className="refRange">Male: 41%-53%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.41-0.53</td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 36%-46%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.36-0.46</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Hemoglobin, blood</td>
                <td className="refRange">Male: 13.5-17.5 g/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>135-175 g/L</td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 12.0-16.0 g/dL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>120-160 g/L</td>
              </tr>
              <tr>
                <td>
                  Hemoglobin A<sub>1c</sub>
                </td>
                <td className="refRange">≤6%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>≤42 mmol/mol</td>
              </tr>
              <tr>
                <td>Leukocyte count (WBC)</td>
                <td className="refRange">
                  4500-11,000/mm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  4.5-11.0 x 10<sup>9</sup>/L
                </td>
              </tr>
              <tr>
                <td>Neutrophils, segmented</td>
                <td className="refRange">54%-62%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.54-0.62</td>
              </tr>
              <tr>
                <td>Neutrophils, bands</td>
                <td className="refRange">3%-5%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.03-0.05</td>
              </tr>
              <tr>
                <td>Eosinophils</td>
                <td className="refRange">1%-3%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.01-0.03</td>
              </tr>
              <tr>
                <td>Basophils</td>
                <td className="refRange">0%-0.75%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.00-0.0075</td>
              </tr>
              <tr>
                <td>Lymphocytes</td>
                <td className="refRange">25%-33%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.25-0.33</td>
              </tr>
              <tr>
                <td>Monocytes</td>
                <td className="refRange">3%-7%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.03-0.07</td>
              </tr>
              <tr>
                <td>CD4+ T-lymphocyte count</td>
                <td className="refRange">
                  ≥500/mm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  ≥0.5 x 10<sup>9</sup>/L
                </td>
              </tr>
              <tr>
                <td>Platelet count</td>
                <td className="refRange">
                  150,000-400,000/mm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  150-400 x 10<sup>9</sup>/L
                </td>
              </tr>
              <tr>
                <td>Reticulocyte count</td>
                <td className="refRange">0.5%-1.5%</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>0.005-0.015</td>
              </tr>
              <tr>
                <td>D-Dimer</td>
                <td className="refRange">≤250 ng/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>≤1.4 nmol/L</td>
              </tr>
              <tr>
                <td>Partial thromboplastin time (PTT) (activated)</td>
                <td className="refRange">25-40 seconds</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  25-40 seconds
                </td>
              </tr>
              <tr>
                <td>Prothrombin time (PT)</td>
                <td className="refRange">11-15 seconds</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  11-15 seconds
                </td>
              </tr>
              <tr>
                <td>Mean corpuscular hemoglobin (MCH)</td>
                <td className="refRange">25-35 pg/cell</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.39-0.54 fmol/cell
                </td>
              </tr>
              <tr>
                <td>Mean corpuscular hemoglobin concentration (MCHC)</td>
                <td className="refRange">31%-36% Hb/cell</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  4.8-5.6 mmol Hb/L
                </td>
              </tr>
              <tr>
                <td>Mean corpuscular volume (MCV)</td>
                <td className="refRange">
                  80-100 µm<sup>3</sup>
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>80-100 fL</td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <th colSpan={3}>Volume</th>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Plasma</td>
                <td className="refRange">Male: 25-43 mL/kg</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.025-0.043 L/kg
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td className="refRange">Female: 28-45 mL/kg</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.028-0.045 L/kg
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>Red cell</td>
                <td className="refRange">Male: 20-36 mL/kg</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.020-0.036 L/kg
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 19-31 mL/kg</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  0.019-0.031 L/kg
                </td>
              </tr>
            </tbody>
          </table>
        </RecursiveText>
      </div>
      <div
        id="urinebmi"
        className={`chart urinebmi ${activeTab === ValueType.urinebmi ? 'show' : ''}`}
      >
        <RecursiveText searchTerm={searchTerm}>
          <table>
            <thead>
              <tr>
                <th className="labName">Urine</th>
                <th className="refRange">Reference Range</th>
                <th className={`SI ${activeSI ? 'show' : ''}`}>SI Reference</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Calcium</td>
                <td className="refRange">100-300 mg/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  2.5-7.5 mmol/24 h
                </td>
              </tr>
              <tr>
                <td>Osmolality</td>
                <td className="refRange">
                  50-1200 mOsmol/kg H<sub>2</sub>O
                </td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  50-1200 mOsmol/kg H<sub>2</sub>O
                </td>
              </tr>
              <tr>
                <td>Oxalate</td>
                <td className="refRange">8-40 µg/mL</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  90-445 µmol/L
                </td>
              </tr>
              <tr>
                <td>Proteins, total</td>
                <td className="refRange">&lt;150 mg/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  &lt;0.15 g/24 h
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>17-Hydroxycorticosteroids</td>
                <td className="refRange">Male: 3.0-10.0 mg/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  8.2-27.6 µmol/24 h
                </td>
              </tr>
              <tr className="group">
                <td className="refRange">Female: 2.0-8.0 mg/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  5.5-22.0 µmol/24 h
                </td>
              </tr>
              <tr style={{ borderColor: 'transparent' }}>
                <td rowSpan={2}>17-Ketosteroids, total</td>
                <td className="refRange">Male: 8-20 mg/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  28-70 µmol/24 h
                </td>
              </tr>
              <tr className="group" style={{ borderColor: 'transparent' }}>
                <td className="refRange">Female: 6-15 mg/24 h</td>
                <td className={`SI ${activeSI ? 'show' : ''}`}>
                  21-52 µmol/24 h
                </td>
              </tr>
            </tbody>
            <thead>
              <tr style={{ borderColor: 'transparent' }}>
                <th className="labName">Body Mass Index (BMI)</th>
                <td colSpan={2}>
                  Adult: 19-25 kg/m<sup>2</sup>
                </td>
              </tr>
            </thead>
          </table>
        </RecursiveText>
      </div>
    </div>
  );
};

export default LabValues;
