import axiosInstance from './axiosCreator';

// Get help article by id
export const getHelpArticle = async (id: string) => {
  const response = await axiosInstance.get('/help/' + id);
  return response.data;
};

// Get article tree
export const getArticleTree = async () => {
  const response = await axiosInstance.get('/help/tree');
  return response.data;
};
