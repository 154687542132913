export const PsX: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M12.0002 10.2324L10.2324 12.0002L14.2324 16.0002L10.2324 20.0002L12.0002 21.768L16.0002 17.768L20.0002 21.768L21.768 20.0002L17.768 16.0002L21.768 12.0002L20.0002 10.2324L16.0002 14.2324L12.0002 10.2324Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
