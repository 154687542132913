import { Flashcard } from '../types/Flashcard';
import { Block, StudySession } from '../types/Study';
import { secondsToDisplayTime } from './secondsToDisplayTime';

const SECONDS_PER_FLASHCARD = 15;
const SECONDS_PER_VIGNETTE = 90;
const SECONDS_PER_VIGNETTE_REVIEW = 90;

export const calculateStudyTimeRemaining = (
  todaySession: StudySession,
  blocks: Block[]
) => {
  // Flashcards
  const totalFlashcards = todaySession.flashcardCount || 0;
  const remainingCards = Math.max(
    0,
    totalFlashcards - todaySession.completedFlashcardCount
  );

  // Vignettes
  const totalVignetteCount = todaySession.vignetteCount || 0;
  const completedVignettes = blocks.reduce(
    (count, block) =>
      count +
      (block.isSubmitted
        ? block.vignetteCount
        : Object.keys(block.answers).length),
    0
  );
  const remainingVignettes = Math.max(
    0,
    totalVignetteCount - completedVignettes
  );

  const totalSeconds =
    remainingCards * SECONDS_PER_FLASHCARD +
    remainingVignettes * SECONDS_PER_VIGNETTE +
    remainingVignettes * SECONDS_PER_VIGNETTE_REVIEW;
  return secondsToDisplayTime(totalSeconds);
};

export const calculateStudyProgress = (
  todaySession: StudySession,
  flashcards: { new: Flashcard[]; due: Flashcard[] },
  blocks: Block[]
) => {
  let numerator = 0,
    denominator = 0;

  if (todaySession.flashcardCount > 0) {
    const totalFlashcards = todaySession.flashcardCount;
    const completedFlashcards =
      todaySession.flashcardCount -
      (flashcards.new.length + flashcards.due.length);

    numerator += completedFlashcards * SECONDS_PER_FLASHCARD;
    denominator += totalFlashcards * SECONDS_PER_FLASHCARD;
  }

  if (todaySession.vignetteCount > 0) {
    const totalVignetteCount = todaySession.vignetteCount;
    let completedVignettes = 0;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].isSubmitted) {
        completedVignettes += blocks[i].vignetteCount;
      } else {
        completedVignettes += Object.keys(blocks[i].answers).length;
      }
    }

    numerator += completedVignettes * SECONDS_PER_VIGNETTE;
    denominator += totalVignetteCount * SECONDS_PER_VIGNETTE;
  }

  return Math.round((numerator / denominator) * 100);
};

export const calculateFlashcardProgress = (
  todaySession: StudySession,
  flashcards: { new: Flashcard[]; due: Flashcard[] }
) => {
  // Flashcards
  const totalFlashcards = todaySession.flashcardCount || 0;
  const completedFlashcardCount =
    todaySession?.flashcardCount -
    (flashcards?.new.length + flashcards?.due.length);

  return Math.round((completedFlashcardCount / totalFlashcards) * 100);
};
