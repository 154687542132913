export const PsR1: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V16Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M6.82812 25.0006V11.9097H11.9929C12.9815 11.9097 13.8253 12.0865 14.5241 12.4402C15.2273 12.7896 15.7621 13.2861 16.1286 13.9296C16.4993 14.5688 16.6847 15.3209 16.6847 16.1859C16.6847 17.0553 16.4972 17.8031 16.1222 18.4296C15.7472 19.0517 15.2038 19.529 14.4922 19.8614C13.7848 20.1938 12.9283 20.36 11.9226 20.36H8.46449V18.1355H11.4751C12.0036 18.1355 12.4425 18.0631 12.7919 17.9182C13.1413 17.7733 13.4013 17.556 13.5717 17.2662C13.7464 16.9764 13.8338 16.6163 13.8338 16.1859C13.8338 15.7513 13.7464 15.3848 13.5717 15.0865C13.4013 14.7882 13.1392 14.5624 12.7855 14.409C12.4361 14.2513 11.995 14.1725 11.4624 14.1725H9.59588V25.0006H6.82812ZM13.8977 19.0432L17.1513 25.0006H14.0959L10.9126 19.0432H13.8977Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
    <path
      d="M24.2592 11.9097V25.0006H21.4915V14.5368H21.4148L18.4169 16.4161V13.9615L21.6577 11.9097H24.2592Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
