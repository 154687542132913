import axiosInstance from './axiosCreator';

export const createVignetteResponseBE = async (
  vignetteContentId: string,
  answer: string,
  studySessionId: string
) => {
  try {
    const response = await axiosInstance.post('/vignetteResponses', {
      vignetteContentId,
      answer,
      studySessionId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getIncorrectVignetteResponses = async (studySessionId: string) => {
  const response = await axiosInstance.get(
    `/vignetteResponses/incorrect/${studySessionId}`
  );
  return response.data;
};
