// src/context/GamepadProvider.tsx

import React, { useRef, useState } from 'react';
import {
  GamepadButtonEvent,
  GamepadType,
  UniversalButton,
  GamepadAxisEvent,
} from '../types/Gamepad';
import { useGamepad } from '../hooks/useGamepad';
import { GamepadContext } from './GamepadContext';
import { universalButtonMap } from '../utils/universalButtonMap';
import SelectGamepadModal from '../components/Gamepad/SelectGamepadModal';

interface GamepadProviderProps {
  children: React.ReactNode;
}

export const GamepadProvider: React.FC<GamepadProviderProps> = ({
  children,
}) => {
  const [lastButtonEvent, setLastButtonEvent] =
    useState<GamepadButtonEventWithLogical | null>(null);
  const [gamepadType, setGamepadType] = useState<GamepadType | null>(null);
  const [isSelectGamepadModalOpen, setIsSelectGamepadModalOpen] =
    useState(false);
  const previousButtonEventRef = useRef<GamepadButtonEventWithLogical | null>(
    null
  );

  // Scroll target state
  const scrollTargetRef = useRef<HTMLElement | Window>(window);

  // Handle button events
  const handleButtonEvent = (event: GamepadButtonEvent) => {
    const previousEvent = previousButtonEventRef.current;
    const isNewEvent =
      !previousEvent ||
      previousEvent.buttonIndex !== event.buttonIndex ||
      previousEvent.pressed !== event.pressed;

    if (isNewEvent) {
      const mapping = universalButtonMap[event.buttonIndex];
      const universalButton = mapping
        ? mapping[gamepadType as GamepadType]
        : null;

      const eventWithLogical: GamepadButtonEventWithLogical = {
        ...event,
        universalButton: universalButton || null,
      };

      setLastButtonEvent(eventWithLogical);
      previousButtonEventRef.current = eventWithLogical;
    }
  };

  // Handle axis events for scrolling
  const handleAxisEvent = (event: GamepadAxisEvent) => {
    const { axisIndex, value } = event;
    const deadZone = 0.1;
    const scrollSpeed = 20;

    // if gamepad is 8bitvertical, use left and right buttons to scroll
    if (gamepadType === '8bitVertical') {
      if (axisIndex === 0 && Math.abs(value) > deadZone) {
        const scrollAmount = value * scrollSpeed * -1;
        const target = scrollTargetRef.current;
        if (target instanceof Window) {
          window.scrollBy(0, scrollAmount);
        } else {
          target.scrollBy({ top: scrollAmount, behavior: 'auto' });
        }
      }
    } else {
      if (axisIndex === 1 && Math.abs(value) > deadZone) {
        const scrollAmount = value * scrollSpeed;

        const target = scrollTargetRef.current;
        if (target instanceof Window) {
          window.scrollBy(0, scrollAmount);
        } else {
          target.scrollBy({ top: scrollAmount, behavior: 'auto' });
        }
      }
    }
  };

  // Method to set the scroll target
  const setScrollTarget = (target: HTMLElement | Window | null) => {
    scrollTargetRef.current = target || window;
  };

  const handleGamepadConnected = () => {
    if (gamepadType || isSelectGamepadModalOpen) return;
    setIsSelectGamepadModalOpen(true);
  };

  const handleGamepadDisconnected = () => {
    setIsSelectGamepadModalOpen(false);
    setGamepadType(null);
  };

  useGamepad({
    onButtonEvent: handleButtonEvent,
    onAxisEvent: handleAxisEvent,
    onGamepadConnected: handleGamepadConnected,
    onGamepadDisconnected: handleGamepadDisconnected,
  });

  return (
    <GamepadContext.Provider
      value={{
        lastButtonEvent,
        gamepadType,
        setScrollTarget,
        isSelectGamepadModalOpen,
        setIsSelectGamepadModalOpen,
      }}
    >
      {children}
      <SelectGamepadModal
        isOpen={isSelectGamepadModalOpen}
        setIsOpen={setIsSelectGamepadModalOpen}
        gamepadType={gamepadType}
        setGamepadType={setGamepadType}
      />
    </GamepadContext.Provider>
  );
};

// Extend GamepadButtonEvent to include universalButton
interface GamepadButtonEventWithLogical extends GamepadButtonEvent {
  universalButton: UniversalButton | null;
}
