export const XboxB: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M11.8613 22.25V10.3467H16.1943C17.5225 10.3467 18.5435 10.5902 19.2573 11.0771C19.9712 11.5641 20.3281 12.2503 20.3281 13.1357C20.3281 13.7777 20.1095 14.3394 19.6724 14.8208C19.2407 15.3022 18.6873 15.637 18.0122 15.8252V15.8584C18.8589 15.9635 19.534 16.2762 20.0376 16.7964C20.5467 17.3166 20.8013 17.9502 20.8013 18.6973C20.8013 19.7874 20.4111 20.6535 19.6309 21.2954C18.8506 21.9318 17.7853 22.25 16.4351 22.25H11.8613ZM14.5425 12.3223V15.1445H15.7212C16.2746 15.1445 16.709 15.0117 17.0244 14.7461C17.3454 14.4749 17.5059 14.1042 17.5059 13.6338C17.5059 12.7594 16.8529 12.3223 15.5469 12.3223H14.5425ZM14.5425 17.1367V20.2744H15.9951C16.6149 20.2744 17.0991 20.1305 17.4478 19.8428C17.8019 19.555 17.979 19.1621 17.979 18.6641C17.979 18.1882 17.8047 17.8146 17.4561 17.5435C17.113 17.2723 16.6315 17.1367 16.0117 17.1367H14.5425Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
