import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import OraSwitch from '../../CustomMUI/Switch';
import Slider from '@mui/material/Slider';
import moment from 'moment';
import {
  getSliderValue,
  maxFlashcards,
  maxVignettes,
  minFlashcards,
  minVignettes,
  setFlashcardMarks,
  setFlashcardStep,
  setVignetteMarks,
  setVignetteStep,
} from '../../../utils/contentQuantityUtils';
import { daysUntilExam } from '../../../utils/daysUntilExam';
import { fetchStudiedContentCounts } from '../../../services/study';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { hapticsImpactLight } from '../../../utils/haptics';
import TriangleRight from '../../../assets/icons/triangle-right.svg';
import TriangleDown from '../../../assets/icons/triangle-down.svg';

interface ContentSelectionProps {
  contentSelectionOption:
    | 'High Yield'
    | 'Standard'
    | 'Comprehensive'
    | 'Custom'
    | '';
  primaryExamName: string;
  primaryExamDate: string;
  isUsingFlashcards: boolean;
  setIsUsingFlashcards: (value: boolean) => void;
  totalUserSelectedFlashcards: number;
  setTotalUserSelectedFlashcards: (value: number) => void;
  isUsingQbank: boolean;
  setIsUsingQbank: (value: boolean) => void;
  totalUserSelectedVignettes: number;
  setTotalUserSelectedVignettes: (value: number) => void;
  setWorkloadOption: (value: string) => void;
}

const ContentSelection: React.FC<ContentSelectionProps> = ({
  contentSelectionOption,
  primaryExamName,
  primaryExamDate,
  isUsingFlashcards,
  setIsUsingFlashcards,
  totalUserSelectedFlashcards,
  setTotalUserSelectedFlashcards,
  isUsingQbank,
  setIsUsingQbank,
  totalUserSelectedVignettes,
  setTotalUserSelectedVignettes,
  setWorkloadOption,
}) => {
  const [studiedFlashcards, setStudiedFlashcards] = useState(0);
  const [studiedVignettes, setStudiedVignettes] = useState(0);
  const [showFlashcardDaily, setShowFlashcardDaily] = useState(false);
  const [showQBankDaily, setShowQbankDaily] = useState(false);

  const [maxF, setMaxF] = useState(0);
  const [maxV, setMaxV] = useState(0);
  const [minF, setMinF] = useState(0);
  const [minV, setMinV] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    loadStudiedContentCounts();
  }, []);

  useEffect(() => {
    setMaxF(maxFlashcards(primaryExamName));
    setMaxV(maxVignettes(primaryExamName));
    setMinF(minFlashcards(primaryExamName));
    setMinV(minVignettes(primaryExamName));
  }, [primaryExamName]);

  const loadStudiedContentCounts = async () => {
    const res = await fetchStudiedContentCounts();
    let studiedFlashcards = 0;
    let studiedVignettes = 0;
    if (res) {
      studiedFlashcards = res.studiedFlashcards;
      studiedVignettes = res.studiedVignettes;
    }
    setStudiedFlashcards(studiedFlashcards);
    setStudiedVignettes(studiedVignettes);
  };

  const calcFlashcardsPerDay = () => {
    const remainingFlashcards = Math.max(
      0,
      totalUserSelectedFlashcards - studiedFlashcards
    );
    const daysRemaining = daysUntilExam(moment(primaryExamDate));
    let flashcardsPerDay = Math.max(
      2,
      Math.floor(remainingFlashcards / daysRemaining)
    );
    // Ensure the flashcardsPerDay is within the acceptable range
    if (flashcardsPerDay > 300) {
      flashcardsPerDay = 300;
    }
    return flashcardsPerDay;
  };

  const calcVignettesPerDay = () => {
    const remainingVignettes = Math.max(
      0,
      totalUserSelectedVignettes - studiedVignettes
    );
    const daysRemaining = daysUntilExam(moment(primaryExamDate));
    let vignettesPerDay = Math.max(
      2,
      Math.floor(remainingVignettes / daysRemaining)
    );
    // Ensure the vignettesPerDay is within the acceptable range
    if (vignettesPerDay > 160) {
      vignettesPerDay = 160;
    }
    return vignettesPerDay;
  };

  return (
    <div className="content-selection">
      <div className="content-selection_switch-container">
        <div className="content-selection_switch-container_switch">
          <OraSwitch
            checked={isUsingQbank}
            onChange={(e) => {
              setIsUsingQbank(e.target.checked);
              if (!isUsingFlashcards) {
                setIsUsingFlashcards(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
          QBank
        </div>
        <div className="content-selection_switch-container_switch">
          <OraSwitch
            checked={isUsingFlashcards}
            onChange={(e) => {
              setIsUsingFlashcards(e.target.checked);
              if (!isUsingQbank) {
                setIsUsingQbank(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
          Flashcards
        </div>
      </div>
      <div className="content-selection_options">
        <button
          className={`button button--workload-option ${contentSelectionOption === 'High Yield' ? 'is-selected' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            setWorkloadOption('High Yield');
          }}
        >
          <div className="button--workload-option_name">High Yield</div>
          <div className="button--workload-option_quantity">
            {isUsingQbank && `${minV} QBank questions`}
            {isUsingQbank && isUsingFlashcards && ' | '}
            {isUsingFlashcards && `${minF} flashcards`}
          </div>
          <div className="button--workload-option_description">
            Topics for a safe passing score (e.g. stroke, HIV)
          </div>
        </button>
        <button
          className={`button button--workload-option ${contentSelectionOption === 'Standard' ? 'is-selected' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            setWorkloadOption('Standard');
          }}
        >
          <div className="button--workload-option_name">Standard</div>
          <div className="button--workload-option_quantity">
            {isUsingQbank && `${(maxV - minV) / 2 + minV} QBank questions`}
            {isUsingQbank && isUsingFlashcards && ' | '}
            {isUsingFlashcards && `${(maxF - minF) / 2 + minF} flashcards`}
          </div>
          <div className="button--workload-option_description">
            Topics for an above-average score (e.g. Mittelschmerz, silicosis)
          </div>
        </button>
        <button
          className={`button button--workload-option ${contentSelectionOption === 'Comprehensive' ? 'is-selected' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            setWorkloadOption('Comprehensive');
          }}
        >
          <div className="button--workload-option_name">Comprehensive</div>
          <div className="button--workload-option_quantity">
            {isUsingQbank && `${maxV} QBank questions`}
            {isUsingQbank && isUsingFlashcards && ' | '}
            {isUsingFlashcards && `${maxF} flashcards`}
          </div>
          <div className="button--workload-option_description">
            Topics for a peak score (e.g. Behçet's disease, melioidosis)
          </div>
        </button>
      </div>
      <div className="content-selection_advanced">
        <Accordion>
          <AccordionSummary
            aria-controls={`advanced--content`}
            id={`advanced-header`}
            onClick={() => {
              const content = document.querySelector(
                '.modal--workload-selection > .modal_content'
              );
              if (content) {
                setTimeout(() => {
                  content.scrollTo({
                    top: content.scrollHeight,
                    behavior: 'smooth',
                  });
                }, 500);
              }
            }}
          >
            <button
              className={`button button--workload-option ${contentSelectionOption === 'Custom' ? 'is-selected' : ''}`}
            >
              <div className="button--workload-option_name">Custom</div>
              <div className="button--workload-option_description">
                Choose a custom {isUsingQbank ? 'QBank' : ''}
                {isUsingQbank && isUsingFlashcards ? ' and ' : ''}
                {isUsingFlashcards ? 'flashcard' : ''} goal.
              </div>
              <img
                className="expand-icon expand-icon--closed"
                src={TriangleRight}
                alt=""
              />
              <img
                className="expand-icon expand-icon--open"
                src={TriangleDown}
                alt=""
              />
            </button>
          </AccordionSummary>
          <AccordionDetails>
            {isUsingQbank && (
              <section>
                <div className="slider-head">
                  <h4>QBank</h4>
                </div>
                <div
                  className="slider-container"
                  style={!isUsingQbank ? { display: 'none' } : {}}
                >
                  <div className="slider-container_header">
                    <h5>Choose Total Questions</h5>
                    <div
                      className={`daily-counts ${showQBankDaily ? 'reveal' : ''}`}
                    >
                      <div className="count">{calcVignettesPerDay()}</div> New
                      Questions <span className="slash">/</span> Day
                    </div>
                  </div>
                  <div className="slider-wrapper">
                    <div className="top-labels">
                      <div>High Yield</div>
                      <div className="middle">Standard</div>
                      <div>Comprehensive</div>
                    </div>
                    <Slider
                      aria-label="Number of Vignettes"
                      defaultValue={totalUserSelectedVignettes}
                      value={totalUserSelectedVignettes}
                      min={minVignettes(primaryExamName)}
                      max={maxVignettes(primaryExamName)}
                      getAriaValueText={getSliderValue}
                      step={setVignetteStep(primaryExamName)}
                      valueLabelDisplay="auto"
                      marks={setVignetteMarks(primaryExamName)}
                      // @ts-expect-error - TS doesn't like the value having type any
                      onChange={(_, value: number) => {
                        setTotalUserSelectedVignettes(value as number);
                        setShowQbankDaily(true);
                      }}
                      onChangeCommitted={() => setShowQbankDaily(false)}
                    />
                  </div>
                </div>
              </section>
            )}
            {isUsingFlashcards && (
              <section>
                <div className="slider-head">
                  <h4>Flashcards</h4>
                </div>
                <div
                  className="slider-container"
                  style={!isUsingFlashcards ? { display: 'none' } : {}}
                >
                  <div className="slider-container_header">
                    <h5>Choose Total Flashcards</h5>
                    <div
                      className={`daily-counts ${showFlashcardDaily ? 'reveal' : ''}`}
                    >
                      <div className="count">{calcFlashcardsPerDay()}</div> New
                      Flashcards <span className="slash">/</span> Day
                    </div>
                  </div>
                  <div className="slider-wrapper">
                    <div className="top-labels">
                      <div>High Yield</div>
                      <div className="middle">Standard</div>
                      <div>Comprehensive</div>
                    </div>
                    <Slider
                      aria-label="Number of Flashcards"
                      defaultValue={totalUserSelectedFlashcards}
                      value={totalUserSelectedFlashcards}
                      min={minFlashcards(primaryExamName)}
                      max={maxFlashcards(primaryExamName)}
                      getAriaValueText={getSliderValue}
                      step={setFlashcardStep(primaryExamName)}
                      valueLabelDisplay="auto"
                      marks={setFlashcardMarks(primaryExamName)}
                      // @ts-expect-error - TS doesn't like the value having type any
                      onChange={(_, value: number) => {
                        setTotalUserSelectedFlashcards(value as number);
                        setShowFlashcardDaily(true);
                      }}
                      onChangeCommitted={() => setShowFlashcardDaily(false)}
                    />
                  </div>
                </div>
              </section>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ContentSelection;
