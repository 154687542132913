export const XboxController: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="851"
    height="596"
    viewBox="0 0 851 596"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M137.482 46.527C131.666 54.6172 127.767 70.1189 127.767 70.1189L241.563 90.2412H607.931L721.726 70.1189C721.726 70.1189 717.828 54.6172 712.012 46.527C706.84 39.3326 694.412 33.1628 681.47 26.7373C679.858 25.9373 678.239 25.1333 676.624 24.323C652.729 12.3277 611.4 3.50663 611.4 3.50663C611.4 3.50663 591.843 -1.20525 579.482 0.73112C570.761 2.09719 562.231 7.60321 555.551 11.9149C550.858 14.9443 547.078 17.3842 544.788 17.3842H304.706C302.415 17.3842 298.636 14.9443 293.942 11.9149C287.263 7.60321 278.733 2.09719 270.012 0.73112C257.651 -1.20525 238.094 3.50663 238.094 3.50663C238.094 3.50663 196.765 12.3277 172.869 24.323C171.255 25.1333 169.636 25.9373 168.024 26.7372C155.081 33.1627 142.654 39.3326 137.482 46.527Z"
      fill="#A7AEBA"
    />
    <path
      d="M213.114 455.448C163.802 509.457 111.808 595.607 88.9101 595.607C66.0121 595.607 40.3386 570.632 29.2366 556.754C7.1156 529.103 0.0936327 516.757 0.09375 487.367C0.0940021 424.224 5.22827 408.561 14.6652 358.999C28.3412 287.174 44.6159 248.736 69.4815 179.979C82.1454 144.961 90.4742 100.13 118.747 75.8966C153.564 46.0536 198.573 24.5524 246.42 24.5506C264.461 24.5499 261.896 24.5506 273.482 24.5506C288.053 24.5506 294.055 34.9583 302.624 34.9583H424.747H425.441H547.563C556.132 34.9583 562.135 24.5506 576.706 24.5506C588.292 24.5506 585.726 24.5499 603.767 24.5506C651.614 24.5524 696.624 46.0536 731.441 75.8966C759.713 100.13 768.042 144.961 780.706 179.979C805.572 248.736 821.846 287.174 835.522 358.999C844.959 408.561 850.094 424.224 850.094 487.367C850.094 516.757 843.072 529.103 820.951 556.754C809.849 570.632 784.175 595.607 761.277 595.607C738.38 595.607 686.386 509.457 637.073 455.448C622.502 439.489 601.344 424.222 578.788 424.224C521.882 424.227 431.388 424.224 425.094 424.224C418.8 424.224 328.306 424.227 271.4 424.224C248.844 424.222 227.686 439.489 213.114 455.448Z"
      fill="#E5E9F2"
    />
    <circle cx="648.049" cy="108.362" r="28.4279" fill="#A7AEBA" />
    <circle cx="648.049" cy="222.073" r="28.4279" fill="#A7AEBA" />
    <circle cx="704.905" cy="165.217" r="28.4279" fill="#A7AEBA" />
    <circle cx="591.193" cy="165.217" r="28.4279" fill="#A7AEBA" />
    <path
      d="M696.599 152.681H704.178C707.421 152.681 709.875 153.136 711.541 154.047C713.206 154.958 714.039 156.557 714.039 158.844C714.039 159.766 713.889 160.599 713.589 161.343C713.301 162.087 712.879 162.698 712.324 163.175C711.768 163.653 711.091 163.963 710.291 164.108V164.274C711.102 164.441 711.835 164.73 712.49 165.141C713.145 165.54 713.667 166.129 714.056 166.906C714.456 167.672 714.656 168.694 714.656 169.971C714.656 171.448 714.295 172.714 713.573 173.769C712.851 174.824 711.818 175.635 710.475 176.201C709.142 176.756 707.554 177.034 705.711 177.034H696.599V152.681ZM701.763 162.326H704.761C706.26 162.326 707.299 162.092 707.876 161.626C708.454 161.148 708.742 160.449 708.742 159.527C708.742 158.594 708.398 157.928 707.71 157.528C707.032 157.117 705.955 156.912 704.478 156.912H701.763V162.326ZM701.763 166.423V172.769H705.128C706.682 172.769 707.765 172.47 708.376 171.87C708.987 171.27 709.292 170.465 709.292 169.455C709.292 168.855 709.159 168.328 708.892 167.872C708.626 167.417 708.176 167.062 707.543 166.806C706.921 166.551 706.06 166.423 704.961 166.423H701.763Z"
      fill="white"
    />
    <path
      d="M654.651 233.89L652.885 228.093H644.007L642.241 233.89H636.678L645.273 209.437H651.586L660.214 233.89H654.651ZM651.653 223.762L649.887 218.099C649.776 217.721 649.626 217.238 649.437 216.65C649.26 216.05 649.076 215.445 648.888 214.834C648.71 214.212 648.566 213.674 648.454 213.218C648.343 213.674 648.188 214.24 647.988 214.917C647.799 215.584 647.616 216.217 647.438 216.816C647.261 217.416 647.133 217.843 647.055 218.099L645.306 223.762H651.653Z"
      fill="white"
    />
    <path
      d="M602.378 177.034H596.482L590.819 167.823L585.155 177.034H579.625L587.704 164.475L580.141 152.682H585.838L591.085 161.443L596.232 152.682H601.795L594.15 164.758L602.378 177.034Z"
      fill="white"
    />
    <path
      d="M648.096 106.665L653.176 96.6377H658.74L650.661 111.512V120.99H645.531V111.679L637.452 96.6377H643.049L648.096 106.665Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M335.342 242.379C335.342 239.619 333.104 237.381 330.343 237.381H296.605C293.844 237.381 291.606 239.619 291.606 242.379V276.118C291.606 278.878 289.369 281.116 286.608 281.116L252.869 281.116C250.109 281.116 247.871 283.354 247.871 286.114L247.871 319.853C247.871 322.614 250.109 324.851 252.869 324.851H286.608C289.369 324.851 291.606 327.089 291.606 329.85V363.588C291.606 366.349 293.844 368.587 296.605 368.587H330.343C333.104 368.587 335.342 366.349 335.342 363.588V329.85C335.342 327.089 337.58 324.851 340.34 324.851H374.079C376.839 324.851 379.077 322.614 379.077 319.853V286.114C379.077 283.354 376.839 281.116 374.079 281.116L340.34 281.116C337.58 281.116 335.342 278.878 335.342 276.118V242.379Z"
      fill="#A7AEBA"
    />
    <path
      d="M305.977 256.125L313.474 244.878L320.972 256.125H305.977Z"
      fill="white"
    />
    <path
      d="M360.333 295.486L371.58 302.984L360.333 310.481L360.333 295.486Z"
      fill="white"
    />
    <path
      d="M266.615 295.486L255.369 302.984L266.615 310.481L266.615 295.486Z"
      fill="white"
    />
    <path
      d="M305.977 349.843L313.474 361.089L320.972 349.843H305.977Z"
      fill="white"
    />
    <circle cx="541.57" cy="291.882" r="50.4638" fill="#A7AEBA" />
    <circle cx="205.481" cy="161.686" r="50.4638" fill="#A7AEBA" />
    <circle cx="541.571" cy="291.882" r="31.2876" fill="#DDE2ED" />
    <circle cx="205.481" cy="161.686" r="31.2876" fill="#DDE2ED" />
    <circle cx="363.938" cy="162.695" r="20.1855" fill="#A7AEBA" />
    <circle cx="490.097" cy="162.695" r="20.1855" fill="#A7AEBA" />
  </svg>
);
