export const getPlatform = () => {
  // Check if 'navigator' is available
  if (typeof navigator !== 'undefined') {
    // @ts-expect-error - window.MSStream is not defined in the global scope
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for Android
    if (/android/i.test(userAgent)) {
      return 'android';
    }

    // Check for iOS
    // Note: iPad on iOS 13+ may report itself as Mac; this is a common edge case.
    // @ts-expect-error - window.MSStream is not defined in the global scope
    if (/iPhone|iPod|iPad/i.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
  }

  // If neither iOS nor Android, or if 'navigator' is not available, treat as Web
  return 'web';
};
