import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { TopicTreeNode } from '../../../../types/TopicCustomization';
import { updateTopicTree } from '../../../../services/topicCustomization';
import RegenerateModal from '../../RegenerateModal';
import { useEffect, useRef, useState } from 'react';
import { hapticsImpactLight } from '../../../../utils/haptics';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { cloneDeep, isEqual } from 'lodash';
import posthog from 'posthog-js';
import { ChevronsDown, ChevronsUp } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import { setActiveFilters } from '../../../../slices/activeTopics/activeTopicsSlice';
import TriangleRight from '../../../../assets/icons/triangle-right.svg';
import TriangleDown from '../../../../assets/icons/triangle-down.svg';
import OraSwitch from '../../../CustomMUI/Switch';
import CustomModal from '../../CustomModal';
import { UntoggleAll } from '../../../../assets/svgs/UntoggleAll';
import { ToggleAll } from '../../../../assets/svgs/ToggleAll';

interface TopicSelectProps {
  setIsLoadingStudySessionAndContent: (isLoading: boolean) => void;
  setIsLoadedStudySessionAndContent: (isLoaded: boolean) => void;
  refreshStudySessionAndContent: () => void;
}

const TopicSelect: React.FC<TopicSelectProps> = ({
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const todaySession = useSelector((state: RootState) => state.todaySession);

  const topicTreeFromRedux =
    useSelector((state: RootState) => state.activeTopics.topicTree) || [];

  const allTopicsActiveFromRedux = useSelector(
    (state: RootState) => state.activeTopics.allTopicsActive
  );
  const allTopicsInactiveFromRedux = useSelector(
    (state: RootState) => state.activeTopics.allTopicsInactive
  );

  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const secondLevelTopicFiltering = posthog.isFeatureEnabled(
    'second-level-topic-filtering'
  );

  const [originalTopicFilters, setOriginalTopicFilters] = useState<
    TopicTreeNode[]
  >([]);
  const [tempTopicFilters, setTempTopicFilters] = useState<TopicTreeNode[]>([]);
  const [allTopicsActiveLocal, setAllTopicsActiveLocal] = useState(false);
  const [allTopicsInactiveLocal, setAllTopicsInactiveLocal] = useState(false);

  const [showTopicFilters, setShowTopicFilters] = useState(false);
  const [showRegenModal, setShowRegenModal] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const topicMenuButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showTopicFilters) {
      const localCopy = cloneDeep(topicTreeFromRedux);
      setTempTopicFilters(localCopy);
      setOriginalTopicFilters(localCopy);
      setAllTopicsActiveLocal(allTopicsActiveFromRedux);
      setAllTopicsInactiveLocal(allTopicsInactiveFromRedux);
      setUnsavedChanges(false);
    }
  }, [
    showTopicFilters,
    topicTreeFromRedux,
    allTopicsActiveFromRedux,
    allTopicsInactiveFromRedux,
  ]);

  const renderTopicFilterSelections = () => {
    if (allTopicsActiveFromRedux) {
      return 'All Topics';
    } else {
      const activeTopics = topicTreeFromRedux.filter((topic) => topic.active);
      return activeTopics[0]?.name || 'No topics selected';
    }
  };

  const flattenTopicTree = (topicFilters: TopicTreeNode[]): TopicTreeNode[] => {
    let flatTopics: TopicTreeNode[] = [];
    topicFilters.forEach((topic) => {
      const topicObj: TopicTreeNode = {
        id: topic.id,
        name: topic.name,
        path: topic.path,
        level: topic.level,
        active: topic.active,
        articleId: topic.articleId,
      };
      flatTopics.push(topicObj);
      if (topic.children) {
        flatTopics = flatTopics.concat(flattenTopicTree(topic.children));
      }
    });
    return flatTopics;
  };

  const handleTopicChange = (topicId: string, active: boolean) => {
    const updateTopicFilters = (
      topics: TopicTreeNode[],
      parentActive: boolean = true
    ): TopicTreeNode[] => {
      return topics.map((topic) => {
        const updatedTopic = { ...topic };
        if (updatedTopic.id === topicId) {
          updatedTopic.active = active;
          if (active && !parentActive) {
            parentActive = true;
          }
          if (
            active &&
            updatedTopic.children &&
            updatedTopic.children.every((child) => !child.active)
          ) {
            updatedTopic.children = updatedTopic.children.map((child) => ({
              ...child,
              active: true,
            }));
          }
        }

        if (updatedTopic.children) {
          updatedTopic.children = updateTopicFilters(
            updatedTopic.children,
            updatedTopic.active && parentActive
          );
        }

        if (!parentActive) {
          updatedTopic.active = false;
          if (updatedTopic.children) {
            updatedTopic.children = updatedTopic.children.map((child) => ({
              ...child,
              active: false,
            }));
          }
        }

        return updatedTopic;
      });
    };

    const activateParent = (
      topics: TopicTreeNode[],
      myTopicId: string
    ): TopicTreeNode[] => {
      return topics.map((topic) => {
        const updatedTopic = { ...topic };
        if (updatedTopic.children) {
          updatedTopic.children = activateParent(
            updatedTopic.children,
            myTopicId
          );
          if (
            updatedTopic.children.some(
              (child) => child.id === myTopicId && child.active
            )
          ) {
            updatedTopic.active = true;
          }
        }
        return updatedTopic;
      });
    };

    let updatedTopics = updateTopicFilters(cloneDeep(tempTopicFilters));
    if (active) {
      updatedTopics = activateParent(updatedTopics, topicId);
    }

    setAllTopicsActiveLocal(isAllActive(updatedTopics));
    setAllTopicsInactiveLocal(isAllInactive(updatedTopics));
    setTempTopicFilters(updatedTopics);
    setUnsavedChanges(!isEqual(updatedTopics, originalTopicFilters));
  };

  const renderTopicFilters = (topics: TopicTreeNode[]) => {
    if (!topics.length) return null;
    return topics.map((t, i) => (
      <Accordion key={i} className="topic-accordion">
        <AccordionSummary
          sx={
            i !== topics.length - 1
              ? { borderBottom: '1px solid var(--border-glass)', padding: 0 }
              : { padding: 0 }
          }
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            hapticsImpactLight();
          }}
        >
          {t.children && t.children.length > 0 && secondLevelTopicFiltering && (
            <>
              <img
                className="expand-icon expand-icon--closed"
                src={TriangleRight}
                alt=""
              />
              <img
                className="expand-icon expand-icon--open"
                src={TriangleDown}
                alt=""
              />
            </>
          )}
          <div className="row">
            <div>{t.name}</div>
            <OraSwitch
              checked={t.active}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleTopicChange(t.id, e.target.checked)}
            />
          </div>
        </AccordionSummary>
        {secondLevelTopicFiltering && t.children && t.children.length > 0 && (
          <AccordionDetails>
            <div className="level-2">{renderLevel2Topics(t.children)}</div>
          </AccordionDetails>
        )}
      </Accordion>
    ));
  };

  const renderLevel2Topics = (topics: TopicTreeNode[]) => {
    return topics.map((t, i) => (
      <div key={'level-2-' + i} className="row">
        <div>{t.name}</div>
        <OraSwitch
          checked={t.active}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleTopicChange(t.id, e.target.checked)}
        />
      </div>
    ));
  };

  const renderLevelOneOnly = (topics: TopicTreeNode[]) => {
    if (!topics.length) return null;
    return topics.map((t, i) => (
      <button
        key={'level-1-topic-' + i}
        className="button button--topic-filter"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          hapticsImpactLight();
          handleTopicChange(t.id, !t.active);
        }}
      >
        <div className="row">
          <div>{t.name}</div>
          <OraSwitch
            checked={t.active}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleTopicChange(t.id, e.target.checked)}
          />
        </div>
      </button>
    ));
  };

  const saveChangesOrShowConfirmation = async () => {
    setShowTopicFilters(false);
    if (todaySession.isStarted === false) {
      submitChanges(true);
    } else if (todaySession.isComplete) {
      submitChanges(false);
    } else {
      setShowRegenModal(true);
    }
  };

  const submitChanges = async (regenerate: boolean) => {
    if (unsavedChanges) {
      setIsLoadingStudySessionAndContent(true);
      setIsLoadedStudySessionAndContent(false);

      const flatTopics = flattenTopicTree(tempTopicFilters);
      await updateTopicTree(flatTopics, regenerate);
      await refreshStudySessionAndContent();

      dispatch(
        setActiveFilters({
          allTopicsActive: allTopicsActiveLocal,
          allTopicsInactive: allTopicsInactiveLocal,
          topicTree: tempTopicFilters,
        })
      );

      setIsLoadingStudySessionAndContent(false);
      setIsLoadedStudySessionAndContent(true);
      setUnsavedChanges(false);
    }
  };

  const handleUnsavedChanges = () => {
    if (cancelButtonRef.current) {
      cancelButtonRef.current.classList.add('shake');
    }
    if (submitButtonRef.current) {
      submitButtonRef.current.classList.add('shake');
    }
    setTimeout(() => {
      cancelButtonRef.current?.classList.remove('shake');
      submitButtonRef.current?.classList.remove('shake');
    }, 500);
  };

  const handleEnableAllFilters = () => {
    const updatedFilters = bulkChangeTopicFilters(
      cloneDeep(tempTopicFilters),
      true
    );
    setTempTopicFilters(updatedFilters);
    setAllTopicsActiveLocal(true);
    setAllTopicsInactiveLocal(false);
    setUnsavedChanges(!isEqual(updatedFilters, originalTopicFilters));
  };

  const handleDisableAllFilters = () => {
    const updatedFilters = bulkChangeTopicFilters(
      cloneDeep(tempTopicFilters),
      false
    );
    setTempTopicFilters(updatedFilters);
    setAllTopicsActiveLocal(false);
    setAllTopicsInactiveLocal(true);
    setUnsavedChanges(!isEqual(updatedFilters, originalTopicFilters));
  };

  const bulkChangeTopicFilters = (
    topicFilters: TopicTreeNode[],
    enable: boolean
  ): TopicTreeNode[] => {
    return topicFilters.map((topicFilter) =>
      changeTopicFilter({ ...topicFilter }, enable)
    );
  };

  const changeTopicFilter = (
    topicFilter: TopicTreeNode,
    enable: boolean
  ): TopicTreeNode => {
    topicFilter.active = enable;
    if (topicFilter.children) {
      topicFilter.children = topicFilter.children.map((child) =>
        changeTopicFilter({ ...child }, enable)
      );
    }
    return topicFilter;
  };

  const isAllActive = (topics: TopicTreeNode[]): boolean => {
    return topics.every((t) => {
      if (!t.active) return false;
      return t.children ? isAllActive(t.children) : true;
    });
  };
  const isAllInactive = (topics: TopicTreeNode[]): boolean => {
    return topics.every((t) => {
      if (t.active) return false;
      return t.children ? isAllInactive(t.children) : true;
    });
  };

  const expandAllAccordions = () => {
    const accordions = document.querySelectorAll('.topic-accordion');
    accordions.forEach((accordion) => {
      const summary = accordion.querySelector('.MuiAccordionSummary-root');
      if (summary) {
        (summary as HTMLElement).click();
      }
    });
  };

  const collapseAllAccordions = () => {
    const accordions = document.querySelectorAll('.topic-accordion');
    accordions.forEach((accordion) => {
      const summary = accordion.querySelector('.MuiAccordionSummary-root');
      if (summary && accordion.classList.contains('Mui-expanded')) {
        (summary as HTMLElement).click();
      }
    });
  };

  return (
    <>
      <button
        id="topic-filters-button"
        className={`button button--glass button--topic-filters ${
          !allTopicsActiveFromRedux ? 'fill' : ''
        }`}
        onClick={() => {
          setShowTopicFilters(true);
          hapticsImpactLight();
        }}
        tabIndex={0}
        ref={topicMenuButtonRef}
      >
        <div className="button--topic-filters_label">Topic Filters</div>
        <div className="button--topic-filters_value">
          <span className="value">{renderTopicFilterSelections()}</span>
          <span className="spacer">X</span>
        </div>
      </button>
      <CustomModal
        open={showTopicFilters}
        onClose={() => setShowTopicFilters(false)}
        name={'topic-select'}
        glass={true}
        unsavedChanges={unsavedChanges}
        handleUnsavedChanges={handleUnsavedChanges}
      >
        <div className="modal_header">
          <div className="exam-title">
            <span>{primaryExam?.name}</span>
            <h2>Topic Filters</h2>
          </div>
        </div>
        <div className="modal_content">
          <div className="actions">
            <div>
              <Tooltip title="Enable all topics." enterTouchDelay={0}>
                <button
                  className="button button--icon-only custom-svg"
                  onClick={handleEnableAllFilters}
                >
                  <ToggleAll />
                </button>
              </Tooltip>
              <Tooltip title="Disable all topics." enterTouchDelay={0}>
                <button
                  className="button button--icon-only custom-svg"
                  onClick={handleDisableAllFilters}
                >
                  <UntoggleAll />
                </button>
              </Tooltip>
            </div>
            {secondLevelTopicFiltering && (
              <div>
                <Tooltip title="Collapse all topics." enterTouchDelay={0}>
                  <button
                    className="button button--icon-only"
                    onClick={collapseAllAccordions}
                  >
                    <ChevronsUp />
                  </button>
                </Tooltip>
                <Tooltip title="Expand all topics." enterTouchDelay={0}>
                  <button
                    className="button button--icon-only"
                    onClick={expandAllAccordions}
                  >
                    <ChevronsDown />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
          <div>
            {secondLevelTopicFiltering
              ? renderTopicFilters(tempTopicFilters)
              : renderLevelOneOnly(tempTopicFilters)}
          </div>
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass"
            onClick={() => setShowTopicFilters(false)}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
          <Tooltip
            title={
              allTopicsInactiveLocal
                ? 'You must have at least one active topic.'
                : ''
            }
            enterTouchDelay={0}
          >
            <span>
              <button
                ref={submitButtonRef}
                className="button button--glass"
                onClick={saveChangesOrShowConfirmation}
                disabled={!unsavedChanges || allTopicsInactiveLocal}
              >
                Confirm
              </button>
            </span>
          </Tooltip>
        </div>
      </CustomModal>
      <RegenerateModal
        showRegenModal={showRegenModal}
        setShowRegenModal={setShowRegenModal}
        submitChanges={submitChanges}
        type={'exam-settings'}
      />
    </>
  );
};

export default TopicSelect;
