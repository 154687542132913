import { useEffect, useState } from 'react';
import { ThemeMode } from '../types/User';

export function useSystemTheme(): ThemeMode {
  const [theme, setTheme] = useState<ThemeMode>('light');

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    // Helper function to update the state based on the media query match
    const updateTheme = (e: MediaQueryListEvent | MediaQueryList) => {
      setTheme(e.matches ? 'dark' : 'light');
    };

    // Initial check
    updateTheme(mediaQueryList);

    // Add listener for changes
    mediaQueryList.addEventListener('change', updateTheme);

    // Cleanup listener on unmount
    return () => {
      mediaQueryList.removeEventListener('change', updateTheme);
    };
  }, []);

  return theme;
}
