export const XboxRT: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.13643 0.5H25.247C26.6517 0.5 27.5982 1.95627 27.1802 3.29743C24.2026 12.8517 27.4165 22.3874 30.4399 27.7989C31.1177 29.012 30.4842 30.6153 29.115 30.8528C8.1592 34.4878 1.21583 22.6255 0.385485 15.9468C-0.24123 10.906 0.0377464 5.08496 0.240774 2.24309C0.311651 1.25101 1.14183 0.5 2.13643 0.5Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M14.6403 18.3185H11.654L9.85902 15.3483C9.72487 15.1229 9.59608 14.9217 9.47266 14.7446C9.34923 14.5675 9.22313 14.4173 9.09434 14.2939C8.97092 14.1651 8.83945 14.0685 8.69993 14.0041C8.56577 13.9343 8.41821 13.8995 8.25722 13.8995H7.55694V18.3185H4.95703V6.77588H9.07824C11.8794 6.77588 13.2799 7.82228 13.2799 9.91508C13.2799 10.3175 13.2182 10.6905 13.0948 11.0339C12.9714 11.372 12.797 11.6779 12.5716 11.9515C12.3462 12.2252 12.0726 12.4613 11.7506 12.6599C11.434 12.8584 11.0798 13.014 10.6881 13.1267V13.1589C10.8598 13.2126 11.0262 13.3011 11.1871 13.4246C11.3481 13.5426 11.5037 13.6821 11.654 13.8431C11.8043 14.0041 11.9465 14.1785 12.0806 14.3663C12.2201 14.5488 12.3462 14.7285 12.4589 14.9056L14.6403 18.3185ZM7.55694 8.7238V11.9354H8.68383C9.24191 11.9354 9.68999 11.7745 10.0281 11.4525C10.3715 11.1252 10.5432 10.72 10.5432 10.2371C10.5432 9.22821 9.93951 8.7238 8.73213 8.7238H7.55694Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M23.4944 8.89283H20.2023V18.3185H17.5943V8.89283H14.3183V6.77588H23.4944V8.89283Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
