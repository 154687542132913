import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export const renderLoadingFrames = (showTitle: boolean = true) => {
  return (
    <React.Fragment>
      {showTitle && (
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'70%'}
          height={60}
        />
      )}
      <Skeleton
        sx={
          showTitle
            ? {
                margin: '1.5rem 0',
                borderRadius: '1rem',
              }
            : {
                margin: '0 0 1.5rem 0',
                borderRadius: '1rem',
              }
        }
        variant="rectangular"
        animation="wave"
        width={'100%'}
        height={200}
      />
      <Skeleton
        sx={{
          margin: '1.5rem 0',
          borderRadius: '1rem',
        }}
        variant="rectangular"
        animation="wave"
        width={'100%'}
        height={200}
      />
      <Skeleton
        sx={{
          margin: '1.5rem 0',
          borderRadius: '1rem',
        }}
        variant="rectangular"
        animation="wave"
        width={'100%'}
        height={200}
      />
      <Skeleton
        sx={{
          margin: '1.5rem 0',
          borderRadius: '1rem',
        }}
        variant="rectangular"
        animation="wave"
        width={'100%'}
        height={200}
      />
    </React.Fragment>
  );
};
