export const PsL1: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V16Z"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M8.04102 25.0006V11.9097H10.8088V22.7186H16.421V25.0006H8.04102Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
    <path
      d="M23.0463 11.9097V25.0006H20.2786V14.5368H20.2019L17.204 16.4161V13.9615L20.4448 11.9097H23.0463Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
