import { useEffect } from 'react';
import { router } from './routes/Routes';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { darkTheme, theme } from './theme/theme';
import { RootState } from './store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { posthog } from 'posthog-js';
import KeywordPopoversManager from './components/Global/KeywordsPopoverManager';
import { updateCurrentUserInfo } from './services/auth';
import { setUserInfo } from './slices/auth/authSlice';
import { GamepadProvider } from './context/GamepadProvider';
import { initializePushNotifications } from './services/pushNotifications';
import { useSystemTheme } from './hooks/useSystemTheme';

function App() {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const dispatch = useDispatch();
  const systemTheme = useSystemTheme();

  useEffect(() => {
    window.Intercom('shutdown');
    if (currentUser && currentUser.email) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'x6oeppkc',
        source: 'ora',
        name: currentUser.name || '',
        email: currentUser.email,
        user_id: currentUser.id,
        created_at: currentUser.createdAt,
      });

      // Identify user in PostHog
      posthog.identify(currentUser.id, {
        email: currentUser.email,
        name: currentUser.name,
        created_at: currentUser.createdAt,
        role: currentUser.role,
        has_ios_app: currentUser.hasIOSApp,
        has_android_app: currentUser.hasAndroidApp,
      });

      // Start Recording user session for Authenticated Users
      if (
        !currentUser.email.includes('jctest') &&
        !currentUser.email.includes('kbtest') &&
        !currentUser.email.includes('rptest') &&
        !currentUser.email.includes('jacob.caccamo') &&
        !currentUser.email.includes('ryanllewellynphelps') &&
        !currentUser.email.includes('ryanrlphelps') &&
        !currentUser.email.includes('losaltoslandscaping.com') &&
        !currentUser.email.includes('kaycbas') &&
        !currentUser.email.includes('kevin@synaptiq.co') &&
        !currentUser.email.includes('jacob@synaptiq.co') &&
        !currentUser.email.includes('ryan@synaptiq.co') &&
        !currentUser.email.includes('kevin@oraai.com') &&
        !currentUser.email.includes('jacob@oraai.com') &&
        !currentUser.email.includes('ryan@oraai.com') &&
        !currentUser.email.includes('vkbzs8xkvd@privaterelay.appleid.com') // Jake's Apple Account
      ) {
        posthog.startSessionRecording();
      }

      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        initializePushNotifications(currentUser.id);
        if (!currentUser.hasIOSApp) {
          userOnApp('ios');
        }
      } else if (
        Capacitor.isNativePlatform() &&
        Capacitor.getPlatform() === 'android'
      ) {
        if (!currentUser.hasAndroidApp) {
          userOnApp('android');
        }
      }
    } else {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'x6oeppkc',
        source: 'ora',
      });
    }

    // Update body class based on user's dark mode preference
    if (
      (currentUser && currentUser.theme === 'dark') ||
      (currentUser && currentUser.theme === 'system' && systemTheme === 'dark')
    ) {
      document.body.classList.add('theme-dark');
    } else {
      document.body.classList.remove('theme-dark');
    }

    if (currentUser && currentUser.demoMode) {
      document.body.classList.add('DEMO-MODE');
    } else {
      document.body.classList.remove('DEMO-MODE');
    }
  }, [currentUser]);

  // Add classes based on platform
  if (Capacitor.getPlatform() === 'ios') {
    document.body.classList.add('CAPACITOR-IOS');
  }
  if (Capacitor.getPlatform() === 'android') {
    document.body.classList.add('CAPACITOR-ANDROID');
  }
  if (Capacitor.isNativePlatform()) {
    document.body.classList.add('IS-CAPACITOR');
  }

  const userOnApp = async (platformType: 'ios' | 'android') => {
    let updateInfo = {};
    if (platformType === 'ios') {
      updateInfo = { hasIOSApp: true };
    } else {
      updateInfo = { hasAndroidApp: true };
    }
    const updateUser = await updateCurrentUserInfo(updateInfo);
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider
          theme={
            (currentUser && currentUser.theme === 'dark') ||
            (currentUser &&
              currentUser.theme === 'system' &&
              systemTheme === 'dark')
              ? darkTheme
              : theme
          }
        >
          <GamepadProvider>
            <RouterProvider router={router} />
          </GamepadProvider>
          <KeywordPopoversManager />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
