import React, { useEffect, useState } from 'react';
import { RootState } from '../../../store/store';
import { FSRS } from '../../../services/scheduler';
import { updateFlashcardSchedulingBE } from '../../../services/study';
import {
  createFlashcardResponseBE,
  undoFlashcardResponseBE,
} from '../../../services/flashcardResponse';
import {
  shiftDue,
  shiftNew,
  shiftNewAndAddDue,
  shiftDueAndAddDue,
  unshiftNew,
  unshiftDue,
  removeFromDue,
} from '../../../slices/flashcards/flashcardsSlice';
import {
  addPastFlashcard,
  shiftPastFlashcard,
} from '../../../slices/pastFlashcards/pastFlashcardsSlice';
import {
  decrementCompletedFlashcards,
  incrementCompletedFlashcards,
} from '../../../slices/todaySession/todaySessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Flashcard as FlashcardType } from '../../../types/Flashcard';
import { useHotkeys } from 'react-hotkeys-hook';
import Flashcard from './Flashcard';
import moment, { Moment } from 'moment';
import { updateDiagnosticData } from '../../../services/onboarding';
import { setDiagnosticData } from '../../../slices/diagnosticData/diagnosticDataSlice';
import { posthog } from 'posthog-js';
import { PanelType } from '../../../types/Study';
import { CircularProgress, Drawer } from '@mui/material';
import {
  getSuspendedFlashcards,
  unsuspendFlashcard,
} from '../../../services/flashcards';
import { hapticsImpactLight } from '../../../utils/haptics';
import { enqueueSnackbar } from 'notistack';
import parse from 'html-react-parser';
import { X } from 'react-feather';
import { useRefreshStudySessionAndContent } from '../../../utils/refreshStudySession';
import useWindowWidth from '../../../hooks/useWindowWidth';
import FlashcardsFeatureTour from './FlashcardsTour';
import { updateCurrentUserInfo } from '../../../services/auth';
import { setUserInfo } from '../../../slices/auth/authSlice';

interface FlashcardsProps {
  renderToast: (message: string) => void;
  isDiagnostic: boolean;
  panelType: PanelType;
  setPanelType: (panelType: PanelType) => void;
  setIsStartingPhase: (isStartingPhase: boolean) => void;
}

const Flashcards: React.FC<FlashcardsProps> = ({
  renderToast,
  isDiagnostic,
  panelType,
  setPanelType,
  setIsStartingPhase,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] =
    useState<FlashcardType | null>(null);
  const [currentFlashcardType, setCurrentFlashcardType] = useState('none');
  const [keybindsDisabled, setKeybindsDisabled] = useState(false);
  const [newScheduling, setNewScheduling] = useState({});
  const [flashcardMenuIsOpen, setFlashcardMenuIsOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [shortcutMenuIsOpen, setShortcutMenuIsOpen] = useState(false);
  const [showSuspendedCards, setShowSuspendedCards] = useState(false);
  const [isLoadingSuspendedCards, setIsLoadingSuspendedCards] = useState(false);
  const [suspendedCards, setSuspendedCards] = useState([]);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const newFlashcards = useSelector((state: RootState) => state.flashcards.new);
  const dueFlashcards = useSelector((state: RootState) => state.flashcards.due);
  const prevFlashcard = useSelector(
    (state: RootState) => state.pastFlashcards[0]
  );

  // QBank Feature Tour
  const [showFlashcardsTour, setShowFlashcardsTour] = useState(false);
  const [currentFlashcardsTourStepIndex, setCurrentFlashcardsTourStepIndex] =
    useState(0);
  const [isFlashcardsTourRunning, setIsFlashcardsTourRunning] = useState(false);
  const flashcardsStepSelectors = [
    '.button--reveal',
    '.button--reveal',
    '.flashcard_actions',
  ];

  const windowWidth = useWindowWidth();

  const {
    // isLoadingStudySessionAndContent,
    // // isLoadedStudySessionAndContent,
    // setIsLoadingStudySessionAndContent,
    // setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();

  // Onboarding States
  const diagnosticData = useSelector(
    (state: RootState) => state.diagnosticData
  );
  const [flashcardStartTime, setFlashcardStartTime] = useState<Moment | null>(
    null
  );

  const dispatch = useDispatch();

  const fsrs = new FSRS();

  const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  const undoHotkey = isMac ? 'meta+z' : 'ctrl+z';

  useHotkeys('space', () => keybinds('space'), { keyup: true });
  useHotkeys('f', () => keybinds('f'), { keyup: true });
  useHotkeys('escape', () => keybinds('esc'), { keyup: true });
  useHotkeys(undoHotkey, (event) => {
    event.preventDefault();
    keybinds('z', event);
  });
  useHotkeys('z', (event) => {
    if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) {
      return;
    }
    keybinds('z', event);
  });

  const keybinds = (key: string, event?: KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (key === 'esc') {
      setPanelType(PanelType.explanation);
      setKeybindsDisabled(false);
    }
    if (keybindsDisabled) return;
    if (!isFlipped && (key === 'space' || key === 'f')) {
      setIsFlipped(true);
    }
    if (isFlipped && key === 'space') {
      handleResponse(2);
    }
    if (isFlipped && key === 'f') {
      setIsFlipped(false);
    }
    if (key === 'z') {
      undoResponse();
    }
  };

  // Since on Flashcards, Set Start to False (Only Relevant for QBank)
  useEffect(() => {
    setIsStartingPhase(false);
    fetchSuspendedFlashcards();
  }, []);

  useEffect(() => {
    if (!isFlipped) {
      setFlashcardStartTime(moment());
      setNewScheduling({});
    } else {
      if (!currentFlashcard) return;
      const initialScheduling = currentFlashcard.scheduling;
      const now = new Date();
      const schedulingOptions = fsrs.repeat(initialScheduling, now);
      const timeDiffs = ['', '', '', ''];
      for (let i = 0; i < Object.keys(schedulingOptions).length; i++) {
        const schedulingOption = schedulingOptions[i + 1];
        if (schedulingOption) {
          const diff = moment(schedulingOption.card.due).diff(now, 'minutes');
          if (diff < 60) {
            timeDiffs[i] = `${diff}m`;
          } else if (diff < 1440) {
            timeDiffs[i] = `${Math.floor(diff / 60)}h`;
          } else {
            timeDiffs[i] = `${Math.floor(diff / 1440)}d`;
          }
        }
      }

      setNewScheduling({
        0: timeDiffs[0],
        1: timeDiffs[1],
        2: timeDiffs[2],
        3: timeDiffs[3],
      });
    }
  }, [isFlipped]);

  useEffect(() => {
    const [flashcard, type] = calcCurrentFlashcard();
    // @ts-expect-error - expect type error
    setCurrentFlashcard(flashcard);
    setIsFlipped(false);
    // @ts-expect-error - expect type error
    setCurrentFlashcardType(type);
  }, [newFlashcards, dueFlashcards]);

  useEffect(() => {
    if (!currentFlashcard || !currentFlashcard.contents.length) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  }, [currentFlashcard]);

  // Fetch suspended flashcards when the user toggles the suspended cards drawer
  useEffect(() => {
    if (showSuspendedCards) {
      fetchSuspendedFlashcards();
    }
  }, [showSuspendedCards]);

  // Handle Showing QBank Tour
  useEffect(() => {
    let flashcardsTourStartDate = moment.utc('2025-01-13');
    flashcardsTourStartDate = flashcardsTourStartDate.add(3, 'hours');

    // // Home Tour
    if (currentUser?.createdAt) {
      const userCreatedAt = moment.utc(currentUser.createdAt);
      if (userCreatedAt.isAfter(flashcardsTourStartDate)) {
        // Prevent showing tour to existing users
        if (currentUser?.onboardingTips && windowWidth > 900) {
          if (!currentUser.onboardingTips['flashcards-tour']) {
            setTimeout(() => {
              setShowFlashcardsTour(true);
              handleStartTour();
            }, 500);
          }
        }
      }
    }
  }, [currentUser, windowWidth]);

  const handleStartTour = () => {
    setCurrentFlashcardsTourStepIndex(0);
    setIsFlashcardsTourRunning(true);
  };

  const handleTourEnd = () => {
    setIsFlashcardsTourRunning(false);
    setCurrentFlashcardsTourStepIndex(0);
  };

  const handleStepChange = (newIndex: number) => {
    setCurrentFlashcardsTourStepIndex(newIndex);
  };

  const updateDiagnosticFlashcardResponses = async (response: number) => {
    if (
      !flashcardStartTime ||
      !currentFlashcard ||
      !diagnosticData.flashcardResponses
    )
      return;
    const now = moment();
    // push a new response to the flashcardResponses array
    const newResponse = {
      id: currentFlashcard.id,
      response: response,
      startTime: flashcardStartTime.toDate(),
      endTime: now.toDate(),
    };
    const updatedResponses = [
      ...diagnosticData.flashcardResponses,
      newResponse,
    ];
    const updatedData = {
      flashcardResponses: updatedResponses,
    };
    const data = await updateDiagnosticData(updatedData);
    if (data) {
      const updateDiagnosticData = {
        flashcardResponses: data.flashcardResponses,
        vignetteResponses: data.vignetteResponses,
      };
      dispatch(setDiagnosticData(updateDiagnosticData));
    }
  };

  const handleResponse = async (response: number) => {
    if (!currentFlashcard) return;

    // if (isDiagnostic && flashcardFrame === 1 && response === 2) {
    //   setFlashcardResponseCorrect(true);
    // }
    if (isDiagnostic) {
      updateDiagnosticFlashcardResponses(response);
    }

    const pastFlashcardInfo = {
      flashcard: currentFlashcard,
      type: currentFlashcardType,
    };
    // Save the current flashcard info to the past flashcards queue
    dispatch(addPastFlashcard(pastFlashcardInfo));

    const now = new Date();
    const initialScheduling = currentFlashcard.scheduling;

    const newDifficultyIndex = calcNewDifficultyIndex(response);

    // Calculate new scheduling
    const schedulingOptions = fsrs.repeat(initialScheduling, now);
    // Grab the appropriate scheduling option based on the user's response
    const newScheduling = Object.values(schedulingOptions)[response].card;
    newScheduling.difficultyIndex = newDifficultyIndex;
    newScheduling.sessionId = todaySession.id;
    const flashcardId = currentFlashcard.id;

    // Update flashcard scheduling in the backend
    updateFlashcardSchedulingBE(flashcardId, newScheduling);

    // Log the flashcard response in the backend
    createFlashcardResponseBE(flashcardId, response, todaySession.id);

    // If the new scheduling is due today or before, add it to the due queue
    if (isTodayOrBefore(newScheduling.due)) {
      // Convert due and lastReview to ISO strings if they are not already
      if (typeof newScheduling.due !== 'string') {
        newScheduling.due = newScheduling.due.toISOString();
      }
      if (typeof newScheduling.lastReview !== 'string') {
        newScheduling.lastReview = newScheduling.lastReview.toISOString();
      }
      // clone currentFlashcard and update its scheduling
      const updatedFlashcard = {
        ...currentFlashcard,
        scheduling: newScheduling,
      };

      // Shift the flashcard off the appropriate queue and add it to the due queue
      if (currentFlashcardType === 'new') {
        dispatch(shiftNewAndAddDue(updatedFlashcard));
      } else {
        dispatch(shiftDueAndAddDue(updatedFlashcard));
      }
    } else {
      // Shift the flashcard off the appropriate queue
      currentFlashcardType === 'new'
        ? dispatch(shiftNew())
        : dispatch(shiftDue());
      dispatch(incrementCompletedFlashcards());
    }

    renderRatingFeedback(response);
    posthog?.capture('flashcard_respond');
  };

  const deleteFlashcardDiagnosticReponse = async (flashcardId: string) => {
    const updatedResponses = diagnosticData.flashcardResponses.filter(
      (response) => response.id !== flashcardId
    );
    const updatedData = {
      flashcardResponses: updatedResponses,
    };
    const data = await updateDiagnosticData(updatedData);
    if (data) {
      const updateDiagnosticData = {
        flashcardResponses: data.flashcardResponses,
        vignetteResponses: data.vignetteResponses,
      };
      dispatch(setDiagnosticData(updateDiagnosticData));
    }
  };

  const undoResponse = () => {
    if (!prevFlashcard) {
      renderToast('Maximum number of undos reached.');
      return;
    }
    // Remove prev flashcard from due queue, if present
    const prevFlashcardId = prevFlashcard.flashcard.id;
    const prevFlashcardWasInDue = dueFlashcards.find(
      (flashcard) => flashcard.id === prevFlashcardId
    );
    if (prevFlashcardWasInDue) dispatch(removeFromDue(prevFlashcardId));

    posthog?.capture('flashcard_undo_respond');

    // Add prev flashcard back to the appropriate queue
    if (prevFlashcard.type === 'new') {
      dispatch(unshiftNew(prevFlashcard.flashcard));
    } else {
      dispatch(unshiftDue(prevFlashcard.flashcard));
    }

    // Only decrement completed flashcards if the prev flashcard was not in the due queue
    const shouldDecrementCompleted = prevFlashcardWasInDue ? false : true;
    undoFlashcardResponseBE(
      prevFlashcard.flashcard.id,
      todaySession.id,
      shouldDecrementCompleted
    );
    if (shouldDecrementCompleted) dispatch(decrementCompletedFlashcards());

    dispatch(shiftPastFlashcard());
    if (isDiagnostic) {
      deleteFlashcardDiagnosticReponse(prevFlashcard.flashcard.id);
    }
    renderToast('Response undone.');
    setIsFlipped(false);
  };

  const calcNewDifficultyIndex = (response: number) => {
    if (!currentFlashcard) return 0;
    const numFlashcardVariants = currentFlashcard.contents.length;
    const initialDifficultyIndex = currentFlashcard.scheduling.difficultyIndex;
    if (response === 0 || response === 1) {
      return Math.max(initialDifficultyIndex - 1, 0);
    } else {
      return Math.min(initialDifficultyIndex + 1, numFlashcardVariants - 1);
    }
  };

  const calcCurrentFlashcard = () => {
    // If there are no due or new flashcards, return null
    if (dueFlashcards.length === 0 && newFlashcards.length === 0) {
      return [null, 'none'];
      // If there is a due flashcard that was studied today and is now due again, return it
    } else if (
      dueFlashcards.length > 0 &&
      dueFlashcards[0].scheduling.elapsedDays === 0 &&
      isDueNow(dueFlashcards[0].scheduling.due)
    ) {
      return [dueFlashcards[0], 'due'];
      // If there is a due flashcard that was studied on a previous day, return it
    } else if (
      dueFlashcards.length > 0 &&
      dueFlashcards[0].scheduling.elapsedDays > 0
    ) {
      return [dueFlashcards[0], 'due'];
      // If there are new flashcards, return the first one
    } else if (newFlashcards.length > 0) {
      return [newFlashcards[0], 'new'];
      // If there are due flashcards, return the first one
    } else if (dueFlashcards.length > 0) {
      return [dueFlashcards[0], 'due'];
    } else {
      return [null, 'none'];
    }
  };

  const isTodayOrBefore = (date: Date | string) => {
    return moment(date).isSameOrBefore(moment(), 'day');
  };

  const isDueNow = (dueDate: Date | string): boolean => {
    // Convert both current time and dueDate to moment objects for comparison
    const now = moment();
    const due = moment(dueDate);
    return now.isSameOrAfter(due);
  };

  const renderRatingFeedback = (response: number) => {
    const feedbackTarget = document.querySelector('.flashcard_actions');
    const childDiv = document.createElement('div');
    childDiv.classList.add('rating-feedback');
    childDiv.classList.add(
      response === 2 || response === 3 ? 'right' : 'wrong'
    );
    const feedbackText = newScheduling[
      response as keyof typeof newScheduling
    ] as string;
    if (!feedbackText) return;
    const feedbackTextFormatted = feedbackText.replace('m', 'min');
    childDiv.textContent = feedbackTextFormatted;
    feedbackTarget?.appendChild(childDiv);
    setTimeout(() => {
      childDiv.classList.add('show');
    });
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    delay(500)
      .then(() => {
        childDiv.classList.add('hide');
        return new Promise((resolve) => setTimeout(resolve, 250));
      })
      .then(() => {
        feedbackTarget?.removeChild(childDiv);
      });
  };

  const fetchSuspendedFlashcards = async () => {
    setIsLoadingSuspendedCards(true);
    const response = await getSuspendedFlashcards();
    if (response) {
      setSuspendedCards(response);
    }
    setIsLoadingSuspendedCards(false);
  };

  const handleUnsuspendFlashcard = async (flashcardId: string) => {
    await unsuspendFlashcard(flashcardId);
    await refreshStudySessionAndContent();
    fetchSuspendedFlashcards();
    enqueueSnackbar('Flashcard unsuspended.', {
      autoHideDuration: 3000,
    });
  };

  const handleSeenFlashcardsTour = async () => {
    if (!currentUser) return;
    const updateUser = await updateCurrentUserInfo({
      onboardingTips: {
        ...currentUser?.onboardingTips,
        'flashcards-tour': true,
      },
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <>
      {!showErrorMessage && (
        <>
          <Flashcard
            flashcard={currentFlashcard}
            isFlipped={isFlipped}
            flipCard={setIsFlipped}
            handleResponse={handleResponse}
            newScheduling={newScheduling}
            flashcardMenuIsOpen={flashcardMenuIsOpen}
            setFlashcardMenuIsOpen={setFlashcardMenuIsOpen}
            prevFlashcard={!!prevFlashcard}
            undoResponse={undoResponse}
            shortcutMenuIsOpen={shortcutMenuIsOpen}
            setShortcutMenuIsOpen={setShortcutMenuIsOpen}
            keybindsDisabled={keybindsDisabled}
            setKeybindsDisabled={setKeybindsDisabled}
            isDiagnostic={isDiagnostic}
            panelType={panelType}
            setPanelType={setPanelType}
            fetchSuspendedFlashcards={fetchSuspendedFlashcards}
            setShowSuspendedCards={setShowSuspendedCards}
            suspendedCardCount={suspendedCards.length}
          />
          <Drawer
            className="suspended-cards-drawer"
            anchor="right"
            open={showSuspendedCards}
            onClose={() => setShowSuspendedCards(false)}
          >
            <div className="drawer-container">
              <div className="drawer-container_header">
                <h2>Suspended Cards</h2>
                <button
                  onClick={() => setShowSuspendedCards(false)}
                  className="button button--close"
                >
                  <X />
                </button>
              </div>
              {!isLoadingSuspendedCards && (
                <div className="flashcard-container">
                  {suspendedCards.map((card: FlashcardType, index: number) => (
                    <div key={index} className="suspended-card">
                      <div className="flashcard">
                        <div>{parse(card.contents[0].front)}</div>
                        <button
                          onClick={() => {
                            hapticsImpactLight();
                            handleUnsuspendFlashcard(card.id);
                          }}
                          className="button button--unsuspend"
                        >
                          Unsuspend
                        </button>
                      </div>
                    </div>
                  ))}
                  {suspendedCards.length === 0 && (
                    <div className="no-remaining">
                      No remaining suspended flashcards.
                    </div>
                  )}
                </div>
              )}
              {isLoadingSuspendedCards && (
                <div className="loader-wrapper">
                  <CircularProgress
                    sx={{
                      display: 'block',
                      margin: '1.5rem 0 0 0',
                      height: '1rem !important',
                      width: '1rem !important',
                    }}
                  />
                </div>
              )}
            </div>
          </Drawer>
          {showFlashcardsTour && (
            <FlashcardsFeatureTour
              stepTargets={[...flashcardsStepSelectors]}
              stepFunctions={[() => {}, () => {}, () => {}]}
              run={isFlashcardsTourRunning}
              stepIndex={currentFlashcardsTourStepIndex}
              onStepChange={(newIndex) => handleStepChange(newIndex)}
              onTourEnd={handleTourEnd}
              handleSeenFlashcardsTour={handleSeenFlashcardsTour}
              isFlipped={isFlipped}
            />
          )}
        </>
      )}
      {showErrorMessage && (
        <div className="flashcard-error">
          <div>
            <h2>Error retrieving flashcard content.</h2>
            <button
              className="button button--glass"
              onClick={() => keybinds('space')}
            >
              Continue
            </button>
            <div className="or">or</div>
            <p>Press Space to continue.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Flashcards;
