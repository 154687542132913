export const XboxLS: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      y="9.41162"
      width="32"
      height="15.0588"
      rx="7.52941"
      fill="var(--gamepad-button-background)"
    />
    <rect
      x="8.4707"
      y="24.4707"
      width="15.0588"
      height="3.76471"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M20.1885 22.7646H13.5166V11.5615H16.04V20.7178H20.1885V22.7646Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M16.0001 9.41162L19.2604 5.17633H12.7397L16.0001 9.41162Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
