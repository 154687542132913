import React, { useEffect, useMemo, useRef, useState } from 'react';
import ExamHeader from './ExamHeader';
import Footer from './Footer';
import LabValues from './LabValues';
import StartScreen from './StartScreen';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import ReactHtmlParser from 'html-react-parser';
import Calculator from './Calculator';
import { Vignette } from '../../../types/Vignette';
import { Block, BlockType, PanelType, StudyPhase } from '../../../types/Study';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBlockResponse,
  submitBlock,
  updateBlockBE,
  updateBlockCrossouts,
  updateBlockNotes,
} from '../../../services/blocks';
import { updateBlockFE } from '../../../slices/blocks/blocksSlice';
import {
  AlertTriangle,
  Check,
  Copy,
  MessageSquare,
  Repeat,
  Type,
  X,
} from 'react-feather';
import StandardFlag from '../../../assets/images/standard/flag.svg';
import FlagDark from '../../../assets/images/standard/flag-dark.svg';
import Pencil from '../../../assets/images/standard/pencil.svg';
import NotesIcon from '../../../assets/images/standard/notes.svg';
import CalculatorIcon from '../../../assets/images/standard/calculator.svg';
import { useNavigate } from 'react-router-dom';
import { updateStudySession } from '../../../services/studySession';
import { setTodaySession } from '../../../slices/todaySession/todaySessionSlice';
import { useSnackbar } from 'notistack';
import Notes from './Notes';
import { hapticsImpactLight } from '../../../utils/haptics';
import { Modal, Popover } from '@mui/material';
import _ from 'lodash';
import { setDiagnosticData } from '../../../slices/diagnosticData/diagnosticDataSlice';
import { updateDiagnosticData } from '../../../services/onboarding';
import { Moment } from 'moment';
import moment from 'moment';
import { Topic } from '../../../types/Dashboard';
import ImagePopover from './ImagePopover';
import CustomImage from './CustomImage';
import {
  fetchLinkedFlashcards,
  submitTutorVignette,
} from '../../../services/vignettes';
import { Flashcard } from '../../../types/Flashcard';
import { setVignettes } from '../../../slices/vignettes/vignettesSlice';
import useHighlighting from './Highlighting/highlighting';
import { posthog } from 'posthog-js';
// import EditVignette from './EditVignette';
import { useHotkeys } from 'react-hotkeys-hook';
import StudyPanel from '../StudyPanel';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Drag } from '../../../assets/svgs/Drag';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import NonAuthFeatureTour from '../NonAuthFeatureTour';
import Cookies from 'js-cookie';
import { updateCurrentUserInfo } from '../../../services/auth';
import { setUserInfo } from '../../../slices/auth/authSlice';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { useRefreshStudySessionAndContent } from '../../../utils/refreshStudySession';
import useWindowHeight from '../../../hooks/useWindowHeight';
import { createVignetteResponseBE } from '../../../services/vignetteResponse';
import { useSystemTheme } from '../../../hooks/useSystemTheme';
import QBankFeatureTour from './QBankFeatureTour';
import ImageDisplay from '../../Misc/ImageDisplay';

interface ExamProps {
  activeBlockId: string;
  currentBlockNumber: number;
  nextBlock?: () => void;
  changePhase?: (phase: StudyPhase, options?: object) => void;
  vignettes: Vignette[];
  isStart: boolean;
  setIsStart?: (isStart: boolean) => void;
  isDiagnostic: boolean;
  fadeInOutFlashLoader: () => void;
  fadeInFlashLoader: () => void;
  fadeOutFlashLoader: () => void;
  reviewQbankBlock?: (blockId: string) => void;
  panelType: PanelType;
  setPanelType: (panelType: PanelType) => void;
  isDemo?: boolean;
  isStartingPhase: boolean;
  setIsStartingPhase: (isStartingPhase: boolean) => void;
}

const Exam: React.FC<ExamProps> = ({
  activeBlockId,
  currentBlockNumber,
  nextBlock,
  changePhase,
  vignettes,
  isStart,
  setIsStart,
  isDiagnostic,
  fadeInOutFlashLoader,
  fadeInFlashLoader,
  fadeOutFlashLoader,
  reviewQbankBlock,
  panelType,
  setPanelType,
  isDemo,
  isStartingPhase,
  setIsStartingPhase,
}) => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const diagnosticData = useSelector(
    (state: RootState) => state.diagnosticData
  );
  const activeBlock = useSelector(
    (state: RootState) => state.blocks.find((b) => b.id === activeBlockId)!
  );
  const primaryExam = useSelector((state: RootState) =>
    state.auth.userInfo?.exams.find((b) => b.primary === true)
  );
  const activeBlockRef = useRef(activeBlock);
  const answerCrossouts = activeBlock?.answerCrossouts;
  const contextMenuAnchor = useRef<HTMLButtonElement>(null);
  const blockCount = useSelector((state: RootState) => state.blocks.length);
  const [reportCard, setReportCard] = useState(false);
  const [theme, setTheme] = useState('default');
  const [showLabValues, setShowLabValues] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [linkedFlashcards, setLinkedFlashcards] = useState<Flashcard[]>([]);
  const [functionMenuIsOpen, setFunctionMenuIsOpen] = useState(false);
  const [confirmEndIsOpen, setConfirmEndIsOpen] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [activeBlockType, setActiveBlockType] = useState(activeBlock?.type);
  const [qBankDemoQuestionCorrect, setQBankDemoQuestionCorrect] = useState<
    boolean | null
  >(null);
  const [activeVignette, setActiveVignette] = useState(
    vignettes[activeQuestionIndex]
  );
  const [activeVignetteVariant, setActiveVignetteVariant] = useState(
    activeVignette?.contents[0]
  );
  const [vignetteMenuIsOpen, setVignetteMenuIsOpen] = useState(false);
  const [questionsMenuIsOpen, setQuestionsMenuIsOpen] = useState(false);
  // const [isEditing, setIsEditing] = useState(false);
  const [keybindsDisabled, setKeybindsDisabled] = useState(false);
  const [vignetteChangeCount, setVignetteChangeCount] = useState(0);
  const [vignetteNotes, setVignetteNotes] = useState('');
  const [reportValue, setReportValue] = useState('');
  const [parsedExhibit, setParsedExhibit] = useState<React.ReactNode[]>([]);
  const [parsedExplanation, setParsedExplanation] = useState<React.ReactNode[]>(
    []
  );
  const [vignetteStartTime, setVignetteStartTime] = useState<Moment | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();

  const [timeRemaining, setTimeRemaining] = useState(
    activeBlock?.duration || 0
  );
  let timer: ReturnType<typeof setInterval>;
  const timeRemainingRef = useRef(timeRemaining);
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const rightColumnRef = useRef<HTMLDivElement>(null);
  const labValuesRef = useRef<HTMLDivElement>(null);
  // const prevVignetteRef = useRef<Vignette | null>(null);

  // Custom highlighting hook
  const { highlightTarget } = useHighlighting(
    isStart,
    activeBlock,
    activeVignetteVariant,
    todaySession
  );

  // QBank Feature Tour
  const [showQBankTour, setShowQBankTour] = useState(false);
  const [currentQBankTourStepIndex, setCurrentQBankTourStepIndex] = useState(0);
  const [isQBankTourRunning, setIsQBankTourRunning] = useState(false);
  const qbankStepSelectors = [
    '#left-panel',
    '#left-panel',
    '.study-panel .button--flashcards',
  ];

  // Non Auth Feature Tour
  const [createAccountModalIsOpen, setCreateAccountModalIsOpen] =
    useState(false);
  const [allDemoQuestionsSubmitted, setAllDemoQuestionsSubmitted] =
    useState(false);
  const [currentNonAuthStepIndex, setCurrentNonAuthStepIndex] = useState(0);
  const [isNonAuthTourRunning, setIsNonAuthTourRunning] = useState(false);
  const tourNonAuthStep1Ref = highlightTarget;
  const tourNonAuthStep2Ref = useRef<HTMLDivElement>(null);
  const stepRefs = [tourNonAuthStep1Ref, tourNonAuthStep2Ref];
  const nonAuthStepSelectors = ['.button--flashcards'];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const systemTheme = useSystemTheme();

  const { refreshFlashcards } = useRefreshStudySessionAndContent();

  useHotkeys('comma', () => keybinds('comma'), { keyup: true });
  useHotkeys('period', () => keybinds('period'), { keyup: true });
  useHotkeys('r', () => keybinds('r'), { keyup: true });
  useHotkeys('l', () => keybinds('l'), { keyup: true });
  useHotkeys('f', () => keybinds('f'), { keyup: true });
  useHotkeys('e', () => keybinds('e'), { keyup: true });
  useHotkeys('escape', () => keybinds('esc'), { keyup: true });

  const keybinds = (key: string) => {
    if (key === 'esc') {
      setKeybindsDisabled(false);
      setPanelType(PanelType.explanation);
    }
    if (keybindsDisabled) return;
    if (key === 'r') {
      setPanelType(PanelType.report);
    }
    // if (key === 'comma' && currentUser?.role === 'admin') {
    //   setIsEditing(true);
    // }
    if (key === 'l') {
      setPanelType(PanelType.library);
    }
    if (key === 'f') {
      if (currentUser && !primaryExam?.isUsingFlashcards) return;
      setPanelType(PanelType.flashcards);
    }
    if (key === 'e') {
      setPanelType(PanelType.explanation);
    }
  };

  useEffect(() => {
    // Protects against leaving exam page open overnight
    if (activeBlock === null || activeBlock === undefined) {
      if (todaySession?.vignetteCount === 0) {
        if (changePhase) changePhase(StudyPhase.flashcard);
      } else {
        navigate('/');
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('resize', handlePanelResize);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('resize', handlePanelResize);
      document.body.classList.remove('in-exam');
      document.body.classList.remove('exam--standard');
      document.body.classList.remove('exam--timed');
      document.body.classList.remove('exam--tutor');
      document.body.classList.remove('dark');
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        document.body.classList.add('theme-dark');
      }
      if (activeBlockRef.current) {
        cleanupExam(activeBlockRef.current);
      }
    };
  }, []);

  const handleBeforeUnload = async () => {
    console.log('The page is being unloaded or refreshed.');

    // Update the backend with the current block duration
    if (activeBlockRef.current && timeRemainingRef) {
      const updatedBlock = {
        ...activeBlockRef.current,
        duration: timeRemainingRef.current,
      };
      dispatch(updateBlockFE(updatedBlock));
    }
  };

  useEffect(() => {
    setActiveVignette(vignettes[activeQuestionIndex]);
    setNotes();
  }, [activeQuestionIndex, vignettes, vignetteChangeCount]);

  // Handle Showing QBank Tour
  useEffect(() => {
    let qbankTourStartDate = moment.utc('2025-01-13');
    qbankTourStartDate = qbankTourStartDate.add(3, 'hours');

    // Exam Tour
    if (currentUser?.createdAt) {
      const userCreatedAt = moment.utc(currentUser.createdAt);
      if (userCreatedAt.isAfter(qbankTourStartDate)) {
        // Prevent showing tour to existing users
        if (currentUser?.onboardingTips && windowWidth > 900) {
          if (!currentUser.onboardingTips['qbank-tour']) {
            setTimeout(() => {
              setShowQBankTour(true);
              handleStartTour('qbank');
            }, 1750);
          }
        }
      }
    }
  }, [currentUser, windowWidth]);

  // Start Feature Tour if Non Auth Demo
  useEffect(() => {
    if (isDemo) {
      handleStartTour('non-auth');
    }
  }, [isStart, isDemo]);

  // Non Auth Tour Step Management
  useEffect(() => {
    if (currentNonAuthStepIndex === 1) {
      handlePauseTour('non-auth');
    }
    if (currentNonAuthStepIndex === 2) {
      const linkedFlashcards = document.querySelector(
        '.button--flashcards'
      ) as HTMLButtonElement;
      if (linkedFlashcards) {
        linkedFlashcards.click();
      }
    }
  }, [currentNonAuthStepIndex]);

  useEffect(() => {
    if (!isStart) {
      document.body.classList.add('in-exam');
      setVignetteStartTime(moment());
    } else {
      document.body.classList.remove('in-exam');
    }
  }, [isStart]);

  // Update Mobile Lab Values Padding
  useEffect(() => {
    if (showLabValues && windowWidth < 900) {
      const labValuesHeight = labValuesRef.current?.clientHeight;
      if (shouldRenderAnswerAndExplanation()) {
        if (rightColumnRef.current) {
          rightColumnRef.current.style.paddingBottom = `${labValuesHeight}px`;
        }
      } else {
        if (leftColumnRef.current) {
          leftColumnRef.current.style.paddingBottom = `${labValuesHeight}px`;
        }
      }
    } else {
      if (leftColumnRef.current) {
        leftColumnRef.current.style.paddingBottom = '1.5rem';
      }
      if (rightColumnRef.current) {
        rightColumnRef.current.style.paddingBottom = '0';
      }
    }
  }, [
    leftColumnRef,
    rightColumnRef,
    windowWidth,
    windowHeight,
    showLabValues,
    activeBlock,
    activeVignette,
  ]);

  // Fade Out Flash Loader on QBank Review
  useEffect(() => {
    if (!isStart && todaySession.phase === StudyPhase.qbankReview) {
      setTimeout(() => {
        fadeOutFlashLoader();
      }, 250);
    }
  }, [isStart, todaySession]);

  // Auto QBank Start if Coming from Home
  useEffect(() => {
    if (activeBlock && isStart && isStartingPhase) {
      setIsStartingPhase(false);
      if (!activeBlock.isStarted) {
        if (currentUser?.preferredBlockType === 'tutor') {
          startBlock(BlockType.tutor);
        }
        if (currentUser?.preferredBlockType === 'timed') {
          startBlock(BlockType.timed);
        }
      }
      if (activeBlock.isStarted) {
        resumeBlock();
      }
    }
  }, [activeBlock, isStart, currentUser, isStartingPhase]);

  useEffect(() => {
    if (activeBlock && isStart) {
      if (activeBlockType === BlockType.tutor) {
        const answeredQuestions = Object.keys(activeBlock.answers).length;
        setActiveQuestionIndex(answeredQuestions);
      }
      if (activeBlock.isSubmitted) {
        setActiveQuestionIndex(0);
      }
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        document.body.classList.add('theme-dark');
      }
    }
    if (!isStart) {
      document.body.classList.remove('theme-dark');
    }
  }, [activeBlock, isStart, currentUser]);

  useEffect(() => {
    if (isStart) {
      document.body.classList.remove('exam--standard');
      document.body.classList.remove('exam--timed');
      document.body.classList.remove('exam--tutor');
    } else {
      document.body.classList.add('exam--standard');
      if (activeBlockType === BlockType.timed) {
        document.body.classList.add('exam--timed');
      }
      if (activeBlockType === BlockType.tutor) {
        document.body.classList.add('exam--tutor');
      }
      // Don't update the block time if in review mode
      if (todaySession.phase !== StudyPhase.qbankReview) {
        if (activeBlockType === BlockType.timed) {
          timer = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime - 1);
          }, 1000);
        } else {
          timer = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime + 1);
          }, 1000);
        }
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [activeBlockType, isStart, todaySession.phase]);

  // If the block type is changed, reset the timer
  useEffect(() => {
    if (todaySession.phase !== StudyPhase.qbankReview) {
      if (activeBlockType !== activeBlock?.type) {
        setTimeRemaining(activeBlock?.duration);
        clearInterval(timer);
        if (activeBlockType === BlockType.timed) {
          timer = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime - 1);
          }, 1000);
        } else {
          timer = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime + 1);
          }, 1000);
        }
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [activeBlockType, activeBlock?.type, todaySession.phase]);

  // Update Active Block Ref and Type
  useEffect(() => {
    activeBlockRef.current = activeBlock;
    if (activeBlock) {
      setActiveBlockType(activeBlock.type);
    }
  }, [activeBlock]);

  // Tutor Mode Only: End Block if All Questions Submitted
  useEffect(() => {
    if (!activeBlock) return;
    if (activeBlock.type === BlockType.tutor && !activeBlock.isSubmitted) {
      if (
        Object.keys(activeBlock.answers).length === activeBlock.vignetteCount &&
        Object.values(activeBlock.answers).every((answer) => answer.submitted)
      ) {
        endBlock();
      }
    }
  }, [activeBlock?.answers]); // Only run when answers change

  // Demo Mode Only: Show Create Account Modal After 3 Questions
  useEffect(() => {
    if (!activeBlock || !isDemo) return;
    if (
      isDemo &&
      Object.keys(activeBlock.answers).length === 3 &&
      Object.values(activeBlock.answers).every((answer) => answer.submitted)
    ) {
      setCreateAccountModalIsOpen(true);
      setAllDemoQuestionsSubmitted(true);
    }
  }, [activeBlock?.answers, isDemo]);

  // If Account Creation Modal is Open, Close the Feature Tour
  useEffect(() => {
    if (createAccountModalIsOpen) {
      handleTourEnd('non-auth');
    }
  }, [createAccountModalIsOpen]);

  // Update Time Remaining
  useEffect(() => {
    activeBlockRef.current = activeBlock;
    setTimeRemaining(activeBlock?.duration);
  }, [activeBlock?.id]);

  // Bypass Start Screen in Reviewing QBank
  useEffect(() => {
    if (
      todaySession.phase === StudyPhase.qbankReview ||
      todaySession.isReview
    ) {
      if (setIsStart) setIsStart(false);
    }
  }, [todaySession]);

  // Render the Vignette, Set Vignette Notes, Set Vignette Exhibit. Add Event Listeners for Crossouts if Not Submitted
  useEffect(() => {
    setNotes();
    setParsedExhibit([]);
    setParsedExplanation([]);
    if (activeBlock && !isDemo) {
      if (activeVignette && activeVignette.scheduling) {
        if (activeVignetteVariant) {
          if (activeVignetteVariant.exhibit) {
            parseExhibit(activeVignetteVariant.exhibit);
          }
          if (activeVignetteVariant.explanation) {
            parseExplanation(activeVignetteVariant.explanation);
          }
        }
        const scheduling = activeVignette.scheduling;
        // Show review variant if submitted or in tutor review mode
        if (
          activeBlock.isSubmitted ||
          (activeBlock.type === BlockType.tutor &&
            questionIsSubmitted(activeVignette.id))
        ) {
          setActiveVignetteVariant(
            activeVignette.contents[scheduling.reviewVariantIndex]
          );
          document.removeEventListener('mouseup', handleCrossouts);
          document.removeEventListener('contextmenu', handleRightClickCrossout);
        } else {
          setActiveVignetteVariant(
            activeVignette.contents[scheduling.variantIndex]
          );
          document.addEventListener('mouseup', handleCrossouts);
          document.addEventListener('contextmenu', handleRightClickCrossout);
        }
      }
    } else if (activeBlock && isDemo) {
      setActiveVignetteVariant(activeVignette?.contents[0]);

      if (activeVignetteVariant && activeVignetteVariant.exhibit) {
        parseExhibit(activeVignetteVariant.exhibit);
      }
      if (activeVignetteVariant.explanation) {
        parseExplanation(activeVignetteVariant.explanation);
      }
      document.addEventListener('mouseup', handleCrossouts);
      document.addEventListener('contextmenu', handleRightClickCrossout);
    }
    return () => {
      document.removeEventListener('mouseup', handleCrossouts);
      document.removeEventListener('contextmenu', handleRightClickCrossout);
    };
  }, [activeBlock, activeVignette, activeVignetteVariant, answerCrossouts]);

  // Timed Mode: End Block if Time Remaining is 0
  useEffect(() => {
    timeRemainingRef.current = timeRemaining;
    if (
      !activeBlock?.isSubmitted &&
      !isStart &&
      activeBlockType === BlockType.timed &&
      timeRemaining <= 0 &&
      todaySession.phase === StudyPhase.qbank
    ) {
      console.log('No Time Remaining, End Block');
      enqueueSnackbar('The block time has ended.', { autoHideDuration: 5000 });
      endBlock();
    }
  }, [timeRemaining, isStart, todaySession, activeBlock]);

  // Initialize Diagnostic Data
  useEffect(() => {
    if (
      isDiagnostic &&
      diagnosticData &&
      _.isEmpty(diagnosticData.vignetteResponses) &&
      vignettes &&
      vignettes.length > 0
    ) {
      initializeQBankDiagnosticData(vignettes);
    }
  }, [diagnosticData, vignettes]);

  // On Change of Active Vignette: Reset Scroll Position, Add Response Time to Diagnostic Data
  useEffect(() => {
    // Reset Scroll Positions
    const contentContainer = document.querySelector('.content-area_main');
    const panelGroup = document.getElementById('panel-group');
    const leftPanel = document.getElementById('left-panel');
    const rightPanel = document.getElementById('right-panel');
    setReportValue('');
    if (contentContainer) {
      contentContainer.scrollTop = 0;
    }
    if (panelGroup) {
      panelGroup.scrollTop = 0;
    }
    if (leftPanel) {
      leftPanel.scrollTop = 0;
    }
    if (rightPanel) {
      rightPanel.scrollTop = 0;
    }
    // Add Response Time to Diagnostic Data
    // if (!isDiagnostic) return;
    // if (vignetteStartTime && prevVignetteRef.current) {
    //   addVignetteResponseTime(vignetteStartTime, prevVignetteRef.current.id);
    // }
    // prevVignetteRef.current = activeVignette;
    // setVignetteStartTime(moment());
  }, [activeVignette?.id]);

  // On Change of Active Vignette, Start, or Block: Fetch Linked Flashcards
  useEffect(() => {
    if (!isStart) {
      if (activeBlock.type === BlockType.tutor || activeBlock.isSubmitted) {
        if (activeVignette && (primaryExam?.isUsingFlashcards || isDemo)) {
          getLinkedFlashcards(activeVignette.id);
        }
      }
    }
  }, [activeVignette?.id, isStart, activeBlock]);

  // Update Panel on Load
  useEffect(() => {
    handlePanelResize();
  }, [rightColumnRef.current]);

  const initializeQBankDiagnosticData = async (vignettes: Vignette[]) => {
    if (!vignettes) return;

    const vignetteResponses: {
      [key: string]: { time: number; topics: Topic[] };
    } = {};

    vignettes.forEach((v) => {
      vignetteResponses[v.id] = {
        time: 0,
        topics: v.topics ? v.topics : [],
      };
    });

    const diagnosticData = {
      vignetteResponses: vignetteResponses,
    };

    const data = await updateDiagnosticData(diagnosticData);
    if (data) {
      const updateDiagnosticData = {
        flashcardResponses: data.flashcardResponses,
        vignetteResponses: data.vignetteResponses,
      };
      dispatch(setDiagnosticData(updateDiagnosticData));
    }
  };

  const addVignetteResponseTime = async (
    startTime: Moment | null,
    vignetteId: string
  ) => {
    if (!isDiagnostic || !startTime) return;
    const vignetteResponses = diagnosticData.vignetteResponses;
    const endTime = moment();
    const newTime = endTime.diff(startTime, 'seconds');
    const currentTime = vignetteResponses[vignetteId]?.time || 0;
    const updatedTime = currentTime + newTime;
    const updatedVignetteResponses = {
      ...vignetteResponses,
      [vignetteId]: {
        ...vignetteResponses[vignetteId],
        time: updatedTime,
      },
    };
    const updatedDiagnosticData = {
      ...diagnosticData,
      vignetteResponses: updatedVignetteResponses,
    };
    const data = await updateDiagnosticData(updatedDiagnosticData);
    if (data) {
      dispatch(setDiagnosticData(data));
    }
  };

  const setNotes = () => {
    if (activeBlock?.notes[activeVignette?.id]) {
      setVignetteNotes(activeBlock.notes[activeVignette?.id]);
    } else {
      setVignetteNotes('');
    }
  };

  const handleCrossouts = async () => {
    const selection = window.getSelection();
    if (selection?.toString().trim().length === 0) return;
    if (!selection || !selection.rangeCount || !selection.anchorNode) return;
    const selectedRange = selection.getRangeAt(0);
    let startNode: Node | null = selection.anchorNode;
    if (startNode.nodeType !== Node.ELEMENT_NODE) {
      startNode = startNode.parentElement;
    }
    while (startNode && startNode.nodeName === 'SPAN') {
      startNode = startNode.parentElement;
    }
    if (!startNode) return;

    const labels: Element[] = [];
    if (startNode.nodeName === 'LABEL') {
      const siblingsWalker = document.createTreeWalker(
        startNode.parentNode!,
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode: (node: Node) => {
            if (
              node instanceof Element &&
              node !== startNode &&
              node.tagName === 'LABEL'
            ) {
              const range = document.createRange();
              range.selectNodeContents(node);
              const intersects =
                selectedRange.compareBoundaryPoints(Range.END_TO_START, range) <
                  0 &&
                selectedRange.compareBoundaryPoints(Range.START_TO_END, range) >
                  0;
              range.detach();
              return intersects
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP;
            }
            return NodeFilter.FILTER_SKIP;
          },
        }
      );
      // Add the startNode if it's selected
      labels.push(startNode as Element);
      while (siblingsWalker.nextNode()) {
        labels.push(siblingsWalker.currentNode as Element);
      }
    } else {
      const walker = document.createTreeWalker(
        startNode,
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode: (node: Node) => {
            if (node instanceof Element && node.tagName === 'LABEL') {
              const range = document.createRange();
              range.selectNodeContents(node);
              const intersects =
                selectedRange.compareBoundaryPoints(Range.END_TO_START, range) <
                  0 &&
                selectedRange.compareBoundaryPoints(Range.START_TO_END, range) >
                  0;
              range.detach();
              return intersects
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP;
            }
            return NodeFilter.FILTER_SKIP;
          },
        }
      );
      while (walker.nextNode()) {
        labels.push(walker.currentNode as Element);
      }
    }

    if (labels.length) {
      if (answerCrossouts[activeVignette.id]) {
        const updatedCrossouts = { ...answerCrossouts };
        updatedCrossouts[activeVignette.id] = [
          ...updatedCrossouts[activeVignette.id],
        ];
        labels.forEach((label) => {
          const index = Number(label.getAttribute('data-index'));
          updatedCrossouts[activeVignette.id][index] =
            !updatedCrossouts[activeVignette.id][index];
        });
        const newAnswerCrossouts = await updateBlockCrossouts(
          activeBlock.id,
          activeVignette.id,
          updatedCrossouts[activeVignette.id]
        );
        const updatedBlock = {
          ...activeBlock,
          answerCrossouts: newAnswerCrossouts,
        };
        dispatch(updateBlockFE(updatedBlock));
      } else {
        const newCrossouts = Array(
          activeVignetteVariant.answerOptions.length
        ).fill(false);
        const updatedCrossouts = {
          ...answerCrossouts,
          [activeVignette.id]: newCrossouts,
        };
        labels.forEach((label) => {
          const index = Number(label.getAttribute('data-index'));
          updatedCrossouts[activeVignette.id][index] = true;
        });
        const newAnswerCrossouts = await updateBlockCrossouts(
          activeBlock.id,
          activeVignette.id,
          updatedCrossouts[activeVignette.id]
        );
        const updatedBlock = {
          ...activeBlock,
          answerCrossouts: newAnswerCrossouts,
        };
        dispatch(updateBlockFE(updatedBlock));
      }
      window.getSelection()?.removeAllRanges();
    }
  };

  const handleRightClickCrossout = async (event: MouseEvent) => {
    event.preventDefault();
    if (event.target instanceof HTMLElement) {
      let labelElement: HTMLLabelElement | null = null;
      if (event.target.tagName === 'LABEL') {
        labelElement = event.target as HTMLLabelElement;
      } else if (event.target.closest('label')) {
        labelElement = event.target.closest('label') as HTMLLabelElement;
      }
      if (labelElement && labelElement.getAttribute('data-index') !== null) {
        const index = Number(labelElement.getAttribute('data-index'));
        const updatedCrossouts = { ...answerCrossouts }; // Shallow copy of the outer object
        if (!updatedCrossouts[activeVignette.id]) {
          updatedCrossouts[activeVignette.id] = [];
        } else {
          updatedCrossouts[activeVignette.id] = [
            ...updatedCrossouts[activeVignette.id],
          ];
        }
        const isCrossedOut = updatedCrossouts[activeVignette.id][index];
        updatedCrossouts[activeVignette.id][index] = !isCrossedOut;
        const newAnswerCrossouts = await updateBlockCrossouts(
          activeBlock.id,
          activeVignette.id,
          updatedCrossouts[activeVignette.id]
        );
        const updatedBlock = {
          ...activeBlock,
          answerCrossouts: newAnswerCrossouts,
        };
        dispatch(updateBlockFE(updatedBlock));
        labelElement.classList.toggle('crossed-out');
      }
    }
  };

  const hasAnsweredQuestions = useMemo(() => {
    return activeBlock && Object.keys(activeBlock.answers).length > 0;
  }, [activeBlock]);

  const renderSwitchPhaseButton = () => {
    if (hasAnsweredQuestions) return null;
    if (todaySession.phase === StudyPhase.qbankReview) return null;
    if (activeBlock?.isSubmitted) return null;
    if (isDemo) return null;

    const toggleBlockType = async () => {
      if (changePhase) changePhase(StudyPhase.qbank);
      const newBlockType =
        activeBlock.type === BlockType.timed
          ? BlockType.tutor
          : BlockType.timed;
      const newDuration =
        newBlockType === BlockType.timed ? activeBlock.vignetteCount * 90 : 0;
      try {
        const response = await updateBlockBE(activeBlock.id, {
          ...activeBlock,
          type: newBlockType,
          duration: newDuration,
        });
        dispatch(updateBlockFE(response));
      } catch (error) {
        console.error('Error updating block: ', error);
      }
    };

    return (
      <button
        className="button button--glass button--switch-block-type"
        onClick={toggleBlockType}
      >
        <Repeat />
        Change to {activeBlock.type === BlockType.timed
          ? 'Untimed'
          : 'Timed'}{' '}
        Block
      </button>
    );
  };

  const cleanupExam = async (activeBlock: Block) => {
    if (isDiagnostic) return;
    if (!activeBlock.id) return;
    console.log('Exam Unmounting');
    if (todaySession.phase === StudyPhase.qbank) {
      console.log('Block Active During Unmount, Update Backend');
      updateBlockTime();
    }
  };

  // Update the Backend with the Block Time Remaining
  const updateBlockTime = async () => {
    if (!activeBlockRef.current) return;
    const updatedBlock = {
      ...activeBlockRef.current,
      duration: timeRemainingRef.current,
    };
    try {
      fadeInOutFlashLoader();
      const response = await updateBlockBE(
        activeBlockRef.current.id,
        updatedBlock
      );
      dispatch(updateBlockFE(response));
    } catch (error) {
      console.error('Error updating block: ', error);
    }
  };

  const prevQuestion = async () => {
    if (activeQuestionIndex !== 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
  };

  const nextQuestion = async () => {
    if (activeQuestionIndex !== vignettes.length - 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const toggleTheme = () => {
    if (theme === 'default') {
      document.body.classList.add('dark');
      setTheme('dark');
    } else {
      document.body.classList.remove('dark');
      setTheme('default');
    }
  };

  const setAnswer = async (
    vignette: Vignette,
    answerChoice: string | undefined | null,
    submit: boolean
  ) => {
    if (!answerChoice) {
      if (activeBlock.answers && activeBlock.answers[vignette.id]) {
        answerChoice = activeBlock.answers[vignette.id].answer;
      } else {
        return;
      }
    }
    const currVignetteVariant = isDemo
      ? vignette.contents[0]
      : vignette.contents[vignette.scheduling.variantIndex];

    const updatedBlock = {
      ...activeBlock,
      answers: {
        ...activeBlock.answers,
        [vignette.id]: {
          correctAnswer: currVignetteVariant.correctAnswer,
          answer: answerChoice,
          submitted: submit,
          vignetteContentId: currVignetteVariant.id,
        },
      },
      answerCrossouts: answerCrossouts,
    };

    // Logs a timed question answer the first time an answer is selected
    if (activeBlockType === BlockType.timed) {
      if (activeBlock.answers && !activeBlock.answers[vignette.id]) {
        posthog?.capture('timed_question_answer');
      }
    }

    // If tutor mode, submit the vignette for scheduling updates
    if (submit && activeBlockType === BlockType.tutor) {
      // Submit the tutor vignette to BE for scheduling updates
      await submitTutorVignette(
        vignette.id,
        answerChoice,
        currVignetteVariant.correctAnswer,
        submit,
        isDemo
      );
      // Update the review variant index in redux for review mode
      posthog?.capture('tutor_question_submit', { isDemo: isDemo });
      if (!isDemo) updateVignetteReviewVariantFE(vignette.id);
      if (windowWidth < 900) {
        setTimeout(() => {
          if (tourNonAuthStep2Ref.current) {
            tourNonAuthStep2Ref.current.style.scrollMarginTop = '24px';
            tourNonAuthStep2Ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 50);
      }

      setQBankDemoQuestionCorrect(
        answerChoice === currVignetteVariant.correctAnswer
      );

      // Create a vignette response
      await createVignetteResponseBE(
        currVignetteVariant.id,
        answerChoice,
        todaySession.id
      );
    }

    addBlockResponse(
      updatedBlock.id,
      vignette.id,
      answerChoice,
      currVignetteVariant.correctAnswer,
      submit,
      currVignetteVariant.id
    );
    dispatch(updateBlockFE(updatedBlock));
  };

  const toggleLabValues = () => {
    setShowLabValues(!showLabValues);
  };

  const toggleCalculator = () => {
    if (!showCalculator) {
      setShowNotes(false);
    }
    setShowCalculator(!showCalculator);
  };

  const toggleNotes = () => {
    if (!showNotes) {
      setShowCalculator(false);
    }
    setShowNotes(!showNotes);
  };

  // Returns if user has answered the vignette. Tutor: If Submitted, Timed: If Answered
  const isAnswered = (vignetteId: string) => {
    return (
      (activeBlock.type === BlockType.tutor &&
        activeBlock.answers &&
        activeBlock.answers[vignetteId] &&
        activeBlock.answers[vignetteId].submitted) ||
      (activeBlock.type === BlockType.timed &&
        activeBlock.answers &&
        activeBlock.answers[vignetteId])
    );
  };

  // Returns the user's answer for the vignette
  const storedAnswer = (vignetteId: string) => {
    if (activeBlock.answers) {
      const answerObj = activeBlock.answers[vignetteId];
      return answerObj?.answer || null;
    }
    return null;
  };

  const calculateCorrectCount = () => {
    return Object.values(activeBlock.answers).reduce(
      (correctCount, currentAnswer) => {
        if (currentAnswer.answer === currentAnswer.correctAnswer) {
          return correctCount + 1;
        }
        return correctCount;
      },
      0
    );
  };

  const calculateScore = () => {
    const correctCount = calculateCorrectCount();
    const incorrectCount =
      Object.keys(activeBlock.answers).length - correctCount;
    const omittedCount =
      activeBlock.vignetteCount - correctCount - incorrectCount;
    return {
      correctCount,
      incorrectCount,
      omittedCount,
    };
  };

  const endBlock = async () => {
    if (isDiagnostic && activeVignette?.id) {
      addVignetteResponseTime(vignetteStartTime, activeVignette.id);
    }
    const scoreObj = calculateScore();
    const updatedBlock = {
      ...activeBlock,
      isSubmitted: true,
      duration: 0,
      ...scoreObj,
    };
    if (activeBlockType === BlockType.timed) {
      setActiveQuestionIndex(0);
    }
    const isTimedMode = activeBlockType === BlockType.timed;
    if (!isDemo) {
      activeBlockType === BlockType.timed
        ? posthog?.capture('timed_block_submit')
        : posthog?.capture('tutor_block_submit');
    }
    submitBlock(updatedBlock.id, scoreObj, isTimedMode, isDemo || false);
    dispatch(updateBlockFE(updatedBlock));
    // Set reviewVariantIndex to variantIndex in Redux
    if (!isDemo) updateVignetteReviewVariantsFE();
    clearInterval(timer);
    // Update Today Session
    updateStudySessionScoreCounts(scoreObj);
  };

  // For Tutor Mode: Submit the vignette and update the review variant index in Redux
  const updateVignetteReviewVariantFE = (vignetteId: string) => {
    try {
      const clonedVignettes = _.cloneDeep(vignettes);
      const vignetteIndex = clonedVignettes.findIndex(
        (v) => v.id === vignetteId
      );
      const scheduling = clonedVignettes[vignetteIndex].scheduling;
      scheduling.reviewVariantIndex = scheduling.variantIndex;
      dispatch(setVignettes(clonedVignettes));
    } catch (error) {
      console.log('Error updating review variant: ', error);
      console.log('Vignettes: ', vignettes);
    }
  };

  // For Timed Mode: Submit the block and update all the vignette review variant indices in Redux
  const updateVignetteReviewVariantsFE = () => {
    try {
      const clonedVignettes = _.cloneDeep(vignettes);
      for (const clonedVignette of clonedVignettes) {
        const scheduling = clonedVignette.scheduling;
        const variantIndex = scheduling.variantIndex;
        scheduling.reviewVariantIndex = variantIndex;
      }
      dispatch(setVignettes(clonedVignettes));
    } catch (error) {
      console.log('Error updating review variants: ', error);
      console.log('Vignettes: ', vignettes);
    }
  };

  const updateStudySessionScoreCounts = async (scoreObj: {
    correctCount: number;
    incorrectCount: number;
    omittedCount: number;
  }) => {
    const vignetteCorrectCount =
      todaySession.vignetteCorrectCount + scoreObj.correctCount;
    const vignetteIncorrectCount =
      todaySession.vignetteIncorrectCount + scoreObj.incorrectCount;
    const vignetteOmittedCount =
      todaySession.vignetteOmittedCount + scoreObj.omittedCount;

    const updatedStudySession = {
      ...todaySession,
      vignetteCorrectCount,
      vignetteIncorrectCount,
      vignetteOmittedCount,
    };

    const response = await updateStudySession(
      todaySession.id,
      updatedStudySession
    );
    dispatch(setTodaySession(response));
  };

  const continueExam = async () => {
    setActiveQuestionIndex(0);
    if (nextBlock) {
      nextBlock();
    }
  };

  const completeDiagnostic = async () => {
    const updatedStudySession = { ...todaySession, isComplete: true };
    const response = await updateStudySession(
      todaySession.id,
      updatedStudySession
    );
    dispatch(setTodaySession(response));
  };

  const startBlock = async (type: BlockType) => {
    fadeInOutFlashLoader();
    setTimeout(() => {
      if (setIsStart) setIsStart(false);
    }, 1000);
    if (!activeBlock) return;
    if (!activeBlock.isStarted) {
      const duration = type === BlockType.timed ? activeBlock.duration : 0;
      const updatedBlock = {
        ...activeBlock,
        isStarted: true,
        type: type,
        duration: duration,
      };
      posthog?.capture(type + '_block_start');
      try {
        const response = await updateBlockBE(activeBlock.id, updatedBlock);
        dispatch(updateBlockFE(response));
      } catch (error) {
        console.error('Error updating block: ', error);
      }
      if (type === BlockType.tutor) {
        setTimeRemaining(0);
      }
    }
  };

  const resumeBlock = async () => {
    fadeInOutFlashLoader();
    setTimeout(() => {
      if (setIsStart) setIsStart(false);
    }, 1000);
  };

  const pauseExam = async () => {
    if (changePhase) changePhase(StudyPhase.qbank);
    hapticsImpactLight();
    enqueueSnackbar('QBank progress saved.', {
      autoHideDuration: 3000,
    });
    if (todaySession.phase === StudyPhase.qbank) {
      updateBlockTime();
    }
    refreshFlashcards();
  };

  const updateVignetteNotes = async () => {
    setShowNotes(false);
    const res = await updateBlockNotes(
      activeBlockId,
      activeVignette.id,
      vignetteNotes
    );
    if (res) {
      dispatch(updateBlockFE(res));
    }
  };

  const clearVignetteNotes = async () => {
    setVignetteNotes('');
    const res = await updateBlockNotes(activeBlockId, activeVignette.id, '');
    if (res) {
      dispatch(updateBlockFE(res));
    }
  };

  const vignettesRemaining = () => {
    let count = 0;
    vignettes.forEach((v) => {
      if (!isAnswered(v.id)) {
        count++;
      }
    });
    return count;
  };

  const questionIsSubmitted = (id: string) => {
    return activeBlock.answers[id]?.submitted;
  };

  const parseExplanation = (explanationText: string) => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (
          domNode.type === 'tag' &&
          domNode.name === 'img' &&
          domNode.attribs &&
          domNode.attribs['data-image-caption']
        ) {
          const {
            src,
            'data-image-caption': caption,
            'data-image-credit': credit,
            'data-image-src-annotated': annotatedSrc,
          } = domNode.attribs;

          return (
            <CustomImage
              src={src}
              caption={caption}
              credit={credit}
              annotatedSrc={annotatedSrc}
            />
          );
        }
      },
    };

    const explanation = ReactHtmlParser(explanationText, options);
    setParsedExplanation(
      Array.isArray(explanation) ? explanation : [explanation]
    );
  };

  const parseExhibit = (exhibitText: string) => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (
          domNode.type === 'tag' &&
          domNode.name === 'img' &&
          domNode.attribs &&
          domNode.attribs['data-image-caption']
        ) {
          const {
            src,
            'data-image-caption': caption,
            'data-image-credit': credit,
            'data-image-src-annotated': annotatedSrc,
          } = domNode.attribs;

          return (
            <CustomImage
              src={shouldRenderAnswerAndExplanation() ? annotatedSrc : src}
              caption={caption}
              credit={credit}
              annotatedSrc={annotatedSrc}
            />
          );
        }
      },
    };

    const explanation = ReactHtmlParser(exhibitText, options);
    setParsedExhibit(Array.isArray(explanation) ? explanation : [explanation]);
  };

  const shouldRenderAnswerAndExplanation = () => {
    if (activeBlock.isSubmitted) {
      return true;
    }
    if (
      activeBlock.type === BlockType.tutor &&
      questionIsSubmitted(activeVignette.id)
    ) {
      return true;
    }
    return false;
  };

  const renderQuestionList = () => {
    return (
      <div className={`questions ${questionsMenuIsOpen ? 'show' : ''}`}>
        <div className="questions_header">
          <h3>Select a Question</h3>
          <button
            className="button button--icon-only"
            onClick={() => {
              setQuestionsMenuIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <X />
          </button>
        </div>
        <div className="questions_list">
          {vignettes.map((v, i) => (
            <button
              className={`item ${i === activeQuestionIndex ? 'active' : ''} ${isAnswered(v.id) ? 'answered' : ''}`}
              key={'q-' + v.id}
              value={i}
              onClick={() => {
                setQuestionsMenuIsOpen(false);
                setActiveQuestionIndex(i);
                hapticsImpactLight();
                if (isDiagnostic) {
                  setVignetteChangeCount(vignetteChangeCount + 1);
                }
              }}
            >
              <li>
                {activeBlock.answers[v.id]?.correctAnswer ===
                  storedAnswer(v.id) &&
                  (activeBlock.isSubmitted ||
                    activeBlock.answers[v.id]?.submitted) && <Check />}
                <span>{i + 1}</span>
                <div>
                  {activeBlock.flags[v.id] && (
                    <>
                      <img className="light" src={StandardFlag} alt="Flag" />
                      <img className="dark" src={FlagDark} alt="Flag" />
                    </>
                  )}
                  {activeBlock.notes[v.id] && (
                    <img className="pencil" src={Pencil} alt="Pencil" />
                  )}
                </div>
              </li>
            </button>
          ))}
        </div>
      </div>
    );
  };

  const getLinkedFlashcards = async (id: string) => {
    const linkedFlashcards = await fetchLinkedFlashcards(id);
    setLinkedFlashcards(linkedFlashcards);
  };

  const handlePanelResize = () => {
    setTimeout(() => {
      if (rightColumnRef.current) {
        const width = rightColumnRef.current.getBoundingClientRect().width;
        updateHeaderWidth(width);
      }
    }, 100);
  };

  const updateHeaderWidth = (width: number) => {
    const header = document.querySelector('.study-panel_header') as HTMLElement;
    if (header) {
      header.style.width = `${width}px`;
    }
  };

  const updateMobileTextSize = async (
    newSize: 'smaller' | 'default' | 'larger'
  ) => {
    const updateUser = await updateCurrentUserInfo({
      vignetteMobileTextSize: newSize,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        setTimeout(() => {
          document.body.classList.remove('theme-dark');
        }, 0);
      }
    }
  };

  const renderMobileTextSizeButton = () => {
    return (
      <div className="button button--text-size">
        <div className="button--text-size_wrapper">
          <button
            className={`button--text-size_wrapper_option ${!currentUser || currentUser?.vignetteMobileTextSize === 'smaller' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateMobileTextSize('smaller');
            }}
          >
            A
          </button>
          <button
            className={`button--text-size_wrapper_option button--text-size_wrapper_option--middle ${currentUser?.vignetteMobileTextSize === 'default' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateMobileTextSize('default');
            }}
          >
            A
          </button>
          <button
            className={`button--text-size_wrapper_option ${currentUser?.vignetteMobileTextSize === 'larger' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateMobileTextSize('larger');
            }}
          >
            A
          </button>
        </div>
      </div>
    );
  };

  const renderFunctionMenu = () => {
    if (!functionMenuIsOpen) return null;
    return (
      <div
        className="function-menu"
        role="button"
        aria-label="Close Function Menu"
        onClick={() => {
          hapticsImpactLight();
          setFunctionMenuIsOpen(false);
        }}
      >
        <div className="function-menu_options">
          <button
            onClick={() => {
              hapticsImpactLight();
              toggleNotes();
            }}
          >
            <img
              src={NotesIcon}
              alt="Notes"
              style={{ transform: 'scale(1.25), translateY(2px)' }}
            />
            Notes
          </button>
          <button
            onClick={() => {
              hapticsImpactLight();
              toggleCalculator();
            }}
          >
            <img src={CalculatorIcon} alt="Calculator" />
            Calculator
          </button>
          {shouldRenderAnswerAndExplanation() && (
            <button
              onClick={() => {
                hapticsImpactLight();
                setPanelType(PanelType.report);
              }}
            >
              <AlertTriangle />
              Report
            </button>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <Type />
            Text Size
            {renderMobileTextSizeButton()}
          </div>
          <button
            onClick={() => {
              hapticsImpactLight();
              window.Intercom('showNewMessage');
            }}
          >
            <MessageSquare />
            Help
          </button>
          {renderSwitchPhaseButton()}
        </div>
      </div>
    );
  };

  const handleStartTour = (type: 'non-auth' | 'qbank') => {
    if (type === 'non-auth') {
      setCurrentNonAuthStepIndex(0);
      setIsNonAuthTourRunning(true);
    } else {
      setCurrentQBankTourStepIndex(0);
      setIsQBankTourRunning(true);
    }
  };

  const handlePauseTour = (type: 'non-auth' | 'qbank') => {
    if (type === 'non-auth') {
      setIsNonAuthTourRunning(false);
    } else {
      setIsQBankTourRunning(false);
    }
  };

  const handleTourEnd = (type: 'non-auth' | 'qbank') => {
    if (type === 'non-auth') {
      setIsNonAuthTourRunning(false);
      setCurrentNonAuthStepIndex(0);
      Cookies.set('completedDemoTour', 'true', { expires: 365 });
    } else {
      setIsQBankTourRunning(false);
      setCurrentQBankTourStepIndex(0);
    }
  };

  const handleStepChange = (type: 'non-auth' | 'qbank', newIndex: number) => {
    if (type === 'non-auth') {
      setCurrentNonAuthStepIndex(newIndex);
    } else {
      setCurrentQBankTourStepIndex(newIndex);
    }
  };

  const memoizedAnswerOptions = useMemo(() => {
    if (!activeVignette || !activeVignetteVariant || !activeBlock) return null;

    const storedAnswer = (vignetteId: string) => {
      if (activeBlock.answers) {
        const answerObj = activeBlock.answers[vignetteId];
        return answerObj?.answer || null;
      }
      return null;
    };

    const determineSelectedAnswer = (answerOption: {
      optionLetter: string;
    }) => {
      return answerOption.optionLetter === storedAnswer(activeVignette?.id);
    };

    handlePanelResize();

    return activeVignetteVariant.answerOptions.map((answerOption, i) => {
      const vignetteId = activeVignette.id;
      const crossouts = answerCrossouts?.[vignetteId] || [];
      const isCrossedOut = crossouts[i];

      const correctChoice =
        shouldRenderAnswerAndExplanation() &&
        answerOption.optionLetter === activeVignetteVariant.correctAnswer;

      const incorrectChoice =
        shouldRenderAnswerAndExplanation() &&
        answerOption.optionLetter !== activeVignetteVariant.correctAnswer &&
        storedAnswer(vignetteId) === answerOption.optionLetter;

      return (
        <label
          key={i}
          htmlFor={String.fromCharCode(65 + i)}
          data-index={i}
          className={`${isCrossedOut ? 'crossed-out' : ''}${
            correctChoice ? ' correct-answer' : ''
          }`}
        >
          {correctChoice && <Check className="correct" />}
          {incorrectChoice && <X className="incorrect" />}
          <input
            id={String.fromCharCode(65 + i)}
            type="radio"
            name="answer-choices"
            disabled={
              activeBlock.isSubmitted || shouldRenderAnswerAndExplanation()
            }
            checked={determineSelectedAnswer(answerOption)}
            onChange={() => {
              setAnswer(activeVignette, answerOption.optionLetter, false);
            }}
            onClick={hapticsImpactLight}
          />
          <div className="input-ring"></div>
          <span>
            {String.fromCharCode(65 + i)}.{' '}
            {parse(activeVignetteVariant.answerOptions[i].optionText)}
          </span>
        </label>
      );
    });
  }, [activeVignetteVariant, activeVignette, activeBlock, answerCrossouts]);

  const handleSeenQBankTour = async () => {
    if (!currentUser) return;
    if (currentUser?.onboardingTips?.['qbank-tour']) return;
    const updateUser = await updateCurrentUserInfo({
      onboardingTips: {
        ...currentUser?.onboardingTips,
        'qbank-tour': true,
      },
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <div
      className={`exam${currentUser?.vignetteTextSize === 'larger' ? ' text-size-larger' : ''}${currentUser?.vignetteTextSize === 'largest' ? ' text-size-largest' : ''}${currentUser?.vignetteMobileTextSize === 'smaller' ? ' text-size-mobile-smaller' : ''}${currentUser?.vignetteMobileTextSize === 'larger' ? ' text-size-mobile-larger' : ''}`}
    >
      {isStart && !todaySession.isReview && (
        <>
          <StartScreen
            activeBlock={activeBlock}
            currentBlockNumber={currentBlockNumber}
            timeRemaining={timeRemaining}
            startBlock={startBlock}
            resumeBlock={resumeBlock}
            primaryExam={primaryExam}
            fadeInFlashLoader={fadeInFlashLoader}
            reviewQbankBlock={reviewQbankBlock}
          />
        </>
      )}
      {!isStart && (
        <>
          {renderQuestionList()}
          <div
            className={`main${activeBlock.isSubmitted ? ' reviewing' : ''}${reportCard ? ' report-card-open' : ''}`}
          >
            <ExamHeader
              currentBlockType={activeBlockType}
              currentBlockNumber={currentBlockNumber}
              currentBlockId={activeBlockId}
              currentQuestion={activeQuestionIndex}
              currentVignetteId={activeVignette?.id}
              totalQuestions={vignettes.length}
              timeRemaining={timeRemaining}
              isSubmitted={activeBlock.isSubmitted}
              prevQuestion={prevQuestion}
              nextQuestion={nextQuestion}
              toggleTheme={toggleTheme}
              toggleLabValues={toggleLabValues}
              toggleCalculator={toggleCalculator}
              toggleNotes={toggleNotes}
              blockCount={blockCount}
              setQuestionsMenuIsOpen={setQuestionsMenuIsOpen}
              isFlagged={activeBlock.flags[activeVignette?.id]}
              labValuesAreOpen={showLabValues}
              calculatorIsOpen={showCalculator}
              notesAreOpen={showNotes}
              pauseBlock={pauseExam}
              setConfirmEndIsOpen={setConfirmEndIsOpen}
              isDemo={isDemo}
            />
            <div
              className={`content-area${showLabValues ? ' lab-values-open' : ''}${shouldRenderAnswerAndExplanation() ? ' showing-answer' : ''}`}
            >
              {vignettes.length && (
                <section className="content-area_main">
                  <PanelGroup
                    id="panel-group"
                    direction="horizontal"
                    className="panel-group"
                  >
                    <Panel id="left-panel" className="panel" minSize={33}>
                      <div className="left" ref={leftColumnRef}>
                        {currentUser?.role === 'admin' && (
                          <div className="admin-toolbar">
                            <div className="qa-status">
                              {activeVignette.isQAed ? (
                                <div className="is-qaed">Is QAed</div>
                              ) : (
                                <div className="is-not-qaed">Is Not QAed</div>
                              )}
                            </div>
                            <div className="spacer">|</div>
                            <button
                              className="uuid-display"
                              onClick={() =>
                                copyToClipboard(activeVignetteVariant.id)
                              }
                            >
                              <Copy />
                              {activeVignetteVariant?.id}
                            </button>
                          </div>
                        )}
                        <div ref={highlightTarget}></div>
                        {!shouldRenderAnswerAndExplanation() &&
                          parsedExhibit &&
                          parsedExhibit.length > 0 && (
                            <div className="exhibit not-in-review m-t-1-50 exhibit--mobile">
                              {parsedExhibit}
                            </div>
                          )}
                        <div className="answer-block" ref={tourNonAuthStep2Ref}>
                          <form>{memoizedAnswerOptions}</form>
                        </div>
                        <div
                          className="proceed"
                          style={
                            activeQuestionIndex === vignettes.length - 1 &&
                            activeBlockType === BlockType.tutor &&
                            !shouldRenderAnswerAndExplanation()
                              ? { padding: 0 }
                              : {}
                          }
                        >
                          {activeBlockType === BlockType.tutor &&
                            !shouldRenderAnswerAndExplanation() &&
                            todaySession.phase !== StudyPhase.qbankReview && (
                              <button
                                className="button button--submit"
                                onClick={() => {
                                  hapticsImpactLight();
                                  if (isDemo) {
                                    setAnswer(activeVignette, null, true);
                                    if (activeQuestionIndex === 0) {
                                      setCurrentNonAuthStepIndex(1);
                                      setTimeout(() => {
                                        setIsNonAuthTourRunning(true);
                                      }, 250);
                                    }
                                  } else {
                                    setAnswer(activeVignette, null, true);
                                  }
                                }}
                                disabled={!storedAnswer(activeVignette?.id)}
                                style={
                                  activeQuestionIndex === vignettes.length - 1
                                    ? { margin: '1.5rem 0' }
                                    : {}
                                }
                              >
                                Submit
                              </button>
                            )}
                          {((activeBlockType === BlockType.timed &&
                            activeQuestionIndex !== vignettes.length - 1) ||
                            (activeBlockType === BlockType.tutor &&
                              shouldRenderAnswerAndExplanation() &&
                              activeQuestionIndex !==
                                vignettes.length - 1)) && (
                            <button
                              className="button"
                              onClick={() => {
                                nextQuestion();
                                hapticsImpactLight();
                                if (isDiagnostic) {
                                  setVignetteChangeCount(
                                    vignetteChangeCount + 1
                                  );
                                }
                              }}
                            >
                              Proceed to Next Item
                            </button>
                          )}
                        </div>
                      </div>
                    </Panel>
                    {shouldRenderAnswerAndExplanation() && (
                      <PanelResizeHandle className="panel-resize">
                        <Drag />
                      </PanelResizeHandle>
                    )}
                    <Panel
                      id="right-panel"
                      className="panel"
                      onResize={handlePanelResize}
                      minSize={40}
                    >
                      <div className="right" ref={rightColumnRef}>
                        {!shouldRenderAnswerAndExplanation() &&
                          parsedExhibit &&
                          parsedExhibit.length > 0 && (
                            <div className="exhibit not-in-review m-t-1-50 exhibit--desktop">
                              {parsedExhibit}
                            </div>
                          )}
                        {shouldRenderAnswerAndExplanation() && (
                          <StudyPanel
                            panelType={panelType}
                            setPanelType={setPanelType}
                            phase={StudyPhase.qbank}
                            targetArticleId={
                              activeVignette?.topics
                                ? activeVignette?.topics[0]?.articleId
                                : ''
                            }
                            activeVignette={activeVignette}
                            activeVignetteVariant={activeVignetteVariant}
                            linkedFlashcards={linkedFlashcards}
                            getLinkedFlashcards={getLinkedFlashcards}
                            isDemo={isDemo}
                            reportValue={reportValue}
                            setReportValue={setReportValue}
                          >
                            <>
                              <div className="explanation">
                                {parsedExhibit && parsedExhibit.length > 0 && (
                                  <div className="exhibit">{parsedExhibit}</div>
                                )}
                                {activeVignetteVariant.Image &&
                                  activeVignetteVariant.Image.length > 0 && (
                                    <ImageDisplay
                                      images={activeVignetteVariant.Image}
                                      displayMainImage={true}
                                      activeContentId={activeVignetteVariant.id}
                                    />
                                  )}
                                {parsedExplanation}
                                {activeVignetteVariant.learningObjective && (
                                  <>
                                    <div className="block block--lo">
                                      <strong>Learning Objective: </strong>
                                      {parse(
                                        activeVignetteVariant.learningObjective
                                      )}
                                    </div>
                                  </>
                                )}
                                {activeVignetteVariant.references &&
                                  activeVignetteVariant.references.length >
                                    0 && (
                                    <div className="references">
                                      <ul
                                        className={
                                          activeVignetteVariant.references
                                            .length === 1
                                            ? 'one-ref'
                                            : ''
                                        }
                                      >
                                        {activeVignetteVariant.references.map(
                                          (reference, index) => (
                                            <li key={index}>
                                              {parse(reference)}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </>
                          </StudyPanel>
                        )}
                      </div>
                    </Panel>
                  </PanelGroup>
                </section>
              )}
              <section className="content-area_lab-values-container">
                <LabValues labValuesRef={labValuesRef} />
              </section>
              {showCalculator && (
                <Calculator closeCalculator={toggleCalculator} />
              )}
              {showNotes && (
                <Notes
                  vignetteNotes={vignetteNotes}
                  setVignetteNotes={setVignetteNotes}
                  updateVignetteNotes={updateVignetteNotes}
                  clearVignetteNotes={clearVignetteNotes}
                  isReview={todaySession.phase === StudyPhase.qbankReview}
                  activeBlockType={activeBlockType}
                  isPastSessionReview={todaySession.isReview}
                  toggleNotes={toggleNotes}
                />
              )}
              {renderFunctionMenu()}
            </div>
            <Footer
              activeBlockId={activeBlockId}
              blockType={activeBlockType}
              isSubmitted={activeBlock.isSubmitted}
              changePhase={changePhase}
              continueExam={continueExam}
              timeRemaining={timeRemaining}
              activeVignetteId={activeVignette?.id}
              isDiagnostic={isDiagnostic}
              completeDiagnostic={completeDiagnostic}
              fadeInFlashLoader={fadeInFlashLoader}
              allDiagnosticQuestionsAnswered={
                Object.keys(activeBlock.answers).length ===
                activeBlock.vignetteCount
              }
              pauseBlock={pauseExam}
              setConfirmEndIsOpen={setConfirmEndIsOpen}
              nextQuestion={nextQuestion}
              prevQuestion={prevQuestion}
              currentQuestion={activeQuestionIndex}
              totalQuestions={vignettes.length}
              isDemo={isDemo}
              allDemoQuestionsSubmitted={allDemoQuestionsSubmitted}
              createAccountModalIsOpen={createAccountModalIsOpen}
              functionMenuIsOpen={functionMenuIsOpen}
              setFunctionMenuIsOpen={setFunctionMenuIsOpen}
              refreshFlashcards={refreshFlashcards}
              renderSwitchPhaseButton={renderSwitchPhaseButton}
            />
            {/* {currentUser?.role === 'admin' && isEditing && (
              <EditVignette
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                activeVignette={activeVignette}
                setKeybindsDisabled={setKeybindsDisabled}
              />
            )} */}
          </div>
          <Popover
            open={vignetteMenuIsOpen}
            onClose={() => setVignetteMenuIsOpen(false)}
            anchorEl={contextMenuAnchor.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            disableRestoreFocus
          >
            <div className="modal modal--context-menu">
              <button
                onClick={() => {
                  setReportCard(true);
                  setVignetteMenuIsOpen(false);
                  hapticsImpactLight();
                }}
              >
                Report Question
              </button>
            </div>
          </Popover>
          <ImagePopover parsedExplanation={parsedExplanation} />
          <Modal
            open={confirmEndIsOpen}
            onClose={() => setConfirmEndIsOpen(false)}
            disableRestoreFocus
          >
            <div className="modal modal--end-block">
              <div
                className={`modal_header ${vignettesRemaining() > 0 ? '' : 'non-zero'}`}
              >
                {vignettesRemaining() > 0
                  ? 'Warning - This block is incomplete!'
                  : 'Warning - You are about to end the block!'}
              </div>
              <div className="modal_content">
                {vignettesRemaining() > 0 && (
                  <p className="m-t-0">
                    Number of unanswered items in this block:{' '}
                    {vignettesRemaining()}
                  </p>
                )}
                <p>If you end this block you will not be able to return.</p>
              </div>
              <div className="modal_actions">
                <button
                  className="button button--eb"
                  onClick={() => {
                    endBlock();
                    hapticsImpactLight();
                    setConfirmEndIsOpen(false);
                  }}
                >
                  End Block
                </button>
                <button
                  onClick={() => {
                    setConfirmEndIsOpen(false);
                    hapticsImpactLight();
                  }}
                  className="button button--remain"
                >
                  Remain in Block
                </button>
              </div>
            </div>
          </Modal>
          {!isDemo && showQBankTour && (
            <QBankFeatureTour
              stepTargets={[...qbankStepSelectors]}
              stepFunctions={[() => {}]}
              run={isQBankTourRunning}
              stepIndex={currentQBankTourStepIndex}
              onStepChange={(newIndex) => handleStepChange('qbank', newIndex)}
              onTourEnd={() => handleTourEnd('qbank')}
              handleSeenQBankTour={handleSeenQBankTour}
              qBankDemoQuestionCorrect={qBankDemoQuestionCorrect}
            />
          )}
          {isDemo && (
            <NonAuthFeatureTour
              activeQuestionIndex={activeQuestionIndex}
              answeredCorrectly={
                activeBlock.answers[activeVignette.id]?.answer ===
                activeBlock.answers[activeVignette.id]?.correctAnswer
              }
              stepTargets={[...stepRefs, ...nonAuthStepSelectors]}
              stepFunctions={[() => {}, () => {}, () => {}]}
              run={isNonAuthTourRunning}
              stepIndex={currentNonAuthStepIndex}
              onStepChange={(newIndex) =>
                handleStepChange('non-auth', newIndex)
              }
              onTourEnd={() => handleTourEnd('non-auth')}
              createAccountModalIsOpen={createAccountModalIsOpen}
              setCreateAccountModalIsOpen={setCreateAccountModalIsOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Exam;
