export const XboxA: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M21.8989 22H18.9771L18.1304 19.3521H13.897L13.0586 22H10.1533L14.4863 10.0967H17.6655L21.8989 22ZM17.5161 17.2935L16.2378 13.2925C16.1437 12.9937 16.0773 12.6367 16.0386 12.2217H15.9722C15.9445 12.5703 15.8753 12.9162 15.7646 13.2593L14.4697 17.2935H17.5161Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
