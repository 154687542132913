import React from 'react';
import ImageDisplay from '../../Misc/ImageDisplay';

interface ArticlePreviewProps {
  articleTitle?: string;
  images?: {
    src: string;
    alt: string;
    caption?: string;
    credit?: string;
    ord: number;
  }[];
  children: React.ReactNode;
  displayMainImage?: boolean;
}

const ArticlePreview: React.FC<ArticlePreviewProps> = ({
  articleTitle,
  images,
  children,
  displayMainImage,
}) => {
  return (
    <>
      <div className="article-preview">
        <div className="article-preview_content">
          {articleTitle && (
            <h1 className="article-preview_title">{articleTitle}</h1>
          )}
          {!articleTitle && <h2 className="summary">Summary</h2>}
          {images && images.length > 0 && (
            <ImageDisplay images={images} displayMainImage={displayMainImage} />
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default ArticlePreview;
