export const PsCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <circle
      cx="16"
      cy="16"
      r="5.75"
      stroke="var(--gamepad-button-label)"
      strokeWidth="2.5"
    />
  </svg>
);
