export const XboxY: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M21.0845 10.5967L17.208 18.2666V22.5H14.5269V18.3164L10.75 10.5967H13.813L15.7305 15.0376C15.7637 15.1206 15.855 15.4443 16.0044 16.0088H16.0376C16.1095 15.6104 16.1953 15.2977 16.2949 15.0708L18.2456 10.5967H21.0845Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
