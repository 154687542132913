import { useEffect } from 'react';
import { GamepadAxisEvent, GamepadButtonEvent } from '../types/Gamepad';
import { enqueueSnackbar } from 'notistack';

type ButtonEventCallback = (event: GamepadButtonEvent) => void;
type AxisEventCallback = (event: GamepadAxisEvent) => void;
type GamepadConnectedCallback = (gamepad: Gamepad) => void;

interface UseGamepadOptions {
  onButtonEvent?: ButtonEventCallback;
  onAxisEvent?: AxisEventCallback;
  onGamepadConnected?: GamepadConnectedCallback;
  onGamepadDisconnected?: GamepadConnectedCallback;
  scrollTarget?: HTMLElement | null;
}

class GamepadManager {
  private static instance: GamepadManager;
  private axisEventSubscribers: Set<AxisEventCallback> = new Set();
  private buttonEventSubscribers: Set<ButtonEventCallback> = new Set();
  private gamepadConnectedSubscribers: Set<GamepadConnectedCallback> =
    new Set();
  private gamepadDisconnectedSubscribers: Set<GamepadConnectedCallback> =
    new Set();
  private animationFrameId: number | null = null;
  private buttonStates: Map<number, boolean[]> = new Map();

  private constructor() {
    window.addEventListener('gamepadconnected', this.handleGamepadConnected);
    window.addEventListener(
      'gamepaddisconnected',
      this.handleGamepadDisconnected
    );

    if (
      navigator.getGamepads &&
      Array.from(navigator.getGamepads()).some((gp) => gp)
    ) {
      this.startPolling();
    }
  }

  public static getInstance(): GamepadManager {
    if (!GamepadManager.instance) {
      GamepadManager.instance = new GamepadManager();
    }
    return GamepadManager.instance;
  }

  private handleGamepadConnected = (e: GamepadEvent) => {
    const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
    if (gamepads[0] && gamepads[0].index === e.gamepad.index) {
      this.gamepadConnectedSubscribers.forEach((callback) =>
        callback(e.gamepad)
      );
      this.resetButtonStates();
      this.stopPolling();
      this.startPolling();
    }
  };

  private handleGamepadDisconnected = (e: GamepadEvent) => {
    enqueueSnackbar('Controller disconnected.', { autoHideDuration: 3000 });
    this.gamepadDisconnectedSubscribers.forEach((callback) =>
      callback(e.gamepad)
    );
    this.resetButtonStates();
    this.stopPolling();
  };

  private startPolling() {
    if (!this.animationFrameId) {
      this.animationFrameId = requestAnimationFrame(this.updateGamepadStatus);
    }
  }

  private stopPolling() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  private resetButtonStates() {
    this.buttonStates.clear();
  }

  private updateGamepadStatus = () => {
    const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
    const gp = gamepads[0];
    if (gp) {
      const prevButtonStates = this.buttonStates.get(gp.index) || [];
      const newButtonStates = gp.buttons.map((button) => button.pressed);

      // Handle button events
      gp.buttons.forEach((_, index) => {
        const prevPressed = prevButtonStates[index] || false;
        const currPressed = newButtonStates[index];

        if (currPressed !== prevPressed) {
          // Button state changed
          this.buttonEventSubscribers.forEach((callback) =>
            callback({ buttonIndex: index, pressed: currPressed })
          );
        }
      });

      // Update stored button states
      this.buttonStates.set(gp.index, newButtonStates);

      // Handle axes
      gp.axes.forEach((value, index) => {
        this.axisEventSubscribers.forEach((callback) =>
          callback({ axisIndex: index, value })
        );
      });
    }

    this.animationFrameId = requestAnimationFrame(this.updateGamepadStatus);
  };

  public subscribe(options: UseGamepadOptions) {
    if (options.onButtonEvent) {
      this.buttonEventSubscribers.add(options.onButtonEvent);
    }
    if (options.onAxisEvent) {
      this.axisEventSubscribers.add(options.onAxisEvent);
    }
    if (options.onGamepadConnected) {
      this.gamepadConnectedSubscribers.add(options.onGamepadConnected);
    }
    if (options.onGamepadDisconnected) {
      this.gamepadDisconnectedSubscribers.add(options.onGamepadDisconnected);
    }
  }

  public unsubscribe(options: UseGamepadOptions) {
    if (options.onButtonEvent) {
      this.buttonEventSubscribers.delete(options.onButtonEvent);
    }
    if (options.onAxisEvent) {
      this.axisEventSubscribers.delete(options.onAxisEvent);
    }
    if (options.onGamepadConnected) {
      this.gamepadConnectedSubscribers.delete(options.onGamepadConnected);
    }
    if (options.onGamepadDisconnected) {
      this.gamepadDisconnectedSubscribers.delete(options.onGamepadDisconnected);
    }
  }
}

export function useGamepad(options: UseGamepadOptions) {
  useEffect(() => {
    const manager = GamepadManager.getInstance();
    manager.subscribe(options);

    // Default axis event handler for scrolling
    const axisHandler = (event: GamepadAxisEvent) => {
      const { axisIndex, value } = event;
      // Axis indices 0 and 1 correspond to the left joystick
      const deadZone = 0.1; // To prevent drift
      const scrollSpeed = 20; // Adjust scroll sensitivity

      if (axisIndex === 1 && Math.abs(value) > deadZone) {
        // Vertical scrolling
        const scrollAmount = value * scrollSpeed;
        const target = options.scrollTarget || window;
        if (target === window) {
          window.scrollBy(0, scrollAmount);
        } else {
          target.scrollBy({ top: scrollAmount, behavior: 'smooth' });
        }
      }
    };

    // If no custom onAxisEvent is provided, use the default handler
    if (!options.onAxisEvent) {
      manager.subscribe({ onAxisEvent: axisHandler });
    }

    return () => {
      manager.unsubscribe(options);
      if (!options.onAxisEvent) {
        manager.unsubscribe({ onAxisEvent: axisHandler });
      }
    };
  }, [options]);
}
