import React from 'react';

interface IconProps {
  classes?: string;
}

export const TriangleRight: React.FC<IconProps> = ({ classes }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path d="M3.5 2L3.5 10L8.5 6L3.5 2Z" fill="currentColor" />
    </svg>
  );
};
