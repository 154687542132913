import React from 'react';

interface IconProps {
  classes?: string;
}

export const TriangleDown: React.FC<IconProps> = ({ classes }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path d="M10 3.5L2 3.5L6 8.5L10 3.5Z" fill="currentColor" />
    </svg>
  );
};
