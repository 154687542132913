import { Topic } from './Dashboard';
import { ImageType } from './Image';

export interface Article {
  id: string;
  title: string;
  sections: ArticleSection[];
  articlePreview?: string;
  topics: Topic[];
}

export interface ArticleSection {
  articleId: string;
  id: string;
  images: ArticleImage[];
  ord: number;
  text: string;
  references: ArticleReference[];
  createdAt: string;
  updatedAt: string;
  isQAed: boolean;
  Image: ImageType[];
}

export interface ArticleImage {
  alt: string;
  articleSectionId: string;
  caption: string;
  credit: string;
  id: string;
  src: string;
}

export interface ArticleReference {
  articleSectionId: string;
  author: string;
  copyright: string;
  id: string;
  journal: string;
  pmcid: string;
  title: string;
  url: string;
  year: number;
}

export const ExamNameToYieldKeyMap = {
  'USMLE Step 1': 'step1Yield',
  'USMLE Step 2': 'step2Yield',
  'Neurology Shelf Exam': 'neurologyYield',
  'Psychiatry Shelf Exam': 'psychiatryYield',
  'Pediatrics Shelf Exam': 'pediatricsYield',
  'Surgery Shelf Exam': 'surgeryYield',
  'OBGYN Shelf Exam': 'obgynYield',
  'Family Medicine Shelf Exam': 'familyYield',
  'Emergency Medicine Shelf Exam': 'emergencyYield',
  'Internal Medicine Shelf Exam': 'internalYield',
};
