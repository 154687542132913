import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { getHelpArticle } from '../../../services/help';
import { renderLoadingFrames } from '../../../utils/renderLoadingFrames';
import { ArrowLeft } from 'react-feather';

interface HelpArticlePageProps {
  title: string;
  helpArticleNotionId: string;
}

const HelpArticlePage: React.FC<HelpArticlePageProps> = ({
  title,
  helpArticleNotionId,
}) => {
  const [helpArticleContent, setHelpArticleContent] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchHelpArticle(helpArticleNotionId);
  }, [helpArticleNotionId]);

  useEffect(() => {
    setHelpArticleContent(null);
  }, [location.pathname]);

  const fetchHelpArticle = async (notionId: string) => {
    const response = await getHelpArticle(notionId);
    if (response) {
      setHelpArticleContent(response);
    }
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const linkElement = target.closest('a') as HTMLAnchorElement;
    if (linkElement && linkElement.href.includes(window.location.origin)) {
      event.preventDefault();
      const path = linkElement.pathname;
      navigate(path);
    }
  };

  const renderLoader = () => {
    return renderLoadingFrames();
  };

  if (!helpArticleNotionId) {
    return null;
  }

  return (
    <div className="help-article-page">
      <button
        className="button button--link button--back"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft /> Help Center
      </button>
      <h1>{title}</h1>
      {helpArticleContent ? (
        <div onClick={handleLinkClick}>{parse(helpArticleContent || '')}</div>
      ) : (
        renderLoader()
      )}
    </div>
  );
};

export default HelpArticlePage;
