export const XboxLT: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.8636 0.5H6.75304C5.34827 0.5 4.4018 1.95627 4.81977 3.29743C7.79736 12.8517 4.58354 22.3874 1.56006 27.7989C0.882293 29.012 1.51582 30.6153 2.88496 30.8528C23.8408 34.4878 30.7842 22.6255 31.6145 15.9468C32.2412 10.906 31.9623 5.08496 31.7592 2.24309C31.6883 1.25101 30.8582 0.5 29.8636 0.5Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M18.376 18.318H11.502V6.77539H14.1019V16.2091H18.376V18.318Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M26.9484 8.89234H23.6563V18.318H21.0484V8.89234H17.7723V6.77539H26.9484V8.89234Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
