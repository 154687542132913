export const XboxX: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="var(--gamepad-button-background)"
      strokeWidth={0}
    />
    <path
      d="M21.3813 22H18.2021L16.1519 18.1235C16.0744 17.9797 15.9941 17.714 15.9111 17.3267H15.8779C15.8392 17.5093 15.7479 17.786 15.604 18.1567L13.5454 22H10.3496L14.1431 16.0483L10.6733 10.0967H13.9355L15.6372 13.666C15.77 13.9482 15.889 14.283 15.9941 14.6704H16.0273C16.0938 14.438 16.2183 14.0921 16.4009 13.6328L18.2935 10.0967H21.2817L17.7124 15.9985L21.3813 22Z"
      fill="var(--gamepad-button-label)"
      strokeWidth={0}
    />
  </svg>
);
