// Xbox icons
import { XboxA } from '../../../assets/svgs/controllers/xbox/a';
import { XboxB } from '../../../assets/svgs/controllers/xbox/b';
import { XboxX } from '../../../assets/svgs/controllers/xbox/x';
import { XboxY } from '../../../assets/svgs/controllers/xbox/y';
import { XboxLB } from '../../../assets/svgs/controllers/xbox/lb';
import { XboxRB } from '../../../assets/svgs/controllers/xbox/rb';
import { XboxLT } from '../../../assets/svgs/controllers/xbox/lt';
import { XboxRT } from '../../../assets/svgs/controllers/xbox/rt';
import { XboxLS } from '../../../assets/svgs/controllers/xbox/ls';
import { XboxRS } from '../../../assets/svgs/controllers/xbox/rs';

// PlayStation icons
import { PsX } from '../../../assets/svgs/controllers/ps/cross';
import { PsCircle } from '../../../assets/svgs/controllers/ps/circle';
import { PsSquare } from '../../../assets/svgs/controllers/ps/square';
import { PsTriangle } from '../../../assets/svgs/controllers/ps/triangle';
import { PsL1 } from '../../../assets/svgs/controllers/ps/l1';
import { PsR1 } from '../../../assets/svgs/controllers/ps/r1';
import { PsL2 } from '../../../assets/svgs/controllers/ps/l2';
import { PsR2 } from '../../../assets/svgs/controllers/ps/r2';
import { PsL3 } from '../../../assets/svgs/controllers/ps/l3';
import { PsR3 } from '../../../assets/svgs/controllers/ps/r3';

// Bit
import { BitLH } from '../../../assets/svgs/controllers/bit/bitlh';
import { BitRH } from '../../../assets/svgs/controllers/bit/bitrh';
import { BitLV } from '../../../assets/svgs/controllers/bit/bitlv';
import { BitRV } from '../../../assets/svgs/controllers/bit/bitrv';

// Universal to Icon Map (Xbox Based)
export const universalToIconMap = {
  A: {
    xbox: XboxA,
    ps: PsX,
    '8bitVertical': XboxY,
    '8bitHorizontal': XboxB,
  },
  B: {
    xbox: XboxB,
    ps: PsCircle,
    '8bitVertical': XboxB,
    '8bitHorizontal': XboxA,
  },
  X: {
    xbox: XboxX,
    ps: PsSquare,
    '8bitVertical': XboxX,
    '8bitHorizontal': XboxY,
  },
  Y: {
    xbox: XboxY,
    ps: PsTriangle,
    '8bitVertical': XboxA,
    '8bitHorizontal': XboxX,
  },
  LB: {
    xbox: XboxLB,
    ps: PsL1,
    '8bitVertical': BitLV,
    '8bitHorizontal': BitLH,
  },
  RB: {
    xbox: XboxRB,
    ps: PsR1,
    '8bitVertical': BitRV,
    '8bitHorizontal': BitRH,
  },
  LT: {
    xbox: XboxLT,
    ps: PsL2,
  },
  RT: {
    xbox: XboxRT,
    ps: PsR2,
    '8bitVertical': '',
    '8bitHorizontal': '',
  },
  R3: {
    xbox: XboxRS,
    ps: PsR3,
    '8bitVertical': '',
    '8bitHorizontal': '',
  },
  L3: {
    xbox: XboxLS,
    ps: PsL3,
    '8bitVertical': '',
    '8bitHorizontal': '',
  },
};
