export const PsL3: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.4209 13.3956V1.75919H9.88113V11.3671H14.8698V13.3956H7.4209Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      d="M20.732 13.5546C19.8835 13.5546 19.1278 13.4088 18.4649 13.1171C17.8058 12.8217 17.285 12.4164 16.9024 11.9012C16.5236 11.3823 16.3286 10.7838 16.3172 10.1058H18.7945C18.8096 10.3899 18.9024 10.6399 19.0729 10.8558C19.2471 11.0679 19.4782 11.2327 19.7661 11.3501C20.0539 11.4675 20.3778 11.5262 20.7377 11.5262C21.1127 11.5262 21.4441 11.4599 21.732 11.3274C22.0199 11.1948 22.2452 11.0111 22.4081 10.7762C22.571 10.5414 22.6524 10.2706 22.6524 9.96373C22.6524 9.65313 22.5653 9.37851 22.3911 9.13987C22.2206 8.89745 21.9744 8.70805 21.6524 8.57169C21.3343 8.43533 20.9555 8.36714 20.5161 8.36714H19.4308V6.56033H20.5161C20.8873 6.56033 21.2149 6.49593 21.499 6.36714C21.7869 6.23836 22.0104 6.06033 22.1695 5.83305C22.3286 5.60199 22.4081 5.33305 22.4081 5.02623C22.4081 4.73457 22.338 4.47889 22.1979 4.25919C22.0615 4.0357 21.8683 3.86146 21.6183 3.73646C21.3721 3.61146 21.0843 3.54896 20.7547 3.54896C20.4214 3.54896 20.1164 3.60957 19.8399 3.73078C19.5634 3.8482 19.3418 4.01676 19.1752 4.23646C19.0085 4.45616 18.9195 4.71373 18.9081 5.00919H16.5502C16.5615 4.33873 16.7528 3.74782 17.124 3.23646C17.4952 2.7251 17.9952 2.32548 18.624 2.0376C19.2566 1.74593 19.9706 1.6001 20.7661 1.6001C21.5691 1.6001 22.2718 1.74593 22.874 2.0376C23.4763 2.32926 23.9441 2.7232 24.2774 3.21942C24.6146 3.71184 24.7812 4.26487 24.7774 4.87851C24.7812 5.53002 24.5786 6.07358 24.1695 6.50919C23.7642 6.94479 23.2358 7.22131 22.5843 7.33873V7.42964C23.4403 7.53949 24.0918 7.83684 24.5388 8.32169C24.9896 8.80275 25.213 9.40502 25.2093 10.1285C25.213 10.7914 25.0218 11.3804 24.6354 11.8956C24.2528 12.4107 23.7244 12.816 23.0502 13.1115C22.3759 13.4069 21.6032 13.5546 20.732 13.5546Z"
      fill="var(--gamepad-button-background)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 21.5067C0 19.543 1.59188 17.9512 3.55556 17.9512H28.4444C30.4081 17.9512 32 19.543 32 21.5067C32 23.4704 30.4081 25.0623 28.4444 25.0623H23.1111V30.3956H8.88889V25.0623H3.55556C1.59188 25.0623 0 23.4704 0 21.5067ZM15.9998 25.9509L8.88867 20.6176H23.1109L15.9998 25.9509Z"
      fill="var(--gamepad-button-background)"
    />
  </svg>
);
