// GamepadContext.ts
import { createContext } from 'react';
import {
  GamepadButtonEvent,
  GamepadType,
  UniversalButton,
} from '../types/Gamepad';

export interface GamepadButtonEventWithLogical extends GamepadButtonEvent {
  universalButton: UniversalButton | null;
}

type GamepadContextType = {
  lastButtonEvent: GamepadButtonEventWithLogical | null;
  gamepadType: GamepadType | null;
  setScrollTarget: (target: HTMLElement | Window | null) => void;
  isSelectGamepadModalOpen: boolean;
  setIsSelectGamepadModalOpen: (isOpen: boolean) => void;
};

export const GamepadContext = createContext<GamepadContextType>({
  lastButtonEvent: null,
  gamepadType: null,
  setScrollTarget: () => {},
  isSelectGamepadModalOpen: false,
  setIsSelectGamepadModalOpen: () => {},
});
