import { ScreensaverOption } from '../types/User';

export const getScreensaverTime = (
  screensaverPreference: ScreensaverOption | undefined
) => {
  if (!screensaverPreference) return 1000000000;
  switch (screensaverPreference) {
    case 'thirtySeconds':
      return 30000;
    case 'oneMinute':
      return 60000;
    case 'twoMinutes':
      return 120000;
    case 'fiveMinutes':
      return 300000;
    case 'tenMinutes':
      return 600000;
    default:
      return 1000000000;
  }
};
