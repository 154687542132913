import { ReactNode, useEffect, useState, forwardRef } from 'react';
import {
  SnackbarProvider,
  SnackbarOrigin,
  CustomContentProps,
  SnackbarContent,
} from 'notistack';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

interface ResponsiveSnackbarProviderProps {
  children: ReactNode;
}

const TRANSITION_TIME = 500;

const CustomSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, autoHideDuration }, ref) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
      if (!autoHideDuration) return;

      const adjustedDuration = autoHideDuration - TRANSITION_TIME;
      const interval = 100;
      const totalSteps = Math.floor(adjustedDuration / interval);
      const decrement = 100 / totalSteps;
      let steps = 0;

      const timer = setInterval(() => {
        setProgress((prev) => {
          steps += 1;
          const nextProgress = prev - decrement;

          if (steps >= totalSteps) {
            clearInterval(timer);
            return 0; // Ensure it ends at 0
          }

          return Math.max(nextProgress, 0);
        });
      }, interval);

      return () => clearInterval(timer);
    }, [autoHideDuration]);

    return (
      <StyledSnackbarContent ref={ref}>
        <div className="notistack-content">
          {message}
          <StyledLinearProgress variant="determinate" value={progress} />
        </div>
      </StyledSnackbarContent>
    );
  }
);

CustomSnackbar.displayName = 'CustomSnackbar';

const StyledSnackbarContent = styled(SnackbarContent)(() => ({
  WebkitBackdropFilter: 'blur(20px)',
  backdropFilter: 'blur(20px)',
  backgroundColor: 'rgba(0,0,0,.8)',
  color: 'var(--type-body-inverse)',
  borderRadius: '.75rem',
  padding: '0',
  minWidth: 'fit-content',
  transition: `opacity ${TRANSITION_TIME}ms ease-out`,
}));

const StyledLinearProgress = styled(LinearProgress)({
  width: '100%',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#FEFEFE',
  },
});

export function ResponsiveSnackbarProvider({
  children,
}: ResponsiveSnackbarProviderProps) {
  const [anchorOrigin, setAnchorOrigin] = useState<SnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'center',
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setAnchorOrigin({
          vertical: 'top',
          horizontal: 'left',
        } as SnackbarOrigin);
      } else {
        setAnchorOrigin({
          vertical: 'bottom',
          horizontal: 'center',
        } as SnackbarOrigin);
      }
    };

    // Initialize position based on current window size
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={anchorOrigin}
      Components={{
        default: CustomSnackbar,
      }}
      domRoot={document.body}
    >
      {children}
    </SnackbarProvider>
  );
}
